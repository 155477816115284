import { withRoleListWrapper } from "@/handlers/role/RoleListWrapper";
import { defineAsyncComponent } from "vue";
import { RouteRecordRaw } from "vue-router";

const routes: Array<RouteRecordRaw> = [{
  path: "/roles",
  component: withRoleListWrapper(
    defineAsyncComponent(() => import("@/views/role/RoleModulePage.vue"))

    // defineAsyncComponent(() => import("@/views/management/role/role-list/RoleListPage.vue"))
  ),
  
}];
export default routes;
