import { bindFileUploadComponentService } from "@/IoC/FilesIoC";
import { IoCTypes } from "@/IoC/IoCTypes";
import { Container } from "inversify";
import {
  Options,
  Vue
} from "vue-class-component";
import { IFileUploadComponentService } from "@/services/common/file/IFileUploadComponentService"
import { FileMetadata } from "@/models/FilesModel";
import { FileUploadComponentHandlerProps } from "./FileUploadComponentHandlerProps";

type TProvider = {
  metadata: FileMetadata[];
}

@Options({
  template: `<slot :saveFiles="handleUploadClick" :metadata="metadata" :handleUploadEdit="handleUploadEdit" :handleUploadDelete="handleUploadDelete"></slot>`,
  provide(): TProvider {
    return { metadata: this.metadata }
  }
})

export default class FileUploadComponentHandler extends Vue.with(FileUploadComponentHandlerProps) {
  container: Container = new Container();
  fileUploadComponentService!: IFileUploadComponentService;

  fileList: any[] = [];
  metadata: FileMetadata[] = [];

  handleUploadClick(files: FileList) {
    this.fileUploadComponentService.saveAttachments(files, this.category, this.subCategory)
  }

  handleUploadEdit(item: FileMetadata) {
    this.metadata[this.metadata.findIndex(x => x.fileName === item.fileName)] = item;
  }
  handleUploadDelete(item: File) {
    this.metadata.splice(this.metadata.findIndex(x => x.fileName === item.name), 1);
  }
  beforeCreate() {
    bindFileUploadComponentService(this.container);
    this.fileUploadComponentService = this.container.get<IFileUploadComponentService>(IoCTypes.FileUploadComponentService)
  }
}