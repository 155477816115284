import { HttpUtil } from "@/lib/HttpUtil";
import { PaginateList } from "@/models/ListModel";
import {
  NotificationRequest, NotificationResponse
} from "@/models/NotificationModal";

export class NotificationApi {
  httpUtil: HttpUtil = new HttpUtil();
  getNotificationList(query: NotificationRequest): Promise<PaginateList<NotificationResponse>> {
    // return this.httpUtil.get<PaginateList<NotificationResponse>>(`Notification/${ userId}`)
    return this.httpUtil.get<PaginateList<NotificationResponse>>("notification?"
      .concat("pgIndex=", String(query.PgIndex),
        "&pgSize=", String(query.PgSize),
        //  "&dtype=", String(query.dtype),
        "&featureType=", String(query.featureType),
        "&userid=", String(query.userid),
        "&featureNo=", String(query.featureNo),
        "&datefilter=", String(query.datefilter),
        "&isActive=", String(query.isActive),
        "&notificationType=", String(query.notificationType),

      )
       ,);
  }
  getAllNotificationList(query: NotificationRequest): Promise<PaginateList<NotificationResponse>> {
    return this.httpUtil.get<PaginateList<NotificationResponse>>("notification?"
      .concat("pgIndex=", String(query.PgIndex),
        "&pgSize=", String(query.PgSize),
        // "&ntype=", String(query.ntype),
        "&featureType=", String(query.featureType),
        "&userid=", String(query.userid),
        // "&dtype=", String(query.dtype),

      )
        ,);
  }
  updateNotification(notificationId: string): Promise<any> {
    return this.httpUtil.put<string>(`Notification/${notificationId}`, notificationId)
  }
  createbulkNotification():Promise<boolean>{
    return this.httpUtil.get("notification/bulknotification")
  }

}