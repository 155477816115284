import {
    prop,
    WithDefault
} from "vue-class-component";

export class GroupManageHandlerProps {
    GroupId: WithDefault<any> = prop({
        required: true,
        default: false
    })
}