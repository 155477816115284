import { HttpUtil } from "@/lib/HttpUtil";
import {
  AllegationSearchQuery,
  AllegationSearchResponse,
  AllegationsResponse,
  AllegationStatusCount,
  CaseNoSearch,
  CaseNoSearchResponse
} from "@/models/AllegationsModel";
import { PaginateList } from "@/models/ListModel";
import { ListQuery } from "@/models/ListQuery";

export class AllegationsApi {
  httpUtil: HttpUtil = new HttpUtil();

  getAllAllegations(searchQuery: ListQuery): Promise<any> {
    return this.httpUtil.get<PaginateList<AllegationsResponse>>("allegations?"
      .concat("pgIndex=", String(searchQuery.page),
        "&pgSize=", String(searchQuery.size))
      , searchQuery);
  }

  postNewAllegation(allegation: AllegationsResponse): Promise<boolean> {
    return this.httpUtil.post<AllegationsResponse>("allegations", allegation);
  }

  updateAllegation(allegation: AllegationsResponse): Promise<boolean> {
    return this.httpUtil.put<boolean>("allegations", allegation)
  }

  getSearchAllegation(query: AllegationSearchQuery): Promise<PaginateList<AllegationSearchResponse>> {
    return this.httpUtil.post("allegations/search", query);
  }

  getCaseNoSearch(query: CaseNoSearch): Promise<PaginateList<CaseNoSearchResponse>> {
    return this.httpUtil.post("case/search/caseno", query);
  }

  linkCaseAllegation(caseId: string, allegationId: string): Promise<boolean> {
    return this.httpUtil.post("allegations/".concat(allegationId, "/", caseId), {})
  }

  getAllegationStatusCount(): Promise<AllegationStatusCount> {
    return this.httpUtil.get("allegations/status/count");
  }

  getAllegationById(id: string): Promise<AllegationsResponse> {
    return this.httpUtil.get("allegations/".concat(id));
  }
}