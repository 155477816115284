import { execution } from "@/models/FormBuilderModel";
import {
    ValueSource,
    ValueTypes
} from "@/shared/FormBuilderShared";
import { ICustomFunction } from "../ICustomFunction";

export class Push {
    private exec: execution;
    private func: ICustomFunction;

    constructor(exec: execution, func: ICustomFunction) {
        this.exec = exec;
        this.func = func;
    }

    execute() {
        const arr: any[] = this.func.getValue({
            type: ValueTypes.FIELD,
            value: this.exec.field,
            isFixed: true,
            source: this.exec.source
        })
        const val = this.func.getValue(this.exec.value);
        if (arr) arr.push(val && this.exec.value.type === ValueTypes.STATIC
            || this.exec.value.isFixed
            || this.exec.value.source === ValueSource.LOCAL_VARIABLE
            || this.exec.value.source === ValueSource.BUILT_IN ? JSON.parse(JSON.stringify(val)) : val);
    }
}