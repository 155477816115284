import { FieldOption } from "@/models/ListModel";
import { FieldTypes } from "@/shared/FormBuilderShared";
import { inject } from "vue";
import {
  Options,
  Vue
} from "vue-class-component";
import { BaseFieldProps } from "./BaseFieldProps";

@Options({ inheritAttrs: false })

export default class BaseField extends Vue.with(BaseFieldProps) {
  fieldDynamicOptions: FieldOption[] = inject("fieldDynamicOptions") as FieldOption[]
  IsDisabled: boolean = inject("IsDisabled") as boolean;
  IsReadOnly: boolean = inject("IsReadOnly") as boolean;
  rules: string = inject("rules") as string;
  executeOnChange: () => any = inject("executeOnChange") as () => any;
  executeOnClick: () => any = inject("executeOnClick") as () => any;
  executeOptionSelected: () => any = inject("executeOptionSelected") as () => any;
  dynamicOptionsLoading: boolean = inject("dynamicOptionsLoading") as boolean;
  state: any = inject("state") as any;

  get fieldValue(): any {
    try {
      if (this.modelValue && (typeof this.modelValue === "string" || this.modelValue instanceof String)) return decodeURIComponent(String(this.modelValue));
    }
    catch {
      return this.modelValue;
    }
    return this.modelValue;
  }
  set fieldValue(value: any) {
    this.$emit("update:modelValue", value);
  }
  get selectedOptionValue(): string {
    return this.subTemplates && this.subTemplates.length > 0
      ? this.fieldValue?.value
      : this.fieldValue;
  }
  get selectedOptionIndex(): number {
    const idx = this.options?.findIndex(
      (x) => String(x.value) === this.selectedOptionValue
    );
    return Number(idx);
  }
  get options(): FieldOption[] {
    if (this.fieldDynamicOptions?.length === 0 && this.fieldType === FieldTypes.AUTOCOMPLETE) {
      this.fieldDynamicOptions.push({
        text: "No Results Found",
        value: ""
      })
    }
    return this.fieldOptions || this.fieldDynamicOptions
  }
}
