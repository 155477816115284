import { HttpUtil } from "@/lib/HttpUtil";
import { PaginateList } from "@/models/ListModel";
import { FacilityListResponse } from "@/models/FacilityModal";

export class FacilityApi {
    private httpUtil: HttpUtil = new HttpUtil();
    getFacilityList(): Promise<PaginateList<FacilityListResponse>> {
        return this.httpUtil.get<PaginateList<FacilityListResponse>>("facility/list");
    }

}