import { RouteRecordRaw } from "vue-router";
import { defineAsyncComponent } from "vue";
import { withAllegationModuleWrapper } from "@/handlers/allegation/module/AllegationModuleWrapper";
import { withAllegationListWrapper } from "@/handlers/allegation/list/AllegationListWrapper";
import { withAllegationManageWrapper } from "@/handlers/allegation/manage/AllegationManageWrapper";
import { Permissions } from "@/shared/ClaimsShared";

const routes: Array<RouteRecordRaw> = [{
  path: "/allegation",
  name: "Allegation",
  component: withAllegationModuleWrapper(defineAsyncComponent(() => import("@/views/allegation/AllegationModulePage.vue"))),
  meta:{ permission: Permissions.readAllegation },
  children: [{
    path: "",
    component: withAllegationListWrapper(defineAsyncComponent(() => import("../views/allegation/Allegation.vue"))),
  },
  {
    path: "new-allegation",
    component: withAllegationManageWrapper(defineAsyncComponent(() => import("@/views/allegation/AllegationCreateForm.vue"))),
    meta: { permission: Permissions.createAllegation }
  }]
}]
export default routes;