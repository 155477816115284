import { execution } from "@/models/FormBuilderModel";
import { inject } from "vue";
import {
    Options,
    Vue
} from "vue-class-component";
import { BaseActionProps } from "./BaseActionProps";

@Options({ inheritAttrs: false })

export default class BaseAction extends Vue.with(BaseActionProps) {
    state: any = inject("state") as any;
    handleClick: () => void = inject("handleClick") as () => void;
    executeFunction: (executions: execution[], currentObject?: any) => any = inject("executeFunction") as (executions: execution[], currentObject?: any) => any;
    get IsReadOnly() {
        const isReadonly = this.properties?.isReadonly;
        if (isReadonly === true) return true;
        else if (isReadonly === undefined || isReadonly === false) return false;
        return this.executeFunction(isReadonly, this);
    }
}
