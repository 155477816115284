import { inject } from "vue";
import { Options } from "vue-class-component";
import BaseHandler from "../BaseHandler";


@Options({ template: `<slot></slot>` })
export default class UserListHandler extends BaseHandler {
    selectedUsers: string[] = [];
    canLoadList: boolean = inject("canLoadList") as boolean;
    refreshList: () => void = inject("refreshList") as () => void;
    setCanLoadList: (canLoad: boolean) => void = inject("setCanLoadList") as (canLoad: boolean) => void;

    created() {
        if (this.canLoadList) {
            this.setCanLoadList(false);
            this.refreshList();
        }
    }
}   