import { IAppConfigService } from "@/services/app/IAppConfigService";
import { AppConfigService } from "@/services/app/AppConfigService";
import { Container } from "inversify";
import { IoCTypes } from "./IoCTypes";
import { IAppConfigStoreService } from "@/services/app/IAppConfigStoreService";
import { AppConfigStoreService } from "@/services/app/AppConfigStoreService";

export function bindAppConfigStoreService(container: Container) {
    container.bind<IAppConfigStoreService>(IoCTypes.AppConfigStoreService).to(AppConfigStoreService)
    
}

export function bindAppConfigService(container:Container) {
    container.bind<IAppConfigService>(IoCTypes.AppConfigService).to(AppConfigService);
    bindAppConfigStoreService(container);
}