import { IoCTypes } from "@/IoC/IoCTypes";
import { bindManageUserService } from "@/IoC/UserIoC";
import { UserRequest } from "@/models/UserModel";
import { IManageUserService } from "@/services/user/IManageUserService";
import {
    UsersCreateResponse,
    UsersUpdateResponse,
    UserdeleteResponse

} from "@/shared/UserShared";
import {
    computed,
    ComputedRef,
    inject
} from "vue";
import { Options } from "vue-class-component";
import BaseHandler from "../BaseHandler";

type TProvider = {
    user: ComputedRef<UserRequest>,
    isLoading: ComputedRef<boolean>,
    saveUser: () => void
    deleteUser: (userid: any) => void;
    cancelClick: () => void
}

@Options({
    template: `<c-validation-listener v-model="validate"><slot :DeleteUser="deleteUser"></slot></c-validation-listener>`,
    provide(): TProvider {
        return {
            user: computed(() => this.user),
            isLoading: computed(() => this.isLoading),
            saveUser: this.saveUser,
            deleteUser: this.deleteUser,
            cancelClick: this.cancelClick
        }
    }
})
export default class ManageUserHandler extends BaseHandler {
    user: UserRequest = {
        firstName: "",
        email: "",
        password: "",
        confirmPassword: "",
        roles: "",
        status: 1
    };
    isLoading = false;
    manageUserService!: IManageUserService;
    setCanLoadList: (canLoad: boolean) => void = inject("setCanLoadList") as (canLoad: boolean) => void;
    refreshList: () => void = inject("refreshList") as () => void;

    beforeCreate() {
        bindManageUserService(this.IoC);
        this.manageUserService = this.IoC.get<IManageUserService>(IoCTypes.ManageUserService);
    }

    created() {
        if (this.$route.params.id) {
            this.user.id = this.$route.params.id.toString();
            this.getUsers();
        }
    }

    getUsers() {
        if (this.user.id) {
            this.isLoading = true;
            this.manageUserService.getUser(this.user.id).then((res: UserRequest) => {
                this.user = res;
            }).finally(() => this.isLoading = false)
        }
    }

    cancelClick() {
        this.$router.back()
        this.setCanLoadList(true);
    }

    async saveUser() {
        const validationResult = await this.validate()
        if (!validationResult.valid) return;
        this.isLoading = true;
        if (this.user.id) {
            this.manageUserService.updateUser(this.user).then((res: UsersUpdateResponse) => {
                if (res === UsersUpdateResponse.USER_UPDATED) {
                    this.$confirm.require({
                        message: JSON.stringify({
                            text: "User Updated Sucessfully",
                            status: "SUCCESS",
                            type: "INFORM"
                        }),
                        header: "Saved",
                        acceptLabel: "Ok",
                        icon: "pi pi-check"

                    });
                }
                else {
                    this.$toast.add({
                        severity: "error",
                        summary: "Something Missed",
                        detail: "User account updated, but attributes not updated",
                        life: 5000
                    });
                }
                this.setCanLoadList(true);
                this.$router.back();
            }).catch((err: any) => {
                console.warn(err);
                this.$toast.add({
                    severity: "error",
                    summary: "Failed",
                    detail: err,
                    life: 3000
                });
            }).finally(() => this.isLoading = false)
        }
        else {
            this.manageUserService.addUser(this.user).then((res: UsersCreateResponse) => {
                if (res === UsersCreateResponse.USER_CREATED) {
                    this.$confirm.require({
                        message: JSON.stringify({
                            text: "User Saved Sucessfully",
                            status: "SUCCESS",
                            type: "INFORM"
                        }),
                        header: "Saved",
                        acceptLabel: "Ok",
                        icon: "pi pi-check"

                    });
                }
                else {
                    this.$toast.add({
                        severity: "error",
                        summary: "Something Missed",
                        detail: "User account created, but attributes not saved",
                        life: 5000
                    });
                }
                this.setCanLoadList(true);
                this.$router.back();
            }).catch((err: any) => {
                console.warn(err);
                this.$toast.add({
                    severity: "error",
                    summary: "Failed",
                    detail: err,
                    life: 3000
                });
            }).finally(() => this.isLoading = false)
        }
    }
    deleteUser(id: any) {
        this.isLoading = true;
        this.$confirm.require({
            header: "Delete",
            acceptLabel: "Delete",
            rejectLabel: " Cancel",
            rejectClass: "p-button p-button-secondary",
            message: JSON.stringify({
                text: " Do you really want to delete this user? This process cannot be undone.",
                type: "CONFIRM",
            }),
            icon: "pi pi-times",
            accept: () => {
                const userid = { userid: id }
                this.manageUserService.deleteUser(userid).then((res: any) => {
                    if (res === UserdeleteResponse.USER_DELETED) {
                        this.$toast.add({
                            severity: "success",
                            summary: "Success",
                            detail: "Deleted successfully",
                            life: 3000
                        });
                    }
                    else {
                        this.$toast.add({
                            severity: "error",
                            summary: "Something Missed",
                            detail: "User account deleted, but attributes not saved",
                            life: 5000
                        });
                    }
                    this.refreshList();
                    this.setCanLoadList(true);
                }).catch((err: any) => {
                    console.warn(err);
                    this.$toast.add({
                        severity: "error",
                        summary: "Failed",
                        detail: err,
                        life: 3000
                    });
                }).finally(() => this.isLoading = false)
            },
            reject: () => {
                //callback to execute when user rejects the action
            },
        });
    }
}