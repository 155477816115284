import { HttpUtil } from "@/lib/HttpUtil";
import { 
  ResetRequest,
  ResetResponse
 } from "@/models/ResetPassword";

 import { 
  ChangeRequest,
  ChangeResponse
 } from "@/models/ChangePassword";

export class Resetapi {
  
  httpUtil: HttpUtil = new HttpUtil("IDP");
  
  resetPassword(payload: ResetRequest): Promise<ResetResponse> {
    
    const data = { 
      "email": payload.email,
      "token": payload.token,
      "password": payload.password,
     };
    return this.httpUtil.postForm<ResetResponse>("/api/v1/Login/ResetPassword", data);
}
changePassword(payload: ChangeRequest): Promise<ChangeResponse> {
    
  const data = { 
    "email": payload.Email,
    "currentPassword": payload.currentPassword,
    "newpassword": payload.newPassword,
   };
  return this.httpUtil.postForm<ChangeResponse>("/api/v1/Login/ChangePassword", data);
}
}