import { AsyncRoleListHandler } from "@/components/AsyncComponents";

export const withRoleListWrapper = (WrappedComponent: any) => {
    return {
        template: `<Handler><WrappedComponent></WrappedComponent></Handler>`,
        components: {
            WrappedComponent,
            Handler: AsyncRoleListHandler,
        }
    }
}