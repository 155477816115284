import { HttpUtil } from "@/lib/HttpUtil";
import {
  LoginRequest,
  LoginResponse
} from "@/models/Login";

export class Authapi {
  httpUtil: HttpUtil = new HttpUtil("IDP");

  validateLogin(payload: LoginRequest): Promise<LoginResponse> {
    const data = {
      "client_id": "prea_ui",
      "client_secret": "secret",
      "grant_Type": "password",
      "username": payload.username,
      "password": payload.password
    };
    return this.httpUtil.postForm<LoginResponse>("connect/token", data);
  }

}

