
import { injectable } from "inversify";
import { IRoleManageService } from "./IRoleManageService";
import { RoleApi } from "@/api/RoleApi";
import {
  PermissionDictionary,
  RoleRequest
} from "@/models/RoleModal";

@injectable()
export class RoleManageService implements IRoleManageService {
  roleApi = new RoleApi();

  saveRole(role: RoleRequest): any {
    return this.roleApi.postNewRole(role);
  }
  updateRole(role: RoleRequest): any {
    return this.roleApi.updateRole(role);
  }

  getRolePermissions(roleIds: string[]): Promise<PermissionDictionary[]> {
    return this.roleApi.getPermissionByRoles(roleIds)
  }

}