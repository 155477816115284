import { execution } from "@/models/FormBuilderModel";
import {
    ValueSource,
    ValueTypes
} from "@/shared/FormBuilderShared";
import { computed } from "vue";
import { ICustomFunction } from "../ICustomFunction";

export class SetVariable {
    private exec: execution;
    private func: ICustomFunction;

    constructor(exec: execution, func: ICustomFunction) {
        this.exec = exec;
        this.func = func;
    }

    execute() {
        const value = this.func.getValue(this.exec.value);
        const res = (this.exec.value.type === ValueTypes.STATIC
            || this.exec.value.isFixed
            || this.exec.value.source === ValueSource.LOCAL_VARIABLE
            || this.exec.value.source === ValueSource.BUILT_IN)
            ? value ? JSON.parse(JSON.stringify(value)) : value
            : computed(() => this.func.getValue(this.exec.value));
        this.func.setValue(this.exec.field, res, this.exec.source);
    }
}