
export enum SortOrder {
    ASC = "ASC",
    DESC = "DESC"
}

export enum Operators {
    AND = "AND",
    OR = "OR",

    STARTS_WITH = "STARTS_WITH",
    END_WITH = "ENDS_WITH",
    CONTAINS = "CONTAINS",

    BETWEEN = "BETWEEN",
    LESS_THAN_OR_EQUAL = "LESS_THAN_OR_EQUAL",
    GREATER_THAN_OR_EQUAL = "GREATER_THAN_OR_EQUAL",
    LESS_THAN = "LESS_THAN",
    GREATER_THAN = "GREATER_THAN",
    EQUAL = "EQUAL",
    NOT_EQUAL = "NOT_EQUAL",
    // DURATION = "DURATION",
    LAST_MONTH = "LAST_MONTH",
    LAST_3_MONTH = "LAST_3_MONTH",
    LAST_6_MONTH = "LAST_6_MONTH",
    LAST_YEAR = "LAST_YEAR",
    YEAR_TO_DATE = "YEAR_TO_DATE"
}


export enum ColumnDataType {
    TEXT = "TEXT",
    NUMERIC = "NUMERIC",
    DATETIME = "DATETIME",
    DATE = "DATE",
    DROPDOWN = "DROPDOWN"
}

export class OperatorTypeAssoc {
    public static listOperators: Array<string> = [Operators.CONTAINS, Operators.EQUAL, Operators.NOT_EQUAL];
    public static textOperators: Array<string> = [
        Operators.STARTS_WITH, Operators.END_WITH, Operators.CONTAINS, Operators.EQUAL, Operators.NOT_EQUAL
    ];
    public static numericOperators: Array<string> = [
        Operators.LESS_THAN, Operators.LESS_THAN_OR_EQUAL, Operators.GREATER_THAN, Operators.GREATER_THAN_OR_EQUAL,
        Operators.EQUAL, Operators.NOT_EQUAL, Operators.BETWEEN
    ];
    public static dateOperators: Array<string> = [
        Operators.YEAR_TO_DATE, Operators.LAST_MONTH, Operators.LAST_3_MONTH, Operators.LAST_6_MONTH, Operators.LAST_YEAR,
        // Operators.DURATION,
        Operators.LESS_THAN, Operators.LESS_THAN_OR_EQUAL, Operators.GREATER_THAN, Operators.GREATER_THAN_OR_EQUAL,
        Operators.EQUAL, Operators.NOT_EQUAL, Operators.BETWEEN
    ];
}

export interface Column {
    fieldName: string,
    aliasName: string,
    dataType: ColumnDataType,
    sortOrder?: SortOrder.ASC | SortOrder.DESC
}

export interface DataSet {
    name: string,
    displayName: string,
    description: string,
}

export interface Parameters {
    name: string,
    displayName: string;
    dataType: "TEXT" | "DATE" | "DATETIME" | "DROPDOWN" | "NUMERIC";
    defaultOptions?: string;
    refLink?: string;
    optionType?: string;
}

export interface QueryFilters {
    name: string;
    displayName: string;
    parameters: Array<Parameters>;
}

export interface QueryBuilderModel {
    dataSet: string,
    dataSetDisplayName?: string,
    dataSetDescription?: string,
    selectedColumns?: Array<Column>,
    sortColumns?: Array<Column>,
    queryFilters?: Array<any>,
    query?: string
}

export interface AdHocQueryModel {
    id?: string,
    title?: string
    description?: string,
    isCustomQuery?: boolean,
    isPublished?: boolean,
    queryBuilder?: QueryBuilderModel,
    createdBy?: string,
    createdDate?: Date,
    modifiedBy?: string,
    modifiedDate?: Date,
    isActive?: boolean
}