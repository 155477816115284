import { 
    ResetRequest,
    ResetResponse
 } from "@/models/ResetPassword";
 import { 
    ChangeRequest,
    ChangeResponse
 } from "@/models/ChangePassword";
    import { injectable } from "inversify";
    import { IResetService } from "./IResetService";
    import { Resetapi }from "../api/ResetApi";
    
    @injectable()
    export class ResetService implements IResetService {
        resetApi: Resetapi = new Resetapi();
        resetPassword(data:ResetRequest):Promise<ResetResponse> {
            return this.resetApi.resetPassword(data);
        }
        changePassword(data:ChangeRequest):Promise<ChangeResponse> {
            return this.resetApi.changePassword(data);
        }
    }