import ListHandler from "@/handlers/common/list/ListHandler";
import { bindAllegationSearchListService } from "@/IoC/AllegationsIoC";
import {
  AllegationSearchQuery,
  AllegationSearchResponse,
  AllegationsResponse,
  AllegationStatusCount
} from "@/models/AllegationsModel";
import { ListQuery } from "@/models/ListQuery";
import { Container } from "inversify";
import { Options } from "vue-class-component";
import { IAllegationSearchListService } from "@/services/allegation/list/IAllegationSearchListService";
import { IoCTypes } from "@/IoC/IoCTypes";


@Options({ template: `<slot :allegationList="allegationList" :isFetching="isFetching" :updateAllegationStatus="updateAllegationStatus" :listDetails="listDetails" :handlePageChange="handlePageChange" :perStatusCount="perStatusCount"></slot>` })
export default class AllegationSearchListHandler extends ListHandler<AllegationSearchResponse> {
  container: Container = new Container();
  allegationSearchListService!: IAllegationSearchListService;
  perStatusCount: AllegationStatusCount[] = []
  isFetching: any = false;

  async created() {
    await this.getAllegationStatusCount();
    this.loadList();
  }

  getList(searchQuery: ListQuery) {
    const payload: AllegationSearchQuery = {
      pVictimName: "",
      pInmateId: "",
      pPageLimit: 5,
      pPageNumber: searchQuery.page,
      pDateTime: "",
      PPhone: "",
      PEmail: "",
      PDescription: "",
      PLocation: ""
    }
    this.isFetching = true;
    this.getAllegationStatusCount();
    return this.allegationSearchListService.getAllAllegations(payload).finally(() => {
      this.isFetching = false;
    });
  }

  get allegationList(): AllegationsResponse[] {
    return this.pages[this.listQuery.page];
  }

  updateAllegationStatus(allegation: AllegationsResponse) {
    return this.allegationSearchListService.updateAllegation(allegation).then(() => {
      this.$toast.add({
        severity: "success",
        summary: "Success",
        detail: "Allegation updated successfully",
        life: 3000
      });
      this.refreshList();
    }).catch((err: PromiseRejectedResult) => {
      console.error("err.reason", err)
      this.$toast.add({
        severity: "error",
        summary: "Failed",
        detail: "Something went wrong",
        life: 3000
      });
    });
  }

  getAllegationStatusCount() {
    this.allegationSearchListService.getAllegationStatusCount().then((res: any) => {
      this.perStatusCount = res.items;
    })
  }

  beforeCreate() {
    bindAllegationSearchListService(this.container);
    this.allegationSearchListService = this.container.get<IAllegationSearchListService>(IoCTypes.AllegationSearchListService);
  }
}