import { Container } from "inversify";
import { Vue } from "vue-class-component";

export default class BaseHandler extends Vue {
    IoC: Container = new Container();
    validate: () => Promise<{ valid: boolean; errors?: any }> = () => new Promise<{ valid: boolean }>((res) => res({ valid: true }));
    beforeUnmount() {
        this.IoC.unbindAllAsync();
    }
}
