import { HttpUtil } from "@/lib/HttpUtil";
import { StoreUtil } from "@/lib/StoreUtil";
import {
    decryptedText,
    encryptedText
} from "@/lib/Util";
import { AppConfig } from "@/models/AppConfig";

export class AppConfigApi {
    httpUtil: HttpUtil = new HttpUtil();
    storeUtil: StoreUtil = new StoreUtil();
    getAllAppConfig(): Promise<AppConfig[]> {
        if (this.storeUtil.getItem("orgAppConfig")) return Promise.resolve(JSON.parse(decryptedText(this.storeUtil.getItem("orgAppConfig"))));
        return this.httpUtil.get("common/getorgappconfig").then((res: AppConfig[]) => {
            this.storeUtil.setItem("orgAppConfig", encryptedText(JSON.stringify(res)));
            return res;
        });
    }
}
