import { AsyncCaseListHandler } from "@/components/AsyncComponents";

export const withCaseListWrapper = (WrappedComponent: any) => {
    return {
        template: `<CaseListHandler><WrappedComponent></WrappedComponent></CaseListHandler>`,
        components: {
            WrappedComponent,
            CaseListHandler: AsyncCaseListHandler
        }
    }
}