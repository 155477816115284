import { HttpUtil } from "@/lib/HttpUtil";
import { ProfileResponse } from "@/models/Profile";
import { getToken } from "@/lib/AuthUtil";


export class ProfileApi {
  httpUtil: HttpUtil = new HttpUtil("IDP");
  getProfile(): Promise<ProfileResponse> {
    const data = getToken();
    return this.httpUtil.get<ProfileResponse>("api/v1/Profile", { "Authorization": "Bearer ".concat(String(data)) });
  }
}