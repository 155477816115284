import { bindAllegationModuleService } from "@/IoC/AllegationsIoC";
import { IoCTypes } from "@/IoC/IoCTypes";
import {
  AllegationSearchQuery,
  AllegationSearchResponse,
  AllegationsResponse,
  CaseNoSearch,
  CaseNoSearchResponse
} from "@/models/AllegationsModel";
import { PaginateList } from "@/models/ListModel";
import { ListQuery } from "@/models/ListQuery";
import { IAllegationModuleService } from "@/services/allegation/module/IAllegationModuleService";
import { Container } from "inversify";
import {
  computed,
  ComputedRef
} from "vue";
import { Options } from "vue-class-component";
import ListHandler from "../../common/list/ListHandler";
import { Helpers } from "@/lib/Util";
import { bindFacilityListService } from "@/IoC/FacilityIoC";
import { IFacilityListService } from "@/services/facility/facility-list/IFacilityListService";
import { FacilityListResponse } from "@/models/FacilityModal";

type TProvider = {
  allegationSearchList: ComputedRef<AllegationSearchResponse[]>;
  handlePageChange: () => void;
  listDetails: ComputedRef<any>,
  searchModel: any,
  postAllegation: () => void,
  updateAllegation: () => void,
  refreshList: () => void,
  isFetchingSearchResults: any,
  handleSearchEvent: () => void,
  listQuery: ListQuery,
  getCaseNoSearchList: () => Promise<PaginateList<CaseNoSearchResponse>>,
  linkCaseAllegation: () => void,
  isLinking: boolean,
  showResults: ComputedRef<boolean>,
  facilityList: ComputedRef<FacilityListResponse>
  isFacilityLoading: ComputedRef<boolean>,
  clearSearch: () => void
}

@Options({
  template: `<slot></slot>`,
  provide(): TProvider {
    return {
      allegationSearchList: computed(() => this.allegationSearchList),
      handlePageChange: this.handlePageChange,
      listDetails: computed(() => this.listDetails),
      searchModel: this.searchModel,
      postAllegation: this.postAllegation,
      updateAllegation: this.updateAllegation,
      refreshList: this.refreshList,
      isFetchingSearchResults: this.isFetchingSearchResults,
      handleSearchEvent: this.handleSearchEvent,
      listQuery: this.listQuery,
      getCaseNoSearchList: this.getCaseNoSearchList,
      linkCaseAllegation: this.linkCaseAllegation,
      isLinking: this.isLinking,
      showResults: computed(() => this.showResults),
      facilityList: computed(() => this.facilityList),
      isFacilityLoading: computed(() => this.isFacilityLoading),
      clearSearch: this.clearSearch
    }
  }
})

export default class AllegationModuleHandler extends ListHandler<AllegationSearchResponse> {
  container: Container = new Container();
  allegationModuleService!: IAllegationModuleService;
  isFetchingSearchResults: any = { value: false };
  isLinking: any = { value: false }
  searchListQuery: AllegationsResponse = {
    allegationDate: undefined,
    allegationEventDescription: "",
    allegationEventLocation: "",
    allegationStatus: "",
    comments: "",
    victimContactAddress: "",
    victimContactEmail: "",
    victimContactPhone: "",
    victimInmateId: "",
    victimName: "",
  }
  showResults = false;

  searchModel: AllegationsResponse = {
    allegationDate: undefined,
    allegationEventDescription: "",
    allegationEventLocation: "",
    allegationStatus: "",
    comments: "",
    victimContactAddress: "",
    victimContactEmail: "",
    victimContactPhone: "",
    victimInmateId: "",
    victimName: "",
  }
  facilityListService!: IFacilityListService;

  facilityList: FacilityListResponse[] = [];
  isFacilityLoading = true;
  handleSearchEvent(details: AllegationsResponse) {
    this.searchListQuery = details;
    this.refreshList();
  }
  clearSearch() {
    this.showResults = false;
  }
  getList(searchQuery: ListQuery) {
    const payload: AllegationSearchQuery = {
      pVictimName: this.searchListQuery.victimName,
      pInmateId: this.searchListQuery.victimInmateId ?? "",
      pPageLimit: searchQuery.size,
      pPageNumber: searchQuery.page,
      pDateTime: this.searchListQuery.allegationDate === undefined ? "" : Helpers.getLocaleFormattedDate(this.searchListQuery.allegationDate as Date),
      PPhone: this.searchListQuery.victimContactPhone ?? "",
      PEmail: this.searchListQuery.victimContactEmail ?? "",
      PDescription: this.searchListQuery.allegationEventDescription ?? "",
      PLocation: this.searchListQuery.allegationEventLocation ?? ""
    }
    return this.allegationModuleService.getFilteredAllegation(payload).finally(() => {
      this.isFetchingSearchResults.value = false;
      this.showResults = true;
    })
  }

  get allegationSearchList(): AllegationSearchResponse[] {
    return this.pages[this.listQuery.page];
  }

  updateAllegation(allegation: AllegationsResponse) {
    return this.allegationModuleService.updateAllegation(allegation).then(() => {
      this.refreshList();
      this.$toast.add({
        severity: "success",
        summary: "Success",
        detail: "Allegation updated successfully",
        life: 3000
      });
    }).catch((err: PromiseRejectedResult) => {
      console.error("err.reason", err)
      this.$toast.add({
        severity: "error",
        summary: "Failed",
        detail: "Something went wrong",
        life: 3000
      });
    });
  }

  getFacilityList() {
    this.facilityListService.getFacilityList().then((res: any) => {
      if (res && res.length > 0) {
        this.facilityList = res.map((x: any) => {
          return {
            value: x.value,
            text: x.text
          }
        })
      }
      this.isFacilityLoading = false;
    }).catch(() => this.isFacilityLoading = false)
      .finally(() => this.isFacilityLoading = false)
  }

  getCaseNoSearchList(searchQuery: CaseNoSearch): Promise<PaginateList<CaseNoSearchResponse>> {
    return this.allegationModuleService.getCaseNoList(searchQuery);
  }

  linkCaseAllegation(caseId: string, allegationId: string) {
    this.isLinking.value = true;
    return this.allegationModuleService.linkCaseAllegation(caseId, allegationId).finally(() => { this.isLinking.value = false; })
  }

  beforeCreate() {
    bindAllegationModuleService(this.container);
    bindFacilityListService(this.container);
    this.allegationModuleService = this.container.get<IAllegationModuleService>(IoCTypes.AllegationModuleService);
    this.facilityListService = this.container.get<IFacilityListService>(IoCTypes.FacilityListService);
  }

  created() {
    this.getFacilityList();
  }
}