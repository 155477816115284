import {
    Options,
    Vue
} from "vue-class-component";
import {
    computed,
    ComputedRef
} from "vue";
import {
    InitResetRequest,
    ResetRequest
}
    from "@/models/ResetPassword";
import {
    InitChangeRequest,
    ChangeRequest
} from "@/models/ChangePassword";
import { IResetService } from "@/services/IResetService";
import { Container } from "inversify";
import { bindResetService } from "@/IoC/ResetIoC";
import { IoCTypes } from "@/IoC/IoCTypes";
import ProfileStore from "@/store/profile/ProfileStore";
import { getModule } from "vuex-module-decorators";
import AppStore from "@/store";


type TProvider = {
    resetpassword: typeof Function;
    changepassword: typeof Function;
    resetPage: any;
    reset: ComputedRef<ResetRequest>;
    change: ComputedRef<ChangeRequest>;
    isResetLoading: ComputedRef<boolean>;
};

@Options({
    template: `<slot></slot>`,
    provide(): TProvider {
        return {
            resetpassword: this.resetpassword,
            changepassword: this.changepassword,
            resetPage: this.resetPage,
            isResetLoading: computed(() => this.isResetLoading),
            reset: computed(() => this.reset),
            change: computed(() => this.change),
        };
    },
})

export default class ResetHandler extends Vue {
    resetservice!: IResetService;
    profileStore!: ProfileStore;
    isResetLoading = false;
    showChangePassword = false;
    reset: ResetRequest = { ...InitResetRequest };
    change: ChangeRequest = { ...InitChangeRequest };
    container: Container = new Container();
    resetPage: any = {};
    beforeCreate() {
        bindResetService(this.container)
        this.resetservice = this.container.get<IResetService>(IoCTypes.ResetService)
    }

    async resetpassword() {
        if (!(await this.resetPage.valResetPassword()).valid) return;
        this.isResetLoading = true
        this.reset.email = String(this.$route.query.email);
        this.reset.token = encodeURIComponent(String(this.$route.query.token));
        if (this.reset.confirmpassword === this.reset.password) {
            this.resetservice.resetPassword(this.reset).then(() => {
                this.$toast.add({
                    severity: "success",
                    summary: "Success",
                    detail: "Password changed sucessfully",
                    life: 3000
                  });
                this.$router.push(this.$route + String("/"));
            }).catch((err) => {
                this.isResetLoading = false
                this.$confirm.require({
                    acceptLabel: "Try Again",
                    acceptClass: "p-button p-button-danger",
                    message: JSON.stringify({
                        text: "Something went wrong",
                        type: "INFORM"
                    }),
                    icon: "pi pi-times",
                    accept: () => {
                        //yes
                    },
                }
                );
                console.error(err)
            })
        }
        else this.$confirm.require({
            acceptLabel: "Try Again",
            acceptClass: "p-button p-button-danger",
            message: JSON.stringify({
                text: "Passwords did not match",
                type: "INFORM"
            }),
            icon: "pi pi-times",
            accept: () => {
                //yes
            },
        });
    }
    async changepassword() {
        if (!(await this.resetPage.valResetPassword()).valid) return;
        this.profileStore = getModule(ProfileStore, AppStore);
        this.change.Email = this.profileStore.MyProfile.email;
        if (this.change.newPassword === this.change.confirmPassword) {
            this.resetservice.changePassword(this.change).then(() => {
                this.$confirm.require({
                    acceptLabel: "Logout",
                    rejectLabel: "Stay ",
                    acceptClass: "p-button p-button-success",
                    rejectClass: "p-button p-button-secondary",
                    header: "Sucess",
                    message: JSON.stringify({
                        type: "CONFIRM",
                        status: "SUCCESS",
                        text: "Password Changed sucessfully"
                    }),
                    icon: "pi pi-check",
                    accept: () => {
                        sessionStorage.clear();
                        localStorage.clear();
                        window.location.pathname = "/";
                    },
                    reject: () => {
                        //yes
                    },
                });
                console.warn("Success");
            }).catch((err) => {
                this.$confirm.require({
                    acceptLabel: "Try Again",
                    acceptClass: "p-button p-button-danger",
                    message: JSON.stringify({
                        text: "Invalid current password",
                        type: "INFORM",

                    }),
                    icon: "pi pi-times",
                    accept: () => {
                        //yes
                    },
                });
                console.warn(err);
            }
            );
        }
        else this.$confirm.require({
            acceptLabel: "Try Again",
            acceptClass: "p-button p-button-danger",
            message: JSON.stringify({
                text: "Passwords did not match",
                type: "INFORM",

            }),
            icon: "pi pi-times",
            accept: () => {
                //yes
            },
        });
    }
}