import { execution } from "@/models/FormBuilderModel";
import { ValueTypes } from "@/shared/FormBuilderShared";
import { ICustomFunction } from "../ICustomFunction";

export class CallExecutions {
    private exec: execution;
    private func: ICustomFunction;

    constructor(exec: execution, func: ICustomFunction) {
        this.exec = exec;
        this.func = func;
    }

    execute() {
        const executions = this.func.getValue({
            type: ValueTypes.FIELD,
            value: this.exec.field,
            isFixed: true,
            source: this.exec.source
        })
        if (executions && executions.length > 0) return this.func.run(executions);
    }
}