import { AsyncAllegationManageHandler } from "@/components/AsyncComponents";

export const withAllegationManageWrapper = (WrappedComponent: any) => {
  return {
    template: `<AllegationManageHandler><WrappedComponent></WrappedComponent></AllegationManageHandler>`,
    components: {
      WrappedComponent,
      AllegationManageHandler: AsyncAllegationManageHandler
    }
  }
}