import { Options } from "vue-class-component";
import { bindFormBuilderService } from "@/IoC/FormBuilderIoC";
import {
    Template,
    TemplateDataRequest
} from "@/models/TemplateModel";
import { IFormBuilderService } from "@/services/form-builder/IFormBuilderService";
import { IoCTypes } from "@/IoC/IoCTypes";
import {
    ComputedRef,
    computed,
    inject
} from "vue";
import {
    execution,
    Validation
} from "@/models/FormBuilderModel";
import BaseHandler from "../BaseHandler";
import { FileMetadata } from "@/models/FilesModel";
import CaseTemplate from "../../../public/assets/testdata/case_creation.json"
import SSVITemplate from "../../../public/assets/testdata/ssvi.json"
import SSV3Template from "../../../public/assets/testdata/ssv3.json"
import SSV2Template from "../../../public/assets/testdata/ssv2.json"
import SSV6Template from "../../../public/assets/testdata/ssv6.json"
import SSV5Template from "../../../public/assets/testdata/ssv5.json"
import RetaliationTemplate from "../../../public/assets/testdata/retaliation_report.json"
import AdministrativeInvestigationTemplate from "../../../public/assets/testdata/report_administrativeInvestigation.json"
// import BaseTemplate from "../../../public/assets/testdata/Base_template_v2.json"
import SAIRTemplate from "../../../public/assets/testdata/SAIR.json"
import SARTTemplate from "../../../public/assets/testdata/SART.json"

import OUTCOMEMEMOTemplate from "../../../public/assets/testdata/OutcomeMemo.json"
import SVreportsTemplate from "../../../public/assets/testdata/sexual_victimization_report.json"


import InmateQuestionnaireTemplate from "../../../public/assets/testdata/inmate_questionnaire.json"
// import RetaliationData from "../../../public/assets/testdata/retaliation_data.json"
import BaseTemplate from "../../../public/assets/testdata/Base_template_v2.json"
import { GlobalConfigService } from "@/services/GlobalConfigService"
//import AdministrativeInfo from "../../../public/assets/testdata/report_administrativeInvestigation_data.json";


import { Container } from "inversify";
import {
    formatMultidDate,
    formattedDate, formattedDateTime, formattedDateTimeReports, formattedTime, Helpers, increaseDate
} from "@/lib/Util";
import {
    ExecuteMethods,
    ValueSource,
    ValueTypes
} from "@/shared/FormBuilderShared";
import { convertDate } from "@/components/filters/ConvertDate";

type TProvider = {
    template: ComputedRef<Template>;
    templateData: ComputedRef<TemplateDataRequest>;
    isLoading: ComputedRef<boolean>;
    IsReadOnly: boolean;
    handleSave: () => void;
    handleSubmit: () => void;
    handleCancel: (executions?: execution[]) => void;
    isInitialHide: (executions: boolean | execution[] | undefined) => any;
    IoC: Container;
    fieldComponents: any;
    containerComponents: any;
    validationListeners: Validation;
}

@Options({
    template: `<c-validation-listener v-model="validationListeners[0].method"><slot></slot></c-validation-listener>`,
    provide(): TProvider {
        return {
            template: computed(() => this.template),
            templateData: computed(() => this.templateData),
            isLoading: computed(() => this.isLoading),
            IsReadOnly: false,
            handleSave: this.handleSave,
            handleSubmit: () => { return },
            handleCancel: this.handleCancel,
            isInitialHide: this.isInitialHide,
            IoC: this.IoC,
            fieldComponents: this.fieldComponents,
            containerComponents: this.containerComponents,
            validationListeners: this.validationListeners
        }
    }
})
export default class FormHandler extends BaseHandler {
    template: Template = {
        id: "",
        name: ""
    };
    templateData: TemplateDataRequest = {
        templateId: "",
        jsonData: {},
    };
    isLoading = true;

    validationListeners: Validation[] = [{ name: "main-form" }];
    formBuilderService!: IFormBuilderService;
    executeFunction: (executions: execution[], currentObject?: any) => any = inject("executeFunction") as (executions: execution[], currentObject?: any) => any;
    attachmentData: any = inject("attachmentData");
    clearAttachments: (res: any) => void = inject("clearAttachments") as (res: any) => void;
    state: { [name: string]: any } = {
        onSaveAuxiliary: [],
        beforeSaveAuxiliary: []
    }
    func = {
        getFormatMultidDate:formatMultidDate,
        getFormattedDate: formattedDate,
        getFormattedTime: formattedTime,
        getFormattedDateTime: formattedDateTime,
        increaseDateByNumber: increaseDate,
        getUTCNowDate: Helpers.getUTCNow,
        getConvertedDate: convertDate,
        getDateDifferenceInYears: Helpers.getDateDifferenceInYears,
        getFormattedDateTimeReports: formattedDateTimeReports
    };
    setCanCheckDirtyValue: (canCheck: boolean) => void = inject("setCanCheckDirtyValue") as (canCheck: boolean) => void;
    setInitialNavValue: (value: any) => void = inject("setInitialNavValue") as (
        value: any
    ) => void;
    setCurrentNavValue: (value: any) => void = inject("setCurrentNavValue") as (
        value: any
    ) => void;
    isNavValueDirty: () => boolean = inject("isNavValueDirty") as () => boolean;

    fieldComponents: any = {};
    containerComponents: any = {};

    beforeCreate() {
        bindFormBuilderService(this.IoC);
        this.formBuilderService = this.IoC.get<IFormBuilderService>(IoCTypes.FormBuilderService);
    }

    setLoading(isLoading: boolean) {
        this.isLoading = isLoading;
    }

    created() {
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        })
        this.setInitialNavValue(this.templateData);
        this.setCurrentNavValue(this.templateData);
        this.template.name = String(this.$route.query.name);
        this.template.incType = this.$route.query.type ? String(this.$route.query.type) : "case";
        this.template.version = this.$route.query.year ? Number(this.$route.query.year) : 0;

        if (this.$route.params.id) {
            this.loadTemplateData(String(this.$route.params.id));
        }
        else this.getTemplate();
    }

    loadTemplateData(id: string, getTemplate = true) {
        this.formBuilderService.getTemplateData(id).then(res => {
            this.templateData = res;
            this.setInitialNavValue(this.templateData);
            this.setCurrentNavValue(this.templateData);
            if (res.metadata) {
                res.metadata?.forEach((x: FileMetadata) => {
                    x.file = "data:".concat(x.fileType, ";base64,", x.file)
                })
                this.attachmentData.attachmentsMetadata.push(...res.metadata);
            }
            if (getTemplate) this.getTemplate();
        }).catch((err: PromiseRejectedResult) => {
            console.error(err);
            this.$confirm.require({
                message: JSON.stringify({
                    type: "INFORM",
                    status: "WARNING",
                    text: "OOPS! You don't have access to open this case"
                }),
                header: "Access Denied",
                acceptLabel: "Ok",
                acceptClass: "p-button p-button-danger",
                icon: "pi pi-exclamation-triangle",
                accept: () => {
                    this.$router.back();
                }
            });
            this.isLoading = false
        });
    }

    async validateForm(from: string, to?: string) {
        const fromIndex = this.validationListeners.findIndex(x => x.name === from);
        const endIndex = to ? this.validationListeners.findIndex(x => x.name === to) + 1 : this.validationListeners.length;
        const selectedvalidationListeners = this.validationListeners.slice(fromIndex, endIndex);
        const validationResults = await Promise.all(selectedvalidationListeners.map(x => x.method && x.method()));
        const falseResult = validationResults.find(x => x?.valid === false);
        if (falseResult) {
            const errorKeys = Object.keys(falseResult.errors);
            if (errorKeys.length > 0) {
                document.getElementById(errorKeys[0])?.focus();
            }
            return false;
        }
        return true;
    }

    async handleSave(executions: execution[] | undefined, onEvent?: execution[] | undefined) {

        if (onEvent && !this.executeFunction(onEvent, this)) return;
        if (!await this.validateForm("main-form")) return;
        this.saveTemplateData(executions);
    }

    isInitialHide(executions: boolean | execution[] | undefined) {
        if (executions === true) return true;
        else if (executions === undefined || executions === false) return false;
        return this.executeFunction(executions, this);
    }

    handleCancel(executions: boolean | execution[] | undefined) {
        if (executions === true) return true;
        else if (executions === undefined || executions === false) return false;
        return this.executeFunction(executions, this);
    }
    currentTemplate = ""
    private getTemplate() {
        this.currentTemplate = GlobalConfigService.Instance.getCurrentTemplate();
        this.formBuilderService.getTemplate(this.templateData.templateId, this.template.name, this.template.version)
            .then((res: Template) => {
                if (process.env.NODE_ENV === "development") {
                    if (this.$route.query.name === "ssvi") res.jsonTemplate = SSVITemplate as any;
                    if (this.$route.query.name === "sair") res.jsonTemplate = SAIRTemplate as any;
                    if (this.$route.query.name === "sart") res.jsonTemplate = SARTTemplate as any;
                    if (this.$route.query.name === "outcomeMemo") res.jsonTemplate = OUTCOMEMEMOTemplate as any;
                    if (this.$route.query.name === "sexualVictimization") res.jsonTemplate = SVreportsTemplate as any;
                    else if (this.$route.query.name === "ssv3") res.jsonTemplate = SSV3Template as any;
                    else if (this.$route.query.name === "ssv2") res.jsonTemplate = SSV2Template as any;
                    else if (this.$route.query.name === "ssv6") res.jsonTemplate = SSV6Template as any;
                    else if (this.$route.query.name === "ssv5") res.jsonTemplate = SSV5Template as any;
                    else if ((this.$route.query.name === "case" || this.$route.path.includes("case")) && this.currentTemplate === "minnesota") res.jsonTemplate = CaseTemplate as any;
                    else if ((this.$route.query.name === "case" || this.$route.path.includes("case")) && this.currentTemplate === "base") res.jsonTemplate = BaseTemplate as any;
                    else if (this.$route.query.name === "case" || this.$route.path.includes("case")) res.jsonTemplate = CaseTemplate as any;
                    // else if (this.$route.query.name === "case" || this.$route.path.includes("case")) res.jsonTemplate = BaseTemplate as any;
                    else if (this.$route.query.name === "monitoring") {
                        res.jsonTemplate = RetaliationTemplate as any;
                    }

                    else if (this.$route.query.name === "administrativeInvestigation") {
                        res.jsonTemplate = AdministrativeInvestigationTemplate as any;
                        //this.templateData.jsonData = AdministrativeInfo;
                    }

                    else if (this.$route.query.name === "inmate_questionnaire" || this.$route.path.includes("inmate")) res.jsonTemplate = InmateQuestionnaireTemplate as any;
                }

                this.template = res;
                if (this.template.jsonTemplate?.onCreate) this.executeFunction(this.template.jsonTemplate?.onCreate, this)
            })
            .catch((err: PromiseRejectedResult) => console.error(err))
            .finally(() => this.isLoading = false);
    }

    private saveTemplateData(executions: execution[] | undefined) {
        this.isLoading = true;
        const postData: TemplateDataRequest = {
            id: this.templateData.id,
            jsonData: JSON.stringify(this.templateData.jsonData),
            templateId: String(this.template?.id),
            onSaveAuxiliary: this.state.onSaveAuxiliary,
            beforeSaveAuxiliary: this.state.beforeSaveAuxiliary
        }

        const files: File[] = [];
        files.push(...this.attachmentData.attachments)
        postData.metadata = this.attachmentData.attachmentsMetadata;
        return this.formBuilderService.saveTemplateData(postData, files).then((res: string) => {
            this.formBuilderService.getCaseAttachments(res).then((response: any) => {
                this.clearAttachments(response)
            })
            this.setInitialNavValue(this.templateData);
            if (executions) {
                const newExecutions = [...executions];
                newExecutions.splice(0, 0, {
                    method: ExecuteMethods.SET_VARIABLE,
                    source: ValueSource.LOCAL_VARIABLE,
                    field: "asyncResponse",
                    value: {
                        type: ValueTypes.STATIC,
                        value: res
                    }
                });
                this.executeFunction(newExecutions, this);
            }
        }).catch((err: PromiseRejectedResult) => {
            this.$toast.add({
                severity: "error",
                summary: "Failed",
                detail: err,
                life: 3000
            });
        }).finally(() => this.isLoading = false);
    }

}
