import { IoCTypes } from "@/IoC/IoCTypes";
import { bindInmateListService } from "@/IoC/InmateIoC";
import {
    InmateList,
    InmateListRequest, InmateListResponse, inmateQuestionnaireResponse, InmateResponse
} from "@/models/InmateModel";
import { PaginateList } from "@/models/ListModel";
import { ListQuery } from "@/models/ListQuery";
import { IInmateListService } from "@/services/inmate/IInmateListService";
import {
    computed,
    ComputedRef
} from "vue";
import { Options } from "vue-class-component";
import ListHandler from "../common/list/ListHandler";
import { ICommonStoreService } from "@/services/common/ICommonStoreService";
import { CommonStoreService } from "@/services/common/CommonStoreService";
import { InmateApi } from "@/api/InmateApi";


type TProvider = {
    inmateSearchRequest: ComputedRef<InmateList>;
    getList: ComputedRef<Promise<PaginateList<InmateListResponse>>>;
    inmateList: ComputedRef<InmateListResponse[]>;
    questionnaireList: ComputedRef<inmateQuestionnaireResponse[]>;
    handlePageChange: () => void;
    inmateQuestionnaire: () => any;
    listQuery: ComputedRef<ListQuery>;
    listDetails: ComputedRef<any>,
    loadList: () => void,
    refreshList: () => void,
    commonStoreService: ICommonStoreService;
    getUserList: () => Promise<InmateResponse[]>;
}

@Options({
    template: `<slot></slot>`,
    provide(): TProvider {
        return {
            getList: computed(() => this.getList),
            inmateSearchRequest: computed(() => this.inmateSearchRequest),
            inmateList: computed(() => this.inmateList),
            listDetails: computed(() => this.listDetails),
            questionnaireList: computed(() => this.questionnaireList),
            inmateQuestionnaire: this.inmateQuestionnaire,
            handlePageChange: this.handlePageChange,
            listQuery: computed(() => this.listQuery),
            loadList: this.loadList,
            refreshList: this.refreshList,
            commonStoreService: this.commonStoreService,
            getUserList: this.getUserList
        }
    }
})
export default class InmateModuleHandler extends ListHandler<InmateListResponse> {

    inmateListService!: IInmateListService;
    commonStoreService: ICommonStoreService = new CommonStoreService();
    inmateSearchRequest: InmateList = {};
    questionnaireList: any = [];
    InmateApi = new InmateApi();

    beforeCreate() {
        bindInmateListService(this.IoC);
        this.inmateListService = this.IoC.get<IInmateListService>(IoCTypes.InmateListServices);
        this.commonStoreService = this.IoC.get<ICommonStoreService>(IoCTypes.CommonStoreService);
    }

    created() {
        this.commonStoreService.setNeedInmateListRefresh(true);
    }

    get inmateList(): InmateListResponse[] {
        return this.pages[this.listQuery.page];
    }

    inmateQuestionnaire(id: any) {
        this.inmateListService.getInmateQuestionnaire(id).then((res: inmateQuestionnaireResponse) => {
            this.questionnaireList = res
            return res
        })
    }

    getList(searchQuery: ListQuery): Promise<PaginateList<InmateListResponse>> {
        const payload: InmateListRequest = {
            page: searchQuery.page,
            size: searchQuery.size,
            inmateId: this.inmateSearchRequest.inmateId,
            bookingId: this.inmateSearchRequest.bookingId,
            inmateName: this.inmateSearchRequest.inmateName,
            bookingStartDate: this.inmateSearchRequest.bookingStartDate,
            bookingEndDate: this.inmateSearchRequest.bookingEndDate,
            questionaire: this.inmateSearchRequest.questionaire,
            searchText: "",
            filterText: ""
        }
        return this.inmateListService.getInmateList(payload)


    }

    getUserList(query: string, searchType: string) {
        return this.InmateApi.getInmateDetails(query, searchType).then((res) => {
            return res;
        })
    }
}