import { HttpUtil } from "@/lib/HttpUtil";
import { RemoteOptions } from "@/models/FormBuilderModel";
import {
    FieldOption,
    PaginateList
} from "@/models/ListModel";

export class LookupApi {

    httpUtil: HttpUtil = new HttpUtil();

    getLookupDetails(options: RemoteOptions): Promise<FieldOption[]> {
        return this.httpUtil.get<PaginateList<any>>(options.url).then((res: any) => {            
            const list: FieldOption[] = [];
            const items = res.items || res;
            if (res && items) {
                for (const item of items) list.push({
                    value: item[options.valAttr],
                    hiddenValue: options.hiddenValAttr ? item[options.hiddenValAttr] : undefined,
                    text: item[options.textAttr]
                });
            }
            return list;
        })
    }
}