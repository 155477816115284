import { Helpers } from "@/lib/Util";
import {
    arithmetic,
    comparision,
    DynamicApi,
    execution,
    Value
} from "@/models/FormBuilderModel";
import {
    ArithmeticTypes,
    ComparisionTypes,
    ComparisonSubTypes,
    ExecuteMethods,
    ValueSource,
    ValueTypes
} from "@/shared/FormBuilderShared";
import { Condition } from "./executions/Condition";
import { Find } from "./executions/Find";
import { SetExecutionVariable } from "./executions/SetExecutionVariable";
import { SetVariable } from "./executions/SetVariable";
import { ICustomFunction } from "./ICustomFunction";
import { ApiCall } from "./executions/ApiCall";
import { ForEach } from "./executions/ForEach";
import { ObjectCreation } from "./executions/ObjectCreation";
import { CallFunction } from "./executions/CallFunction";
import { CallExecutions } from "./executions/CallExecutions";
import { Filter } from "./executions/Filter";
import { Push } from "./executions/Push";
import { SetFunction } from "./executions/SetFunction";
import { JsonParse } from "./executions/JsonParse";
import { GetValue } from "./executions/GetValue";
import { SpreadOperator } from "./executions/SpreadOperator";
import { Sort } from "./executions/Sort";

export class CustomFunction implements ICustomFunction {
    private component: any;
    private handler: any;
    private variables: any = {};

    constructor(handler: any, component: any,) {
        this.handler = handler;
        this.component = component;
    }

    getValue(val: Value) {
        if (val.type === ValueTypes.FIELD) {
            if (val.source === ValueSource.COMPONENT) return Helpers.processObjectFieldValue(this.component, val.value)
            else if (val.source === ValueSource.LOCAL_VARIABLE) return Helpers.processObjectFieldValue(this.variables, val.value)
            else if (val.source === ValueSource.BUILT_IN) return Helpers.processObjectFieldValue(window, val.value);
            return Helpers.processObjectFieldValue(this.handler, val.value)
        }
        else if (val.type === ValueTypes.EXECUTIONS && val.executions) {
            return this.run(val.executions);
        }
        return val.value
    }

    setValue(field: string, value: any, source?: ValueSource) {
        if (source === ValueSource.COMPONENT) Helpers.processObjectFieldValue(this.component, field, value, true)
        else if (source === ValueSource.LOCAL_VARIABLE) Helpers.processObjectFieldValue(this.variables, field, value, true)
        else Helpers.processObjectFieldValue(this.handler, field, value, true)
    }

    getParameters(params: Value[]) {
        const values = [];
        if (params && params.length > 0) {
            for (const param of params) values.push(this.getValue(param))
        }
        return values;
    }

    getDynamicApiResponse(api: DynamicApi) {
        return this.handler.getDynamicApiResponse(api)
    }


    postDynamicApiResponse(api: DynamicApi) {
        return this.handler.postDynamicApiResponse(api)
    }

    compare(comparisions: comparision[]) {
        let result = true;
        if (comparisions && comparisions.length > 0) {
            for (const comp of comparisions) {
                if (comp.subType === ComparisonSubTypes.OR) result ||= this.getCompareResult(comp)
                else result &&= this.getCompareResult(comp)
            }
        }
        return result;
    }

    arithmetic(arithmetics: arithmetic[]) {
        let val;
        if (arithmetics && arithmetics.length > 0) {
            for (const arith of arithmetics) {
                if (arith.type === ArithmeticTypes.INITIAL) val = this.getValue(arith.value)
                else if (arith.type === ArithmeticTypes.ADDITION) val += this.getValue(arith.value)
                else if (arith.type === ArithmeticTypes.SUBTRACTION) val -= this.getValue(arith.value)
                else if (arith.type === ArithmeticTypes.MULTIPLICATION) val *= this.getValue(arith.value)
                else if (arith.type === ArithmeticTypes.DIVISION) val /= this.getValue(arith.value)
            }
        }
        return val;
    }

    run(executions: execution[]): any {
        for (const exec of executions) {
            if (this.variables.return) return;
            switch (exec.method) {
                case ExecuteMethods.SET_VARIABLE:
                    new SetVariable(exec, this).execute();
                    break;
                case ExecuteMethods.SET_VARIABLE_FROM_EXECUTIONS:
                    new SetExecutionVariable(exec, this).execute();
                    break;
                case ExecuteMethods.CONDITION:
                    return new Condition(exec, this).execute();
                case ExecuteMethods.PUSH:
                    new Push(exec, this).execute();
                    break;
                case ExecuteMethods.FIND:
                    return new Find(exec, this).execute();
                case ExecuteMethods.SORT:
                    return new Sort(exec, this).execute();
                case ExecuteMethods.FILTER:
                    return new Filter(exec, this).execute();
                case ExecuteMethods.API_EXECUTIONS:
                    new ApiCall(exec, this).execute();
                    break;
                case ExecuteMethods.FOR_EACH:
                    new ForEach(exec, this).execute();
                    break;
                case ExecuteMethods.CONSOLE_LOG:
                    // eslint-disable-next-line no-console
                    console.log(this.getValue(exec.value));
                    break;
                case ExecuteMethods.OBJECT_CREATION:
                    return new ObjectCreation(exec, this).execute();
                case ExecuteMethods.ARITHMETIC:
                    if (exec.arithmetics) return this.arithmetic(exec.arithmetics);
                    return;
                case ExecuteMethods.CALL_FUNCTION:
                    return new CallFunction(exec, this).execute();
                case ExecuteMethods.EXECUTIONS:
                    if (exec.executions) this.run(exec.executions);
                    break;
                case ExecuteMethods.CALL_EXECUTIONS:
                    return new CallExecutions(exec, this).execute();
                case ExecuteMethods.SET_FUNCTION:
                    new SetFunction(exec, this).execute();
                    break;
                case ExecuteMethods.JSON_PARSE:
                    return new JsonParse(exec, this).execute();
                case ExecuteMethods.GET_VALUE:
                    return new GetValue(exec, this).execute();
                case ExecuteMethods.SPREAD_OPERATOR:
                    return new SpreadOperator(exec, this).execute();
                default:
                    break;
            }
        }
    }

    private getCompareResult(comp: comparision) {
        const source = this.getValue(comp.source);
        const target = this.getValue(comp.target);
        switch (comp.type) {
            case ComparisionTypes.EQUAL:
                if (source === target) return true;
                break;
            case ComparisionTypes.NOT_EQUAL:
                if (source !== target) return true;
                break;
            case ComparisionTypes.LESS_THAN:
                if (source < target) return true;
                break;
            case ComparisionTypes.LESS_THAN_EQUAL:
                if (source <= target) return true;
                break;
            case ComparisionTypes.GREATER_THAN:
                if (source > target) return true;
                break;
            case ComparisionTypes.GREATER_THAN_EQUAL:
                if (source >= target) return true;
                break;
            default:
                return false;
        }
        return false;
    }

}