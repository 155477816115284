import { IRoleListService } from "@/services/role/IRoleListService";
import { IRoleManageService } from "@/services/role/manage/IRoleManageService";
import { RoleListService } from "@/services/role/RoleListService";
import { RoleManageService } from "@/services/role/manage/RoleManageService"
import { Container } from "inversify";
import { IoCTypes } from "./IoCTypes";
import { bindLookupStoreService } from "./LookupIoC";

export function bindRoleListService(container: Container) {
    container.bind<IRoleListService>(IoCTypes.RoleListService).to(RoleListService);
    bindLookupStoreService(container);
}

export function bindRoleManageService(container: Container) {
    container.bind<IRoleManageService>(IoCTypes.RoleManageService).to(RoleManageService);
    bindLookupStoreService(container);
}