import { authorizePermission } from "@/lib/AuthUtil";

const created = (el: any, binding: any) => {
    const permission: string = binding.value;
    if (authorizePermission(permission)) el.style.display = "block"
    else el.style.display = "none"
}

const PermissionDirective = { created }

export default PermissionDirective;