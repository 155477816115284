import { UserApi } from "@/api/UserApi";
import { PaginateList } from "@/models/ListModel";
import { ListQuery } from "@/models/ListQuery";
import { UserListResponse } from "@/models/UserModel";
import { injectable } from "inversify";
import { IUserListService } from "./IUserListService";

@injectable()
export class UserListService implements IUserListService {
    userApi = new UserApi();
    getUsers(query: ListQuery): Promise<PaginateList<UserListResponse>> {
        return this.userApi.getUsers(query)
    }
}