import { withManageUser } from "@/handlers/user/ManageUserWrapper";
import { withUserList } from "@/handlers/user/UserListWrapper";
import { withUserModule } from "@/handlers/user/UserModuleWrapper";
import { defineAsyncComponent } from "vue";
import { RouteRecordRaw } from "vue-router";
import { Permissions } from "../shared/ClaimsShared"

const routes: Array<RouteRecordRaw> = [{
    path: "users",
    component: withUserModule(defineAsyncComponent(() => import("../views/user/UserModulePage.vue"))),
    children: [{
        path: "",
        component: withUserList(defineAsyncComponent(() => import("../views/user/user-list/UserListPage.vue"))),
        meta: { permission: Permissions.readUser }
    }, {
        path: "add",
        component: withManageUser(defineAsyncComponent(() => import("../views/user/manage-user/ManageUserPage.vue"))),
        meta: { permission: Permissions.createUser }
    }, {
        path: "edit/:id",
        component: withManageUser(defineAsyncComponent(() => import("../views/user/manage-user/ManageUserPage.vue"))),
        meta: { permission: Permissions.createUser }
    }]
}]

export default routes;