import { AsyncInmateListHandler } from "@/components/AsyncComponents"

export const withInmateList = (WrappedComponent: any) => {
    return {
        template: `<Handler><WrappedComponent></WrappedComponent></Handler>`,
        components: {
            WrappedComponent,
            Handler: AsyncInmateListHandler
        },
    }
}