export interface ResetRequest {
   confirmpassword: string;
    password: string;
    email: string;
    token: string;
}

export interface ResetResponse {
    message: string;
  }

  export const InitResetRequest: ResetRequest = {
      confirmpassword:"",
      password: "",
      email:"",
      token: ""
    }