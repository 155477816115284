import { AllegationsApi } from "@/api/AllegationsApi";
import { CommonApi } from "@/api/CommonApi";
import { AllegationsResponse } from "@/models/AllegationsModel";
import { injectable } from "inversify";
import { IAllegationManageService } from "./IAllegationManageService";

@injectable()
export default class AllegationManageService implements IAllegationManageService {
  allegationApi: AllegationsApi = new AllegationsApi();
  commonApi: CommonApi = new CommonApi();
  getAllegationCurrentNumber(): Promise<string> {
    return this.commonApi.getFeatureCurrentNumber("Allegation");
  }

  async postNewAllegation(allegation: AllegationsResponse): Promise<boolean> {
    allegation.allegationStatus = "New";
    await this.getAllegationCurrentNumber().then((res: string) => {
      allegation.allegationNo = res;
    })
    return this.allegationApi.postNewAllegation(allegation);
  }

  getAllegationById(id: string): Promise<AllegationsResponse> {
    return this.allegationApi.getAllegationById(id);
  }
}