import { HttpUtil } from "@/lib/HttpUtil";
import { ClaimResponse } from "@/models/Claims";
import { PaginateList } from "@/models/ListModel";
import { ListQuery } from "@/models/ListQuery";
import {
    UserListResponse,
    UserRequest,
    UserResponse
} from "@/models/UserModel";
import {
    UsersCreateResponse,
    UsersUpdateResponse,
    UserdeleteResponse
} from "@/shared/UserShared";
import qs from "qs";

export class UserApi {
    httpUtil = new HttpUtil("IDP");
    httpAPIUtil = new HttpUtil("API");

    getUsers(query: ListQuery): Promise<PaginateList<UserListResponse>> {
        return this.httpAPIUtil.get(`/user?${qs.stringify(query)}`)
    }

    getUser(id: string): Promise<UserResponse> {
        return this.httpUtil.get(`api/v1/user/${id}`).then((res) => {
            return new HttpUtil().get(String("user/") + id).then((attrRes) => {
                res.facilities = attrRes.facilities
                return res;
            }).catch(() => res)
        })
    }

    addUser(request: UserRequest): Promise<UsersCreateResponse> {
        return this.httpUtil.postForm<string>("api/v1/user", request).then((res: string) => {
            request.id = res;
            return new HttpUtil().post("user", request).then(() => {
                return UsersCreateResponse.USER_CREATED
            }).catch(() => UsersCreateResponse.ACCOUNT_CREATED)
        });
    }

    updateUser(request: UserRequest): Promise<UsersUpdateResponse> {
        return this.httpUtil.putForm<string>("api/v1/user", request, { id: request.id }).then(() => {
            return new HttpUtil().put("user", request, { id: request.id }).then(() => {
                return UsersUpdateResponse.USER_UPDATED
            }).catch(() => UsersUpdateResponse.ACCOUNT_UPDATED)
        });
    }

    deleteUser(userid: any): Promise<UserdeleteResponse> {
        return this.httpUtil.deleteForm<any>("api/v1/user", userid).then(() => {
            return new HttpUtil().delete("user", userid.userid).then(() => {
                return UserdeleteResponse.USER_DELETED
            }).catch(() => UserdeleteResponse.ACCOUNT_DELETED)
        });
    }

    getUserClaims(): Promise<ClaimResponse> {
        return this.httpAPIUtil.get(`api/v1/user/claims`);
    }
}