export const IoCTypes = {
    LoginService: Symbol.for("LoginService"),
    ClaimService: Symbol.for("ClaimService"),
    ProfileService: Symbol.for("ProfileService"),
    ForgotService: Symbol.for("ForgotService"),
    ResetService: Symbol.for("ResetService"),
    CaseListService: Symbol.for("CaseListService"),
    FormBuilderService: Symbol.for("FormBuilderService"),
    FormExecutionService: Symbol.for("FormExecutionService"),
    FormBuilderStoreService: Symbol.for("FormBuilderStoreService"),
    FileUploadService: Symbol.for("FileUploadService"),
    FileUploadComponentService: Symbol.for("FileUploadComponentService"),
    FileViewService: Symbol.for("FileViewService"),
    CaseTypesService: Symbol.for("CaseTypesService"),
    CaseService: Symbol.for("CaseService"),
    AllegationModuleService: Symbol.for("AllegationModuleService"),
    CommonService: Symbol.for("CommonService"),
    CommonStoreService: Symbol.for("CommonStoreService"),
    AllegationSearchListService: Symbol.for("AllegationSearchListService"),
    FacilityListService: Symbol.for("FacilityListService"),
    NotificationListService: Symbol.for("NotificationListService"),
    AppService: Symbol.for("AppService"),
    AllegationManageService: Symbol.for("AllegationManageService"),
    LookupService: Symbol.for("LookupService"),
    LookupStoreService: Symbol.for("LookupStoreService"),
    ManageUserService: Symbol.for("ManageUserService"),
    RoleListService: Symbol.for("RoleListService"),
    UserListService: Symbol.for("UserListService"),
    RoleManageService: Symbol.for("RoleManageService"),
    GroupManageService: Symbol.for("GroupManageService"),
    GroupListService: Symbol.for("GroupListService"),
    ManageInmateService: Symbol.for("ManageInmateService"),
    InmateListServices: Symbol.for("InmateListService"),
    AppConfigService: Symbol.for("AppConfigService"),
    AppConfigStoreService: Symbol.for("AppConfigStoreService")
}
