import { AppConfig } from "@/models/AppConfig";
import {
    Module, Mutation, VuexModule
} from "vuex-module-decorators";
import { AppConfigState } from "./AppConfigStoreTypes";

@Module({
    namespaced: true,
    name: "appConfig"
})


export default class AppConfigStore extends VuexModule implements AppConfigState {
    latestAppConfigTypes!: AppConfig[];
    get AppConfigTypes(): AppConfig[] { return this.latestAppConfigTypes }
    @Mutation
    SAVE_APP_CONFIG_TYPES(appConfigTypes: AppConfig[]) {
        this.latestAppConfigTypes = appConfigTypes;
    }

}

