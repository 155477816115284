import { injectable } from "inversify";
import { FilesApi } from "../../../api/FilesApi";
import { IFileViewService } from "./IFileViewService";

@injectable()
export class FileViewService implements IFileViewService {
  filesApi = new FilesApi();
  getTemplateDataAttachments(entity: string, category: string, templateDataId: string): Promise<any> {
    return this.filesApi.getTemplateDataAttachments(entity, category, templateDataId);
  }
  getAttachments(attachmentIds: string[]): Promise<any> {
    return this.filesApi.getAttachments(attachmentIds);
  }
  deleteTemplateAttachments(attachmentIds: string[]): Promise<boolean> {
    return this.filesApi.deleteTemplateAttachments(attachmentIds)
  }
  downloadFile(attachmentId: string): Promise<any> {
    return this.filesApi.downloadAttachment(attachmentId)
  }
}