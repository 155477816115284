
export interface LoginRequest {
  username: string;
  password: string;
  rememberMe?: boolean;
}
export interface LoginResponse {
  data: any;
  access_token: string;
  expires_in: number;
  refresh_token: string;
  scope: string;
  token_type: string;
  userProfile: string;
  userClaims: string;
  resetToken : string;
}

export const InitLoginRequest: LoginRequest = {
  username: "",
  password: ""
}