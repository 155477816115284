import { ICommonStoreService } from "@/services/common/ICommonStoreService";
import { inject } from "vue";
import { Options } from "vue-class-component";
import BaseHandler from "../BaseHandler";


@Options({ template: `<slot></slot>` })
export default class InmateListHandler extends BaseHandler {
    refreshList: () => void = inject("refreshList") as () => void;
    commonStoreService: ICommonStoreService = inject("commonStoreService") as ICommonStoreService;

    created() {
        if (this.commonStoreService.NeedInmateListRefresh) {
            this.refreshList();
            this.commonStoreService.setNeedInmateListRefresh(false);
        }
    }
}   