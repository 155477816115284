import DirtyChecker from "./DirtyChecker";

export const withNavigationBlocker = (WrappedComponent: any) => ({
  template: `<DirtyChecker ref="dirtyChecker"><WrappedComponent></WrappedComponent></DirtyChecker>`,
  components: {
    WrappedComponent,
    DirtyChecker
  },
  $refs: { dirtyChecker: DirtyChecker },
  beforeRouteLeave(_to: any, _from: any, next: any) {
    if ((this.$refs.dirtyChecker as any).canNavigate(next)) next();
  }
})

