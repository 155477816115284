import { Container } from "inversify";
import { bindFacilityListService } from "@/IoC/FacilityIoC";
import { IoCTypes } from "@/IoC/IoCTypes";
import { IFacilityListService } from "@/services/facility/facility-list/IFacilityListService";
import { Options } from "vue-class-component";
import { FacilityListResponse } from "@/models/FacilityModal";
import {
  computed, ComputedRef, inject
} from "vue";
import ListHandler from "@/handlers/common/list/ListHandler";
import {
  CaseListRequest,
  CaseListResponse,
  StaffResponse
} from "@/models/CaseModal";
import { ListQuery } from "@/models/ListQuery";
import { ICaseListService } from "@/services/case/case-list/ICaseListService";
import { bindCaseListService } from "@/IoC/CaseListIoC";
import { InmateApi } from "@/api/InmateApi";
import { StaffApi } from "@/api/StaffApi";
import { InmateResponse } from "@/models/InmateModel";
type TProvider = {
  caseList: ComputedRef<CaseListResponse[]>;
  facilityList: ComputedRef<FacilityListResponse>
  isFacilityLoading: ComputedRef<boolean>,
  searchRefreshList: () => void,
  loadList: () => void,
  deleteCase: (id: string) => void,
  handlePageChange: () => void;
  listQuery: ComputedRef<ListQuery>;
  listDetails: ComputedRef<any>;
  sortCaseList: () => void;
  getUserList: () => Promise<InmateResponse[]>;
  getStaffList: () => Promise<StaffResponse[]>
}


@Options({
  template: `<slot></slot>`,
  provide(): TProvider {
    return {
      caseList: computed(() => this.caseList),
      facilityList: computed(() => this.facilityList),
      isFacilityLoading: computed(() => this.isFacilityLoading),
      searchRefreshList: this.refreshList,
      loadList: this.loadList,
      deleteCase: this.deleteCase,
      listDetails: computed(() => this.listDetails),
      handlePageChange: this.handlePageChange,
      listQuery: computed(() => this.listQuery),
      sortCaseList: this.sortBy,
      getUserList: this.getUserList,
      getStaffList: this.getStaffList
    }
  }
})
export default class CaseListHandler extends ListHandler<CaseListResponse> {
  container: Container = new Container();
  facilityListService!: IFacilityListService;
  caseListService!: ICaseListService;
  facilityList: FacilityListResponse[] = [];
  isFacilityLoading = true;
  filterPage: any = inject("filterPage")
  activityRefreshList: () => void = inject("refreshList") as () => void
  activityLoadList: () => void = inject("activityLoadList") as () => void
  InmateApi = new InmateApi();
  StaffApi = new StaffApi();

  getFacilityList() {
    this.facilityListService.getFacilityList().then((res: any) => {
      if (res && res.length > 0) {
        this.facilityList = res.map((x: any) => {
          return {
            text: x.text,
            value: x.value
          }
        })
      }
      this.isFacilityLoading = false;
    }).catch(() => this.isFacilityLoading = false)
      .finally(() => this.isFacilityLoading = false)
  }
  getList(query: ListQuery) {
    const personType = this.filterPage.activeIndex === 0 ? "case" : this.filterPage.activeIndex === 1 ? "inmate" : "staff"
    const payload: CaseListRequest = {
      PPagenumber: query.page,
      PPagelimit: query.size,
      ...this.filterPage.caseFilterFormData,
      PType: personType,
      PsortField: query.sortField,
      PsortOrder: query.sortOrder
    }
    return this.caseListService.getCaseList(payload).then((res) => {
      this.filterPage.searching = false;
      this.filterPage.showSearchResults = true;
      return res;
    })
      .catch(() => this.filterPage.searching = false)
  }

  get caseList(): CaseListResponse[] {
    return this.pages[this.listQuery.page];
  }
  deleteCase(caseId: string) {
    this.filterPage.isDeleting = true;
    return this.caseListService.deleteCase(caseId).then(() => {
      this.$toast.add({
        severity: "success",
        summary: "Deleted",
        detail: "Case Deleted Successfully",
        life: 8000
      });
      this.refreshList();
      this.activityRefreshList();
    }
    ).finally(() => { this.filterPage.isDeleting = false })
  }

  created() {
    this.getFacilityList();
    this.activityRefreshList();
  }

  beforeCreate() {
    bindCaseListService(this.container);
    bindFacilityListService(this.container);
    this.facilityListService = this.container.get<IFacilityListService>(IoCTypes.FacilityListService);
    this.caseListService = this.container.get<ICaseListService>(IoCTypes.CaseListService);
  }
  getUserList(query: string, searchType: string) {
    return this.InmateApi.getInmateDetails(query, searchType).then((res) => {
      return res;
    })
  }

  getStaffList(query: string, searchType: string) {
    return this.StaffApi.getStaffDetails(query, searchType).then((res) => {
      return res;
    })
  }
}
