import { withNotificationModuleWrapper } from "@/handlers/notifications/NotificationModuleWrapper";
import { defineAsyncComponent } from "vue";
import { RouteRecordRaw } from "vue-router";

const routes: Array<RouteRecordRaw> = [{
  path: "notifications",
  component: withNotificationModuleWrapper(defineAsyncComponent(() => import("@/views/notifications/NotificationPage.vue"))), 
  
}];

export default routes;
