import { convertDate } from "@/components/filters/ConvertDate";
import { BaseContainerProps } from "@/components/form-builder/containers/base/BaseContainerProps";
import { authorizePermission } from "@/lib/AuthUtil";
import {
    formatMultidDate,
    formattedDate,
    formattedDateTime,
    formattedDateTimeReports,
    formattedTime,
    Helpers,
    increaseDate
} from "@/lib/Util";
import { execution } from "@/models/FormBuilderModel";
import {
    ActionSource,
    EventTypes
} from "@/shared/FormBuilderShared";
import {
    computed,
    ComputedRef,
    inject
} from "vue";
import {
    Options,
    Vue
} from "vue-class-component";

type TProvider = {
    handleActionClick: (event: EventTypes, type: ActionSource, executions?: execution[], onEvent?: execution[]) => unknown,
    containerState: any,
    IsReadOnly: ComputedRef<boolean>
}

@Options({
    template: `<slot v-if="!IsHide"></slot>`,
    provide(): TProvider {
        return {
            handleActionClick: this.handleActionClick,
            containerState: this.state,
            IsReadOnly: computed(() => this.IsReadOnly),
        }
    }
})
export default class FormHandler extends Vue.with(BaseContainerProps) {
    handleSubmit: (executions?: execution[], onEvent?: execution[]) => void = inject("handleSubmit") as (executions?: execution[], onEvent?: execution[]) => void;
    handleSave: (executions?: execution[], onEvent?: execution[]) => void = inject("handleSave") as (executions?: execution[], onEvent?: execution[]) => void;
    handleCancel: (executions?: execution[]) => void = inject("handleCancel") as (executions?: execution[]) => void;
    executeFunction: (executions: execution[], currentObject?: any) => any = inject("executeFunction") as (executions: execution[], currentObject?: any) => any;
    state: any = {
        title: this.title,
        actions: this.actions && [...this.actions]
    };
    func = {
        getFormatMultidDate:formatMultidDate,
        getFormattedDate: formattedDate,
        getFormattedTime: formattedTime,
        getFormattedDateTime: formattedDateTime,
        getConvertedDate: convertDate,
        getUTCNowDate: Helpers.getUTCNow,
        increaseDateByNumber: increaseDate,
        getDateDifferenceInYears: Helpers.getDateDifferenceInYears,
        getFormattedDateTimeReports: formattedDateTimeReports
    };
    isParentReadOnly: boolean = inject("IsReadOnly") as boolean;

    created() {
        /// [@Praveen - May 30,2022] -  DO NOT REMOVE setTimeout
        setTimeout(() => {
            if (this.onCreate) this.executeFunction(this.onCreate, this)
        }, 0);
    }

    handleActionClick(event: EventTypes, type: ActionSource, executions?: execution[], onEvent?: execution[]) {
        if (event === EventTypes.SUBMIT && type === ActionSource.SAVE_BUTTON) this.handleSave(executions, onEvent);
        else if (event === EventTypes.SUBMIT && type === ActionSource.SIMPLE_BUTTON) this.handleSubmit(executions, onEvent);
        else if (event === EventTypes.SUBMIT && type === ActionSource.CANCEL_BUTTON) this.handleCancel(executions);
        else if (executions) this.executeFunction(executions, this);
    }

    get IsHide() {
        const visiblePermission = this.properties?.visiblePermission;
        if (visiblePermission) return !authorizePermission(visiblePermission)
        const isHide = this.properties?.isHide;
        if (isHide === true) return true;
        else if (isHide === undefined || isHide === false) return false;
        return this.executeFunction(isHide, this);
    }

    get IsReadOnly() {
        if (this.isParentReadOnly) return true;
        const isReadonly = this.properties?.isReadonly;
        const editablePermission = this.properties?.editablePermission;
        if (editablePermission && !authorizePermission(editablePermission)) return true
        if (isReadonly === true) return true;
        else if (isReadonly === undefined || isReadonly === false) return false;
        return this.executeFunction(isReadonly, this);
    }
}
