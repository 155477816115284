import { bindInmateListService } from "@/IoC/InmateIoC";
import { IoCTypes } from "@/IoC/IoCTypes";
import { inmateQuestionnaireResponse } from "@/models/InmateModel";
import { IInmateListService } from "@/services/inmate/IInmateListService";
import { Options } from "vue-class-component";
import BaseHandler from "../BaseHandler";

@Options({ template: `<slot></slot>` })

export default class InmateQuestionnaireListHandler extends BaseHandler {
    inmateListService!: IInmateListService;
    questionnaireList: any = [];
    isLoading = true;
    isQuestionnaireListData = false;
    beforeCreate() {
        bindInmateListService(this.IoC);
        this.inmateListService = this.IoC.get<IInmateListService>(IoCTypes.InmateListServices);
    }
    getQuestionnaireList(id: string) {
        this.isLoading = true
        this.inmateListService.getInmateQuestionnaire(id).then((res: inmateQuestionnaireResponse[]) => {
            this.isLoading = false
            this.isQuestionnaireListData = res.length === 0 ? true : false
            this.questionnaireList = res.sort((a, b) => new Date(b.modifiedDate).getTime() - new Date(a.modifiedDate).getTime())
            return res
        })
    }
}