import { TemplateApi } from "@/api/TemplateApi";
import { IoCTypes } from "@/IoC/IoCTypes";
import { DynamicApi } from "@/models/FormBuilderModel";
import {
    inject,
    injectable
} from "inversify";
import { IFormBuilderStoreService } from "../IFormBuilderStoreService";
import { IFormExecutionService } from "./IFormExecutionService";

@injectable()
export class FormExecutionService implements IFormExecutionService {
    templateApi: TemplateApi = new TemplateApi();
    storeService!: IFormBuilderStoreService;

    constructor(
        @inject(IoCTypes.FormBuilderStoreService) storeService: IFormBuilderStoreService,
    ) {
        this.storeService = storeService;
    }
    downloadFile(attachmentId: string): Promise<any> {
        return this.templateApi.downloadAttachment(attachmentId)
    }

    getDynamicApiResponse(api: DynamicApi) {
        if (this.storeService.ApiResponses[api.path] && !api.blockStore) return Promise.resolve(this.storeService.ApiResponses[api.path]);
        return this.templateApi.getDynamicApi(api).then((res: any) => {
            if (!api.blockStore) this.storeService.saveApiResponse(api.path, res);
            return res;
        });
    }
}