import {
  createRouter, createWebHistory, RouteRecordRaw
} from "vue-router"
import HomePage from "../views/HomePage.vue"
import CaseRoutes from "../router/CaseRoute"
import { withLoginModuleWrapper } from "@/handlers/login/LoginWrapper"
import { withForgotModuleWrapper } from "@/handlers/login/ForgotWrapper"
import { withResetModuleWrapper } from "@/handlers/login/ResetWrapper"
import { defineAsyncComponent } from "vue"
import {
  authorizePermission, getRefreshToken, getToken
} from "@/lib/AuthUtil"
import DasboardRoutes from "../router/DashboardRoute"
import AllegationRoutes from "../router/AllegationRoute";
import ReportRoutes from "../router/ReportRoute";
import { withFormExecutionWrapper } from "@/handlers/form-builder/FormExecutionWrapper"
import NotificationRoutes from "./NotificationRoute";
import { withAllegationManageWrapper } from "@/handlers/allegation/manage/AllegationManageWrapper"
import WelcomeRoute from "../router/WelcomeRoute";
import UserRoute from "../router/UserRoute";
import RoleRoute from "../router/RoleRoute";
import GroupRoute from "../router/GroupRoute";
import InmateRoute from "./InmateRoute";
import { Permissions } from "@/shared/ClaimsShared"

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    name: "Home",
    component: HomePage,
    redirect: "/cases",
    children: [
      ...CaseRoutes,
      ...DasboardRoutes,
      ...AllegationRoutes,
      ...ReportRoutes,
      ...NotificationRoutes,
      ...WelcomeRoute,
      ...UserRoute,
      ...RoleRoute,
      ...GroupRoute,
      ...InmateRoute
    ]
  },
  {
    path: "/allegation/add",
    name: "Anonymous-Allegation",
    component: withAllegationManageWrapper(defineAsyncComponent(() => import("@/views/allegation/AllegationCreateForm.vue"))),
    meta: { isPublic: true },
  },
  {
    path: "/login",
    name: "Login",
    component: withLoginModuleWrapper(
      defineAsyncComponent(
        () => import("../views/login/Login.vue")
      )
    ),
    meta: { isPublic: true },
  },
  {
    path: "/forgot-password",
    name: "Forgot Password",
    component: withForgotModuleWrapper(
      defineAsyncComponent(
        () => import("../views/login/ForgotPasswordPage.vue")
      )
    ),
    meta: { isPublic: true },
  },
  {
    path: "/reset",
    name: "Reset",
    component: withResetModuleWrapper(
      defineAsyncComponent(
        () => import("../views/login/ResetPasswordPage.vue")
      )
    ),
    meta: { isPublic: true },
  },
  {
    path: "/execution-editor",
    component: withFormExecutionWrapper(defineAsyncComponent(
      () => import("../views/form-exeution/FormExecutionPage.vue")
    )),
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});


router.beforeEach((to, _from, next) => {
  const {
    isPublic,
    permission
  } = to.meta;
  const isLoggedIn = getToken() || getRefreshToken();
  if (!isLoggedIn && !isPublic) {
    return next({
      path: "/login",
      query: { returnUrl: to.path }
    });
  }
  else if (isLoggedIn && isPublic) {
    return next({ path: "/" });
  }
  else if (permission && !authorizePermission(String(permission))) {
    if (to.path === "/cases/search") {
      if (authorizePermission(Permissions.workspaceOnly)) return next({ path: "/workspace" });
      return next({ path: "/welcome" });
    }
    return next({ path: "/unauthorize" });
  }
  next();
});



export default router;
