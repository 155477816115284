import { IoCTypes } from "@/IoC/IoCTypes";
import {
  mixins, Options, Vue
} from "vue-class-component";
import { FieldOption } from "@/models/ListModel";
import { ILookpupStoreService } from "@/services/lookup/ILookupStoreService";
import BaseHandler from "../BaseHandler";
import { ILookupService } from "@/services/lookup/ILookpupService";
import { LookupHandlerProps } from "../Lookup/LookupHandlerProps";
import { bindLookupService } from "@/IoC/LookupIoC";
import { RemoteOptions } from "@/models/FormBuilderModel";
@Options({ template: `<slot :lookupList="lookupList" :othersSelected="othersSelected" :otherMultiSelected="otherMultiSelected"></slot>` })

export default class LookupHandler extends mixins(BaseHandler, Vue.with(LookupHandlerProps)) {
  lookupStoreService!: ILookpupStoreService;
  lookupService!: ILookupService;
  remoteOptions: RemoteOptions = {
    url: String("lookup/list/") + this.lookupName,
    textAttr: "displayValue",
    valAttr: "id",
    hiddenValAttr: "value"
  }
  beforeCreate() {
    bindLookupService(this.IoC)
    this.lookupService = this.IoC.get<ILookupService>(IoCTypes.LookupService);
    this.lookupStoreService = this.IoC.get<ILookpupStoreService>(IoCTypes.LookupStoreService);
  }

  created() {
    this.lookupService.getLookupDetails(this.remoteOptions);
  }

  get lookupList(): FieldOption[] {
    return this.lookupStoreService.lookupDetails[this.remoteOptions.url]
  }
  get otherMultiSelected() {
    if (this.multiselectValue && this.multiselectValue.length > 0 && this.lookupList) {
      if (this.multiselectValue.find((x) => x === this.lookupList.find((value) => value.hiddenValue === "Others")?.value)) {
        return true
      }
    } return false
  }


  get othersSelected() {
    if (this.selectedValue && this.lookupList && this.selectedValue === this.lookupList.find((value) => value.hiddenValue === "Others")?.value) {
      return true
    } return false
  }
}