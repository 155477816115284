import { withInmateModule } from "@/handlers/inmate/InmateModuleWrapper";
import { withInmateList } from "@/handlers/inmate/InmateListWrapper"
import { defineAsyncComponent } from "vue";
import { RouteRecordRaw } from "vue-router";
import { FormBuilder } from "@/components/GlobalComponents";
import { withManageInmate } from "@/handlers/inmate/ManageInmateWrapper";

const routes: Array<RouteRecordRaw> = [{
  path: "inmate",
  component: withInmateModule(defineAsyncComponent(() => import("@/views/Inmate/InmateModulePage.vue"))),
  children: [
    {
      path: "",
      component: withInmateList(defineAsyncComponent(() => import("@/views/Inmate/Inmate-list/InmateListPage.vue"))),
    },
    {
      path: "add",
      component: withManageInmate(defineAsyncComponent(() => import("../views/Inmate/ManageInmate/ManageInmatePage.vue"))),
    },
    {
      path: "edit/:id",
      component: withManageInmate(defineAsyncComponent(() => import("../views/Inmate/ManageInmate/ManageInmatePage.vue"))),
    },
    {
      path: "questionnaire/:inmateId",
      component: FormBuilder,
      strict: true,
      sensitive: true
    },
    {
      path: "questionnaire/edit/:id",
      component: FormBuilder,
      strict: true,
      sensitive: true
    }
  ]
}];

export default routes;
