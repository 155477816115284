
import { IRoleListService } from "@/services/role/IRoleListService";
import { Container } from "inversify";
import { IoCTypes } from "@/IoC/IoCTypes";
import {
    Options, Vue
} from "vue-class-component";
import { bindRoleListService } from "@/IoC/RoleIoC";
import {
    computed, ComputedRef
} from "vue";
import {
    PermissionListResponse,
    RoleNames
} from "@/models/RoleModal";
// import { RoleListResponse } from "@/models/RoleModal";

type TProvider = {
    roleList: ComputedRef<any>,
    fetchingRoles: ComputedRef<boolean>,
    roles: ComputedRef<RoleNames>,
    getRoles: () => void,
    deleteRole: () => void,
    deletingRole: ComputedRef<boolean>,
    permissionList: ComputedRef<PermissionListResponse[]>,
    getPermissions: (index: any) => void
}
@Options({
    template: `<slot></slot>`,
    provide(): TProvider {
        return {
            roleList: computed(() => this.roleList),
            fetchingRoles: computed(() => this.fetchingRoles),
            roles: computed(() => this.roles),
            getRoles: this.getRoles,
            deleteRole: this.deleteRole,
            deletingRole: computed(() => this.deletingRole),
            permissionList: computed(() => this.permissionList),
            getPermissions: this.getPermissions()
        }
    }
})
export default class RoleListHandler extends Vue {
    container: Container = new Container();
    roleListService!: IRoleListService;

    roleList: any = {
        roleName: "",
        description: ""
    };
    roles: RoleNames = {
        name: "",
        id: "",
    }
    permissionList: PermissionListResponse[] = []
    fetchingRoles = false;
    deletingRole = false;
    fetchingPermissions = false;

    beforeCreate() {
        bindRoleListService(this.container);
        this.roleListService = this.container.get<IRoleListService>(IoCTypes.RoleListService);
    }

    created() {
        this.getRoles();
        this.getPermissions(0);
    }
    getRoles() {
        this.fetchingRoles = true;
        this.roleList = [];
        this.roleList = this.roleListService.getRoles()
            .then((res: any) => {

                if (res && res.length > 0) {
                    this.roleList = res;
                    //this.roleList = res.filter((x:any) => x.isActive === true);
                    this.roles = res.filter((y: any) => y.isPredefinedRole === true).map((x: any) => {
                        return {
                            text: x.name,
                            value: x.id,
                        }
                    })
                    return res
                }
            })
            .catch(() => this.fetchingRoles = false)
            .finally(() => this.fetchingRoles = false)

    }
    getPermissions(index: number) {
        this.fetchingPermissions = true;
        this.roleListService.getPermissions(index).then((res: PermissionListResponse[]) => {
            this.permissionList = res;
        }).finally(() => this.fetchingPermissions = false)
    }

    deleteRole(roleId: string) {
        this.deletingRole = true;
        this.fetchingRoles = true;

        const id = { id: roleId }
        return this.roleListService.deleteRole(id)
            .then((res: any) => {
                this.deletingRole = false
                if (res) {
                    this.$toast.add({
                        severity: "success",
                        summary: "Success",
                        detail: "Deleted Successfully",
                        life: 3000
                    });
                    this.fetchingRoles = false;

                    // this.roleList = this.roleList.length > 0 && this.roleList.filter((x: any) => x.id !== roleId)
                    this.getRoles();
                }
            })
            .catch(() => this.getRoles())
            .finally(() => {
                this.deletingRole = false;
                this.fetchingRoles = false;
            })
    }
}
