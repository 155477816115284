import {
    actions,
    ComputedOptions,
    container,
    execution,
    field,
    properties,
    style,
    validators
} from "@/models/FormBuilderModel";
import { FieldOption } from "@/models/ListModel";
import { FieldTypes } from "@/shared/FormBuilderShared";
import { prop } from "vue-class-component";

export class BaseFieldProps implements field {
    fieldId: string = prop({ required: true });
    fieldName?: string | undefined = prop({ required: false });
    fieldType: FieldTypes = prop({ required: true });
    fieldLabel?: string | undefined = prop({ required: false });
    fieldOptions?: FieldOption[] | undefined
        = prop({ required: false });
    remoteOptions?: { url: string; textAttr: string; valAttr: string; authParams?: object | undefined;
         textReplaceAttr?: { featureName: string; configName: string; } | undefined; storeAlterName?: string | undefined; } | undefined
        = prop({ required: false });
    defaultValue?: any = prop({ required: false });
    computedOptions?: ComputedOptions = prop({ required: false });
    fileExtensions?: string[] | undefined = prop({ required: false });
    tooltip?: string | undefined = prop({ required: false });
    hintText?: string | undefined = prop({ required: false });
    placeholderText?: string | undefined = prop({ required: false });
    dateRange?: string | undefined = prop({ required: false });
    maximumDate?: Date | undefined = prop({ required: false });
    minimumDate?: Date | undefined = prop({ required: false });
    calenderMode?: string | undefined = prop({ required: false })
    subTemplates?: container[] | undefined = prop({ required: false });
    style?: style | undefined = prop({ required: false });
    validations?: validators[] | undefined = prop({ required: false });
    properties?: properties | undefined = prop({ required: false });
    actions?: actions[] | undefined = prop({ required: false });
    modelValue: any = prop({ required: true });
    isVisible?: boolean = prop({ required: false });
    onCreate?: execution[] = prop({ required: false });
    onCreateComponent?: execution[] = prop({ required: false });
    onCreateSubTemplates?: execution[] = prop({ required: false });
    beforeCreateSubTemplates?: execution[] = prop({ required: false });
    onCreateComponentHandler?: execution[] = prop({ required: false });
    onDestroySubTemplates?: execution[] = prop({ required: false });
}
