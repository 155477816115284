import BaseField from "@/components/form-builder/fields/base/BaseField";
import {
    actions,
    container,
    execution,
    properties
} from "@/models/FormBuilderModel";
import { FieldOption } from "@/models/ListModel";
import { EventTypes } from "@/shared/FormBuilderShared";
import {
    computed,
    ComputedRef,
    inject
} from "vue";
import {
    mixins,
    Options
} from "vue-class-component";

type TProvider = {
    showForm: ComputedRef<boolean>;
    setShowForm: (show: boolean) => void,
    selectedIndex: ComputedRef<number>;
    formDataList: ComputedRef<any[]>;
    formDataViewList: ComputedRef<any[]>;
    containers: container[];
    actions: actions[];
    fieldOptions: FieldOption,
    handleActionClick: () => unknown;
    handleCancel: () => void;
    handleItemAdd: () => void;
    handleItemEdit: (index: number) => void;
    handleItemDel: (index: number) => void;
    fieldLabel: string;
    subTemplates: container[];
    properties: properties
};

@Options({
    template: `<slot></slot>`,
    provide(): TProvider {
        return {
            showForm: computed(() => this.showForm),
            setShowForm: this.setShowForm,
            selectedIndex: computed(() => this.selectedIndex),
            formDataList: computed(() => this.formDataList),
            formDataViewList: computed(() => this.formDataViewList),
            containers: this.subTemplates,
            actions: this.actions,
            fieldOptions: this.fieldOptions,
            handleActionClick: this.handleActionClick,
            handleCancel: this.handleCancel,
            handleItemAdd: this.handleItemAdd,
            handleItemEdit: this.handleItemEdit,
            handleItemDel: this.handleItemDel,
            fieldLabel: this.fieldLabel,
            subTemplates: this.subTemplates,
            properties: this.properties
        };
    },
})
export default class ChildFormListHandler extends mixins(BaseField) {
    showForm = false;
    selectedIndex = -1;
    executeFunction: (executions: execution[], currentObject: any) => any = inject("executeFunction") as (executions: execution[], currentObject: any) => any;
    isNavValueDirty: () => boolean = inject("isNavValueDirty") as () => boolean;

    created() {
        if (this.onCreateComponentHandler) this.executeFunction(this.onCreateComponentHandler, this)
    }

    get formDataViewList() {
        if(this.properties?.format) return this.executeFunction(this.properties.format,this);
        const list = [...this.formDataList];
        if (this.properties?.sortField) {
            return list.sort((a: any, b: any) => {
                return this.properties && this.properties?.sortField ?
                    b[this.properties?.sortField].localeCompare(a[this.properties?.sortField]) : 0;
            })
        }
        return list;
    }

    get formDataList(): any[] {
        
        return this.fieldValue;
    }

    setShowForm(show: boolean) {
        this.showForm = show;
    }

    handleActionClick(event: EventTypes) {
        switch (event) {
            case EventTypes.SUBMIT:
                this.handleItemAdd();
                break;
            default:
                this.setShowForm(false);
        }
    }

    handleItemAdd() {
        this.selectedIndex = -1;
        this.setShowForm(true);
    }

    handleItemEdit(index: number) {
        
        this.selectedIndex = index;
        this.setShowForm(true);
    }

    handleItemDel(index: number, executions?: execution[]) {
        this.$emit("update:modelValue", this.formDataList.filter((_x, i) => index !== i));
        if (executions) this.executeFunction(executions, this)
        this.handleCancel();
    }

    handleCancel() {
        if (this.properties?.dirtyChecker && this.isNavValueDirty()) {
            this.$confirm.require({
                message: JSON.stringify({ text: "Are you sure you want to leave this page? Changes that you made may not be saved" }),
                header: "Leave Page?",
                icon: "pi pi-exclamation-triangle",
                acceptClass: "p-button p-button-danger",
                rejectClass: "p-button p-button-secondary",
                accept: () => {
                    this.cancelForm();
                }
            });
        }
        else this.cancelForm();
    }

    cancelForm() {
        this.selectedIndex = -1;
        this.setShowForm(false);
    }
}