import { CaseApi } from "@/api/CaseApi";
import { PaginateList } from "@/models/ListModel";
import { injectable } from "inversify";
import { ICaseListService } from "./ICaseListService";
import {
  CaseListResponse, CaseListRequest, CaseTypesResponse, CaseActivityListRequest, CaseActivityListResponse,
  CaseStatisticsListRequest, CaseStatisticsListResponse,
} from "@/models/CaseModal";

@injectable()
export class CaseListService implements ICaseListService {
  caseApi: CaseApi = new CaseApi();
  getCaseList(query: CaseListRequest): Promise<PaginateList<CaseListResponse>> {
    return this.caseApi.getCaseList(query);
  }
  getCaseTypes(query: string | null): Promise<PaginateList<CaseTypesResponse>> {
    return this.caseApi.getCaseTypes(query);
  }
  getCaseActivity(payload: CaseActivityListRequest): Promise<PaginateList<CaseActivityListResponse>> {
    return this.caseApi.getActivityList(payload);
  }

  getCaseStatisticsList(payload: CaseStatisticsListRequest): Promise<PaginateList<CaseStatisticsListResponse>> {
    return this.caseApi.getCaseStatisticsList(payload);
  }

  deleteCase(caseId: string) {
    return this.caseApi.deleteCase(caseId);
  }


}