import AppHandler from "./AppHandler";

export const withAppHandler = (WrappedComponent: any) => {
  return {
    props: WrappedComponent.props,
    template: `<AppHandler v-bind="$props"><WrappedComponent></WrappedComponent></AppHandler>`,
    components: {
      WrappedComponent,
      AppHandler
    }
  }
}