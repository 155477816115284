import { HttpUtil } from "@/lib/HttpUtil";
import { FileMetadata } from "@/models/FilesModel";

export class FilesApi {
  httpUtil: HttpUtil = new HttpUtil();

  getAttachments(attachmentIds: string[]): Promise<any> {
    return this.httpUtil.post<any>("Attachments/list", attachmentIds);
  }

  saveAttachmentData(entity: string, payLoad: FileMetadata[], templateDataId: string): Promise<boolean> {
    return this.httpUtil.post<boolean>(entity.concat("/", templateDataId, "/attachments"), payLoad);
  }

  getTemplateDataAttachments(entity: string, category: string, templateDataId: string): Promise<any> {
    return this.httpUtil.get<any>(entity.concat("/", templateDataId, "/attachments/", category));
  }

  deleteTemplateAttachments(attachmentIds: string[]): Promise<boolean> {
    return this.httpUtil.delete("Attachments", attachmentIds);
  }

  downloadAttachment(id: string): Promise<any> {
    return this.httpUtil.postAndGetBlob("attachments/download/".concat(id), {})
  }
}