import { 
    ForgotRequest,
    ForgotResponse
    } from "@/models/Forgotpassword";
    import { injectable } from "inversify";
    import { IForgotService } from "./IForgotService";
    import { Forgotapi }from "../../api/ForgotApi";
    
    @injectable()
    export class ForgotService implements IForgotService {
        forgotApi: Forgotapi = new Forgotapi();
        validateForgot(data:ForgotRequest):Promise<ForgotResponse> {
            return this.forgotApi.validateForgot(data).then((res:ForgotResponse) => {
            return res
                      });
        }
    }