import { IGroupListService } from "@/services/groups/IGroupListService";
import { Container } from "inversify";
import { IoCTypes } from "@/IoC/IoCTypes";
import {
    Options, Vue
} from "vue-class-component";
import { bindGroupListService } from "@/IoC/GroupIoc"
import {
    computed, ComputedRef
} from "vue";
import { GroupListResponse } from "@/models/GroupModel";

type TProvider = {
    getGroups:()=>void,
    deleteGroup:()=>void,
    fetchingGroups: ComputedRef<boolean>,
	groupList: ComputedRef<any>,
    deletingGroup:ComputedRef<boolean>,
    groupdetailspage:ComputedRef<boolean>
}

@Options({
    template: `<slot></slot>`,
    provide(): TProvider {
        return{
            groupList:computed(() => this.groupList),
            getGroups:this.getGroups,
            deleteGroup:this.deleteGroup,
            fetchingGroups: computed(() => this.fetchingGroups),
            deletingGroup: computed(() => this.fetchingGroups),
            groupdetailspage:computed(() => this.groupdetailspage)
        }  
    }
})
export default class GroupListHandler extends Vue{
    container: Container = new Container();
    GroupListService!:IGroupListService;
    groupdetailspage=false;
    groupList:any={ };
    
    beforeCreate() {
        bindGroupListService(this.container);
        this.GroupListService = this.container.get<IGroupListService>(IoCTypes.GroupListService);
    }

    created() {
        this.getGroups();
    }
    getGroups(){
        this.fetchingGroups=true;
        this.groupList = [];
        this.GroupListService.getGroupList().then((res:GroupListResponse[])=>{
            this.groupList=res;
        })
        .catch(() => this.fetchingGroups = false)
        .finally(() => this.fetchingGroups = false);
    }
    
    deleteGroup(groupid:any){
        const id = { id: groupid }
        this.fetchingGroups=true;
        this.deletingGroup=true;
        return this.GroupListService.deleteGroup(id)
            .then((res: any) => {
                this.deletingGroup = false
                if (res) {
                    this.$toast.add({
                        severity: "success",
                        summary: "Success",
                        detail: "Group Deleted Successfully",
                        life: 3000
                    });
                    this.fetchingGroups = false;
                    this.getGroups();
                }
            })
            .catch(() => this.getGroups())
            .finally(() => {
                this.deletingGroup = false;
                this.fetchingGroups = false;
            })
    }

    fetchingGroups = false;
    deletingGroup = false;

}