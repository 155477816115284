import { Container } from "inversify";
import { IoCTypes } from "./IoCTypes";
import { IAllegationModuleService } from "@/services/allegation/module/IAllegationModuleService";
import { AllegationModuleService } from "@/services/allegation/module/AllegationModuleService";
import { AllegationSearchListService } from "@/services/allegation/list/AllegationSearchListService";
import { IAllegationSearchListService } from "@/services/allegation/list/IAllegationSearchListService";
import { IAllegationManageService } from "@/services/allegation/manage/IAllegationManageService";
import AllegationManageService from "@/services/allegation/manage/AllegationManageService";

export function bindAllegationModuleService(container: Container) {
  container.bind<IAllegationModuleService>(IoCTypes.AllegationModuleService).to(AllegationModuleService);
}

export function bindAllegationSearchListService(container: Container) {
  container.bind<IAllegationSearchListService>(IoCTypes.AllegationSearchListService).to(AllegationSearchListService);
}

export function bindAllegationManageService(container: Container) {
  container.bind<IAllegationManageService>(IoCTypes.AllegationManageService).to(AllegationManageService)
}