import {
    prop,
    WithDefault
} from "vue-class-component";

export class ChildFormHandlerProps {
    addOnly?: WithDefault<boolean> = prop({
        required: false,
        default: false
    })
}