import { HttpUtil } from "@/lib/HttpUtil";

export class CommonApi {
    httpUtil: HttpUtil = new HttpUtil();

    getFeatureCurrentNumber(featureName: string): Promise<string> {
        return this.httpUtil.get<string>("common/CurrentNumber", { featureName })
    }

    async getAppConfig(featureName: string, configName: string): Promise<any> {
        const resp = await this.httpUtil.get(`/common/getAppConfig/${featureName}/${configName}`);
        return resp;
    }
}