import {
    ClaimResponse,
    ClaimRoles
} from "@/models/Claims";
import {
    Module,
    Mutation,
    VuexModule
} from "vuex-module-decorators";
import { ClaimState } from "./ClaimStoreTypes";

@Module({
    namespaced: true,
    name: "claim"
})
export default class ClaimStore extends VuexModule implements ClaimState {
    permissions!: string[];
    roles: ClaimRoles[] = [];
    get Permissions(): string[] { return this.permissions; }
    get Roles(): ClaimRoles[] { return this.roles; }

    @Mutation
    SAVE_CLAIMS(claims: ClaimResponse): void {
        this.permissions = claims.permissions;
        this.roles = claims.roles;
    }
}