export enum UsersCreateResponse {
    ACCOUNT_CREATED = "ACCOUNT_CREATED",
    USER_CREATED = "USER_CREATED"
}

export enum UsersUpdateResponse {
    ACCOUNT_UPDATED = "ACCOUNT_UPDATED",
    USER_UPDATED = "USER_UPDATED"
}
export enum UserdeleteResponse {
    ACCOUNT_DELETED = "ACCOUNT_DELETED",
    USER_DELETED = "USER_DELETED"
}