import { execution } from "@/models/FormBuilderModel";
import { ICustomFunction } from "../ICustomFunction";

export class SetExecutionVariable {
    private exec: execution;
    private func: ICustomFunction;

    constructor(exec: execution, func: ICustomFunction) {
        this.exec = exec;
        this.func = func;
    }

    execute() {
        if (this.exec.executions && this.exec.executions.length > 0) {
            this.func.setValue(this.exec.field, this.func.run(this.exec.executions), this.exec.source)
        }
    }
}