import { bindFacilityListService } from "@/IoC/FacilityIoC";
import { IoCTypes } from "@/IoC/IoCTypes";
import { IFacilityListService } from "@/services/facility/facility-list/IFacilityListService";
import { Options } from "vue-class-component";
import {
  computed, ComputedRef
} from "vue";
import { FieldOption } from "@/models/ListModel";
import { ILookpupStoreService } from "@/services/lookup/ILookupStoreService";
import BaseHandler from "../BaseHandler";

type TProvider = {
  facilityList: ComputedRef<FieldOption[]>
  facilityListLoading: ComputedRef<boolean>
}

@Options({
  template: `<slot :facilityList="facilityList" :facilityListLoading="facilityListLoading"></slot>`,
  provide(): TProvider {
    return {
      facilityList: computed(() => this.facilityList),
      facilityListLoading: computed(() => this.facilityListLoading)
    }
  }
})

export default class FacilityLookupHandler extends BaseHandler {
  facilityListService!: IFacilityListService;
  lookupStoreService!: ILookpupStoreService;

  beforeCreate() {
    bindFacilityListService(this.IoC);
    this.facilityListService = this.IoC.get<IFacilityListService>(IoCTypes.FacilityListService);
    this.lookupStoreService = this.IoC.get<ILookpupStoreService>(IoCTypes.LookupStoreService);
  }

  created() {
    this.facilityListService.getFacilityList();
  }

  get facilityList(): FieldOption[] {
    return this.lookupStoreService.lookupDetails.facilityList || []
  }

  get facilityListLoading(): boolean {
    return this.lookupStoreService.lookupDetailsLoading.facilityList || false;
  }
}