import { UserApi } from "@/api/UserApi";
import {
    UserRequest,
    UserResponse
} from "@/models/UserModel";
import {
    UsersCreateResponse,
    UsersUpdateResponse,
    UserdeleteResponse
} from "@/shared/UserShared";
import { injectable } from "inversify";
import { IManageUserService } from "./IManageUserService";

@injectable()
export class ManageUserService implements IManageUserService {
    api = new UserApi();

    addUser(request: UserRequest): Promise<UsersCreateResponse> {
        return this.api.addUser(request)
    }

    updateUser(request: UserRequest): Promise<UsersUpdateResponse> {
        return this.api.updateUser(request)
    }
    deleteUser(userId: string):Promise<UserdeleteResponse>{
        return this.api.deleteUser(userId)
    }
    getUser(userId: string): Promise<UserRequest> {
        return this.api.getUser(userId).then((res: UserResponse) => {
            const result: UserRequest = {
                id: res.id,
                firstName: res.firstName,
                middleName: res.middleName,
                lastName: res.lastName,
                email: res.email,
                password: "",
                confirmPassword: "",
                roles: res.roles && res.roles.length > 0 ? res.roles[0].id : "",
                facilities: res.facilities && res.facilities.map(x => x.id),
                status: res.status
            }
            return result;
        })
    }
}