import { AsyncDashboardModuleHandler } from "@/components/AsyncComponents";
import FacilityLookupHandler from "../facility/FacilityLookupHandler";

export const withDashboardModuleWrapper = (WrappedComponent: any) => {
    return {
        template: `<Handler><FacilityHandler><WrappedComponent></WrappedComponent></FacilityHandler></Handler>`,
        components: {
            WrappedComponent,
            Handler: AsyncDashboardModuleHandler,
            FacilityHandler: FacilityLookupHandler
        }
    }
}