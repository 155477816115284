import { FieldOption } from "@/models/ListModel";
import AppStore from "@/store";
import { LookupStore } from "@/store/lookup/LookupStore";
import { injectable } from "inversify";
import { getModule } from "vuex-module-decorators";
import { ILookpupStoreService } from "./ILookupStoreService";

@injectable()
export class LookupStoreService implements ILookpupStoreService {
    lookupStore: LookupStore;

    constructor() {
        this.lookupStore = getModule(LookupStore, AppStore);
    }

    get lookupDetailsLoading() { return this.lookupStore.lookupDetailsLoading }

    get lookupDetails() { return this.lookupStore.LookupDetails }

    saveLookupDetails(field: string, details: FieldOption[]): void {
        this.lookupStore.SAVE_LOOKUP_DETAILS({
            field,
            details
        });
    }

    setLookupDetailsLoading(field: string, isLoading: boolean): void {
        this.lookupStore.SET_LOOKUP_DETAILS_LOADING({
            field,
            isLoading
        });
    }

}