import { AsyncAllegationListHandler } from "@/components/AsyncComponents";

export const withAllegationListWrapper = (WrappedComponent: any) => {
  return {
    template: `<AllegationListHandler><WrappedComponent></WrappedComponent></AllegationListHandler>`,
    components: {
      WrappedComponent,
      AllegationListHandler: AsyncAllegationListHandler
    }
  }
}