import { AsyncNotificationModuleHandler } from "@/components/AsyncComponents";

export const withNotificationModuleWrapper = (WrappedComponent: any) => {
    return {
        template: `<Handler><WrappedComponent></WrappedComponent></Handler>`,
        components: {
            WrappedComponent,
            Handler: AsyncNotificationModuleHandler,
        }
    }
}