import { HttpUtil } from "@/lib/HttpUtil";
import { PaginateList } from "@/models/ListModel";
import {
    CaseActivityListRequest,
    CaseListRequest, CaseListResponse, CaseTypesResponse, CaseActivityListResponse, CaseStatisticsListRequest, CaseStatisticsListResponse
} from "@/models/CaseModal";

export class CaseApi {
    httpUtil: HttpUtil = new HttpUtil();
    getCaseList(payload: CaseListRequest): Promise<PaginateList<CaseListResponse>> {
        return this.httpUtil.post<PaginateList<CaseListResponse>>("Case/List", payload)
    }
    getCaseTypes(orgId: string | null): Promise<any> {
        return this.httpUtil.get<PaginateList<CaseTypesResponse>>("Case/casetypes", { headersArg: orgId })
    }
    getActivityList(payload: CaseActivityListRequest): Promise<PaginateList<CaseActivityListResponse>> {
        return this.httpUtil.post<PaginateList<CaseActivityListResponse>>("Case/ActivityList", payload)
    }
    getCaseStatisticsList(payload: CaseStatisticsListRequest): Promise<PaginateList<CaseStatisticsListResponse>> {
        return this.httpUtil.post<PaginateList<CaseStatisticsListRequest>>("Dashboard/statslist", payload)
    }
    deleteCase(caseId: string): Promise<boolean> {
        return this.httpUtil.delete<boolean>("template/".concat(caseId), {})
    }
}