import { HttpUtil } from "@/lib/HttpUtil";
import {
    IdName, PaginateList
} from "@/models/ListModel";
import {
    PermissionListResponse,
    RoleRequest, RoleUpdateRequest,
    PermissionDictionary
} from "@/models/RoleModal";
// import { RoleListResponse } from "@/models/RoleModal";

export class RoleApi {
    httpUtil = new HttpUtil("IDP");
    getRoleList(): Promise<IdName<string>[]> {
        return this.httpUtil.get<IdName<string>[]>("api/v1/role/list");
    }
    getRoles(): Promise<PaginateList<any>> {
        return this.httpUtil.get<PaginateList<any>>("api/v1/role/manage/list")
    }
    postNewRole(role: RoleRequest): Promise<any> {
        return this.httpUtil.postForm<RoleRequest>("api/v1/role/", role);
    }
    deleteRole(roleId: any): Promise<any> {
        return this.httpUtil.deleteForm<any>("api/v1/role/", roleId);
    }
    updateRole(role: RoleUpdateRequest): Promise<any> {
        return this.httpUtil.putForm<RoleUpdateRequest>("api/v1/role/", role)
    }
    getPermissionList(index: number): Promise<PermissionListResponse[]> {
        return this.httpUtil.get<PermissionListResponse[]>("/api/v1/permission".concat("?pgSize=100&&pgIndex=", String(index)));
    }
    getPermissionByRoles(roles: string[]): Promise<PermissionDictionary[]> {
        return this.httpUtil.post<PermissionDictionary[]>("/api/v1/permission?pgSize=100&&pgIndex=0", roles);
    }
}