import { GroupApi } from "@/api/GroupApi";
import { GroupListResponse } from "@/models/GroupModel";
import { injectable } from "inversify";
import { IGroupListService } from "./IGroupListService";
import { GroupActionResponse } from "@/shared/GroupShared";
@injectable()
export class GroupListService implements IGroupListService {
    GroupApi = new GroupApi();
    getGroupList():Promise<GroupListResponse[]>{
        return this.GroupApi.getGroupList()
    }
    deleteGroup(id: any): Promise<GroupActionResponse> {
        return this.GroupApi.deleteGroup(id)
    }
}