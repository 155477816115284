import { TemplateResponse } from "@/models/TemplateModel";
import {
    Module,
    Mutation,
    VuexModule
} from "vuex-module-decorators";
import { FormBuilderState } from "./FormBuilderStoreTypes";

@Module({
    namespaced: true,
    name: "formBuilder"
})
export default class FormBuilderStore extends VuexModule implements FormBuilderState {
    latestTemplates: { [field: string]: TemplateResponse } = {};
    apiResponses: { [field: string]: any } = {};

    get LatestTemplates(): { [field: string]: TemplateResponse } { return this.latestTemplates; }
    get ApiResponses(): { [field: string]: any } { return this.apiResponses; }

    @Mutation
    SAVE_LATEST_TEMPLATE({
        template,
        name
    }: { template: TemplateResponse, name: string }): void {
        this.latestTemplates[name] = template;
    }

    @Mutation
    SAVE_API_RESPONSE({
        field,
        apiResponse
    }: { field: string, apiResponse: any }): void {
        this.apiResponses[field] = apiResponse;
    }
}