import { execution } from "@/models/FormBuilderModel";
import {
    ValueSource,
    ValueTypes
} from "@/shared/FormBuilderShared";
import { ICustomFunction } from "../ICustomFunction";

export class Sort {
    private exec: execution;
    private func: ICustomFunction;

    constructor(exec: execution, func: ICustomFunction) {
        this.exec = exec;
        this.func = func;
    }

    execute() {
        const sortField: string = this.func.getValue({
            type: ValueTypes.FIELD,
            value: "sortField",
            isFixed: true,
            source: ValueSource.LOCAL_VARIABLE
        })
        const sortType: string = this.func.getValue({
            type: ValueTypes.FIELD,
            value: "sortType",
            isFixed: true,
            source: ValueSource.LOCAL_VARIABLE
        })
        const arr: any[] = this.func.getValue({
            type: ValueTypes.FIELD,
            value: this.exec.field,
            isFixed: true,
            source: this.exec.source
        })
        return arr.sort((a: any, b: any) => {
            return sortType === "DSC" ? b[sortField].localeCompare(a[sortField]) : a[sortField].localeCompare(b[sortField])
        })
    }
}