import { bindFileViewService } from "@/IoC/FilesIoC";
import { IoCTypes } from "@/IoC/IoCTypes";
import { FileMetadata } from "@/models/FilesModel";
import { IFileViewService } from "@/services/common/file/IFileViewService";
import { Container } from "inversify";
import { inject } from "vue";
import {
  Options,
  Vue
} from "vue-class-component";
import { FileViewHandlerProps } from "./FileViewHandlerProps";

@Options({ template: `<slot :handleDownload="handleDownload" :handleDelete="handleDelete"></slot>` })

export default class FileViewHandler extends Vue.with(FileViewHandlerProps) {
  container: Container = new Container();
  fileViewService!: IFileViewService;
  uploadedFiles: FileMetadata[] = []
  deleteFiles: string[] = []
  updatedFiles: FileMetadata[] = []
  attachmentData: any = inject("attachmentData");

  handleUploadedDelete(item: FileMetadata) {
    this.deleteFiles.push(item.id || "");
    this.uploadedFiles.splice(this.uploadedFiles.findIndex(x => x.id === item.id), 1);
    this.fileViewService.deleteTemplateAttachments(this.deleteFiles);
  }

  handleDelete(item: FileMetadata) {
    this.attachmentData.attachmentsMetadata.find((x: FileMetadata) => x === item).isActive = false;
  }

  handleUploadedEdit(item: FileMetadata) {
    if (this.updatedFiles.find(x => x.id === item.id) === undefined) this.updatedFiles.push(item)
    else this.updatedFiles[this.updatedFiles.findIndex(x => (x.fileName === item.fileName && x.id === item.id))] = item;
    this.uploadedFiles[this.uploadedFiles.findIndex(x => (x.fileName === item.fileName && x.id === item.id))] = item;
  }

  async handleDownload(id: string) {
    const fileResponse = await this.fileViewService.downloadFile(id);
    const file: File = new File([fileResponse.blob], String(fileResponse.resp.headers.get("x-filename")), { type: fileResponse.resp.headers.get("content-type") });
    const link = URL.createObjectURL(file);
    const a = document.createElement("a");
    a.href = link;
    a.download = String(fileResponse.resp.headers.get("x-filename"));
    document.body.appendChild(a);
    a.click();
  }

  beforeCreate() {
    bindFileViewService(this.container);
    this.fileViewService = this.container.get<IFileViewService>(IoCTypes.FileViewService)
  }
}