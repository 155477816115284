import { BaseActionProps } from "@/components/form-builder/actions/base/BaseActionProps";
import { execution } from "@/models/FormBuilderModel";
import { authorizePermission } from "@/lib/AuthUtil";
import {
    ActionSource,
    EventTypes
} from "@/shared/FormBuilderShared";
import { inject } from "vue";
import {
    Options,
    Vue
} from "vue-class-component";

type TProivder = {
    state: any;
    handleClick: () => void;
}

@Options({
    template: `<slot v-if="!IsHide"></slot>`,
    provide(): TProivder {
        return {
            state: this.state,
            handleClick: this.handleClick
        }
    }
})
export default class FormActionHandler extends Vue.with(BaseActionProps) {
    executeFunction: (executions: execution[], currentObject?: any) => any = inject("executeFunction") as (executions: execution[], currentObject?: any) => any;
    handleActionClick: (event: EventTypes, type: ActionSource, executions?: execution[], onEvent?: execution[]) => unknown =
        inject("handleActionClick") as (event: EventTypes, type: ActionSource, executions?: execution[], onEvent?: execution[]) => unknown;
    state: any = { isLoading: false };

    created() {
        if (this.onCreate) this.executeFunction(this.onCreate, this)
    }

    handleClick() {
        this.handleActionClick(this.event, this.type, this.executions, this.onEvent)
    }
    get IsHide() {
        const visiblePermission = this.properties?.visiblePermission;
        if (visiblePermission && !authorizePermission(visiblePermission)) return true;
        const isHide = this.properties?.isHide;
        if (isHide === true) return true;
        else if (isHide === undefined || isHide === false) return false;
        return this.executeFunction(isHide, this);
    }
}