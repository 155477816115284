import { HttpUtil } from "@/lib/HttpUtil";
import { ClaimResponse } from "@/models/Claims";
import { OrgPreferencesResponse } from "@/models/OrgPreferencesResponse";

export class Claimapi {
  httpUtil: HttpUtil = new HttpUtil("IDP");
  getClaim(): Promise<ClaimResponse> {
    return this.httpUtil.get<ClaimResponse>("api/v1/Profile/Claims");
  }
  getOrgPref():Promise<OrgPreferencesResponse>{
    return this.httpUtil.get<OrgPreferencesResponse>("api/v1/Profile/OrgPreferences");
  }
}