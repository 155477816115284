import { Options } from "vue-class-component";
import {
  computed,
  ComputedRef
} from "vue";
import { IoCTypes } from "@/IoC/IoCTypes";
import { Permissions } from "@/shared/ClaimsShared";
import { bindAppService } from "@/IoC/AppIoc";
import BaseHandler from "./BaseHandler";
import { IAppService } from "@/services/app/IAppService";
import {
  authorizePermission,
  logout
} from "@/lib/AuthUtil";

type TProvider = {
  authValidated: ComputedRef<boolean>;
  permissions: Permissions;
  hidedialog: typeof Function;
  showChangePassword: ComputedRef<boolean>;
  openDialog: typeof Function;
}
@Options({
  template: `<slot></slot>`,
  provide(): TProvider {
    return {
      authValidated: computed(() => this.authValidated),
      permissions: this.permissions,
      hidedialog: this.hidedialog,
      showChangePassword: computed(() => this.showChangePassword),
      openDialog: this.openDialog,
    };
  },
})

export default class AppHandler extends BaseHandler {
  permissions = Permissions;
  authValidated = false;
  appService!: IAppService;
  showChangePassword = false;
  beforeCreate() {
    bindAppService(this.IoC);
    this.appService = this.IoC.get<IAppService>(IoCTypes.AppService);
  }

  created() {
    this.loadAuth();
  }
  hidedialog() {
    this.showChangePassword = false
  }
  openDialog() {
    this.showChangePassword = true
  }
  async loadAuth() {
    try {
      const isAuthorized: boolean = await this.appService.loadAuthorization(this);
      if (!isAuthorized) {
        if (this.$route.path === "/cases/search") {
          if (authorizePermission(this.permissions.workspaceOnly)) await this.$router.push("/workspace");
          else await this.$router.push("/welcome");
        }
        else await this.$router.push("/unauthorize");
      }
      this.authValidated = true;
    }
    catch (err: any) {
      console.error(err);
      logout();
    }
  }

}