import { execution } from "@/models/FormBuilderModel";
import { ICustomFunction } from "../ICustomFunction";

export class JsonParse {
    private exec: execution;
    private func: ICustomFunction;

    constructor(exec: execution, func: ICustomFunction) {
        this.exec = exec;
        this.func = func;
    }

    execute() {
        const val = this.func.getValue(this.exec.value)
        if (val) return JSON.parse(val);
    }
}