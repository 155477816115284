import {
  AllegationSearchQuery,
  AllegationSearchResponse,
  AllegationsResponse,
  AllegationStatusCount
} from "@/models/AllegationsModel";
import { PaginateList } from "@/models/ListModel";
import { IAllegationSearchListService } from "./IAllegationSearchListService";
import { AllegationsApi } from "@/api/AllegationsApi";
import { injectable } from "inversify";

@injectable()
export class AllegationSearchListService implements IAllegationSearchListService {
  allegationApi: AllegationsApi = new AllegationsApi();
  getFilteredAllegation(query: AllegationSearchQuery): Promise<PaginateList<AllegationSearchResponse>> {
    return this.allegationApi.getSearchAllegation(query);
  }

  getAllAllegations(query: AllegationSearchQuery): Promise<PaginateList<AllegationSearchResponse>> {
    return this.allegationApi.getSearchAllegation(query);
  }

  updateAllegation(allegation: AllegationsResponse): Promise<boolean> {
    return this.allegationApi.updateAllegation(allegation)
  }

  getAllegationStatusCount(): Promise<AllegationStatusCount> {
    return this.allegationApi.getAllegationStatusCount();
  }
}