// eslint-disable @typescript-eslint/explicit-module-boundary-types
import { defineAsyncComponent } from "vue";

export const AsyncFormBuilderHandler = defineAsyncComponent(() => import("../handlers/form-builder/FormHandler"));
export const AsyncCaseListHandler = defineAsyncComponent(() => import("../handlers/case/case-list/CaseListHandler"));
export const AsyncCaseModuleHandler = defineAsyncComponent(() => import("../handlers/case/CaseModuleHandler"));
export const AsyncListHandler = defineAsyncComponent(() => import("../handlers/common/list/ListHandler"));
export const AsyncManageLoginHandler = defineAsyncComponent(() => import("../handlers/login/LoginHandler"));
export const AsyncManageForgotHandler = defineAsyncComponent(() => import("../handlers/login/ForgotHandler"));
export const AsyncManageResetHandler = defineAsyncComponent(() => import("../handlers/login/ResetHandler"));
export const AsyncCaseActivityHandler = defineAsyncComponent(() => import("../handlers/case/CaseActivityHandler"))
export const AsyncDashboardCaseStatisticsListHandler = defineAsyncComponent(() => import("../handlers/dashboard/dashboard-case-statistics-list/DashboardCaseStatisticsListHandler"));
export const AsyncDashboardModuleHandler = defineAsyncComponent(() => import("../handlers/dashboard/DashboardModuleHandler"))
export const AsyncAllegationModuleHandler = defineAsyncComponent(() => import("../handlers/allegation/module/AllegationModuleHandler"));
export const AsyncAllegationListHandler = defineAsyncComponent(() => import("../handlers/allegation/list/AllegationListHandler"));
export const AsyncFormExecutionHandler = defineAsyncComponent(() => import("../handlers/form-builder/FormExecutionHandler"));
export const AsyncAllegationManageHandler = defineAsyncComponent(() => import("../handlers/allegation/manage/AllegationManageHandler"));
export const AsyncNotificationModuleHandler = defineAsyncComponent(() => import("../handlers/notifications/NotificationModuleHandler"))
export const AsyncManageUserHandler = defineAsyncComponent(() => import("../handlers/user/ManageUserHandler"))
export const AsyncUserListHandler = defineAsyncComponent(() => import("../handlers/user/UserListHandler"))
export const AsyncUserModuleHandler = defineAsyncComponent(() => import("../handlers/user/UserModuleHandler"))
export const AsyncRoleListHandler = defineAsyncComponent(() => import("../handlers/role/RoleListHandler"));
export const AsyncGroupListHandler = defineAsyncComponent(() => import("../handlers/group/GroupListHandler"));
export const AsyncInmateModuleHandler = defineAsyncComponent(() => import("../handlers/inmate/InmateModuleHandler"));
export const AsyncInmateQuestionnaireListHandler = defineAsyncComponent(() => import("../handlers/inmate/InmateQuestionnaireListHandler"))
export const AsyncManageInmateHandler = defineAsyncComponent(() => import("../handlers/inmate/ManageInmateHandler"));
export const AsyncInmateListHandler = defineAsyncComponent(() => import("../handlers/inmate/InmateListHandler"))
