import { FacilityApi } from "@/api/FacilityApi";
import {
  inject,
  injectable
} from "inversify";
import { IFacilityListService } from "./IFacilityListService";
import { FacilityListResponse } from "@/models/FacilityModal";
import {
  FieldOption,
  PaginateList
} from "@/models/ListModel";
import { ILookpupStoreService } from "@/services/lookup/ILookupStoreService";
import { IoCTypes } from "@/IoC/IoCTypes";
import { ConvertIdNameToFieldOption } from "@/lib/Util";

@injectable()
export class FacilityListService implements IFacilityListService {
  facilityApi = new FacilityApi();
  lookupStoreService: ILookpupStoreService;
  listKey = "facilityList"

  constructor(@inject(IoCTypes.LookupStoreService) lookupStoreService: ILookpupStoreService) {
    this.lookupStoreService = lookupStoreService;
  }

  getFacilityList(): Promise<FieldOption[]> {
    if (this.lookupStoreService.lookupDetails[this.listKey]) {
      return Promise.resolve(this.lookupStoreService.lookupDetails[this.listKey])
    }
    this.lookupStoreService.setLookupDetailsLoading(this.listKey, true);
    return this.facilityApi.getFacilityList().then((res: PaginateList<FacilityListResponse>) => {
      const options = ConvertIdNameToFieldOption(res.items.map(x => ({
        id: x.id,
        name: x.name
      })));
      this.lookupStoreService.saveLookupDetails(this.listKey, options);
      this.lookupStoreService.setLookupDetailsLoading(this.listKey, false)
      return options;
    });
  }
}