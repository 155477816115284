import {
  DynamicApi,
  execution
} from "@/models/FormBuilderModel";
import { ICustomFunction } from "../ICustomFunction";
import {
  ValueTypes,
  ValueSource
} from "@/shared/FormBuilderShared";
import { TemplateApi } from "@/api/TemplateApi";

export class ApiCall {
  private exec: execution;
  private func: ICustomFunction;
  templateApi = new TemplateApi();

  constructor(exec: execution, func: ICustomFunction) {
    this.exec = exec;
    this.func = func;
  }

  execute() {
    const method: string = this.func.getValue({
      type: ValueTypes.FIELD,
      value: "method",
      isFixed: true,
      source: ValueSource.LOCAL_VARIABLE
    });
    const path: string = this.func.getValue({
      type: ValueTypes.FIELD,
      value: "endPoint",
      isFixed: true,
      source: ValueSource.LOCAL_VARIABLE
    })
    const header: any = this.func.getValue({
      type: ValueTypes.FIELD,
      value: "header",
      isFixed: true,
      source: ValueSource.LOCAL_VARIABLE
    })
    const origin: any = this.func.getValue({
      type: ValueTypes.FIELD,
      value: "origin",
      isFixed: true,
      source: ValueSource.LOCAL_VARIABLE
    });
    const payload: any = this.func.getValue({
      type: ValueTypes.FIELD,
      value: "payload",
      isFixed: true,
      source: ValueSource.LOCAL_VARIABLE
    });
    const blockStore: any = this.func.getValue({
      type: ValueTypes.FIELD,
      value: "blockStore",
      isFixed: true,
      source: ValueSource.LOCAL_VARIABLE
    })
    const api: DynamicApi = {
      method: method || "GET",
      path,
      header,
      origin,
      payload,
      blockStore
    }
    if (method === "POST_AND_GET_BLOB") {
      this.templateApi.postAndGetBlob(api).then(this.handleResponse).catch(this.handleError).finally(this.handleFinally);
    }
    else if (method === "GET_BLOB") {
      this.templateApi.getBlob(api).then(this.handleResponse).catch(this.handleError).finally(this.handleFinally);
    }
    else if (method === "POST") {
      this.templateApi.postDynamicApi(api).then(this.handleResponse).catch(this.handleError).finally(this.handleFinally);
    }
    else if (method === "PUT") {
      this.templateApi.putDynamicApi(api).then(this.handleResponse).catch(this.handleError).finally(this.handleFinally);
    }
    else {
      this.func.getDynamicApiResponse(api).then(this.handleResponse).catch(this.handleError).finally(this.handleFinally);
    }
  }

  handleResponse = (res: any) => {
    this.func.setValue("apiResponse", res, ValueSource.LOCAL_VARIABLE);
    if (this.exec.executions && this.exec.executions.length > 0) {
      this.func.run(this.exec.executions);
    }
  }

  handleError = (error: any) => {
    this.func.setValue("error", error, ValueSource.LOCAL_VARIABLE);
    if (this.exec.errorExecutions && this.exec.errorExecutions.length > 0) {
      this.func.run(this.exec.errorExecutions);
    }
  }

  handleFinally = () => {
    if (this.exec.finallyExecutions && this.exec.finallyExecutions.length > 0) {
      this.func.run(this.exec.finallyExecutions);
    }
  }

}