import { execution } from "@/models/FormBuilderModel";
import {
    ValueSource,
    ValueTypes
} from "@/shared/FormBuilderShared";
import { ICustomFunction } from "../ICustomFunction";

export class CallFunction {
    private exec: execution;
    private func: ICustomFunction;

    constructor(exec: execution, func: ICustomFunction) {
        this.exec = exec;
        this.func = func;
    }

    execute() {
        const isAsyncFunction: boolean = this.func.getValue({
            type: ValueTypes.FIELD,
            value: "isAsync",
            isFixed: true,
            source: ValueSource.LOCAL_VARIABLE
        });
        const obj = this.func.getValue({
            type: ValueTypes.FIELD,
            value: this.exec.field,
            isFixed: true,
            source: this.exec.source
        })
        if (!this.exec.parameters) this.exec.parameters = []
        if (obj && this.exec.func) {
            if (isAsyncFunction) {
                this.func.setValue("isAsync", false, ValueSource.LOCAL_VARIABLE)
                obj[this.exec.func](...this.func.getParameters(this.exec.parameters)).then((res: any) => {
                    this.func.setValue("asyncResponse", res, ValueSource.LOCAL_VARIABLE)
                    if (this.exec.executions && this.exec.executions.length > 0) {
                        this.func.run(this.exec.executions);
                    }
                })
            }
            else return obj[this.exec.func](...this.func.getParameters(this.exec.parameters))
        }
    }
}