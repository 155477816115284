import { execution } from "@/models/FormBuilderModel";
import { ValueTypes } from "@/shared/FormBuilderShared";
import { ICustomFunction } from "../ICustomFunction";

export class ObjectCreation {
    private exec: execution;
    private func: ICustomFunction;

    constructor(exec: execution, func: ICustomFunction) {
        this.exec = exec;
        this.func = func;
    }

    execute() {
        const obj = this.func.getValue({
            type: ValueTypes.FIELD,
            value: this.exec.field,
            isFixed: true,
            source: this.exec.source
        })
        if (!this.exec.parameters) this.exec.parameters = []
        if (obj && this.exec.func) return new obj[this.exec.func](...this.func.getParameters(this.exec.parameters));
    }
}