import { TemplateApi } from "@/api/TemplateApi";
import {
    Template,
    TemplateDataRequest,
    TemplateDataResponse,
    TemplateResponse
} from "@/models/TemplateModel";
import {
    inject,
    injectable
} from "inversify";
import { IFormBuilderService } from "./IFormBuilderService"
import { FileMetadata } from "@/models/FilesModel";
import { IoCTypes } from "@/IoC/IoCTypes";
import { IFormBuilderStoreService } from "./IFormBuilderStoreService";
import { ILookupService } from "../lookup/ILookpupService";

@injectable()
export class FormBuilderService implements IFormBuilderService {
    templateApi: TemplateApi = new TemplateApi();
    storeService!: IFormBuilderStoreService;
    lookupService!: ILookupService;
    constructor(
        @inject(IoCTypes.FormBuilderStoreService) storeService: IFormBuilderStoreService,
        @inject(IoCTypes.LookupService) lookupService: ILookupService
    ) {
        this.storeService = storeService;
        this.lookupService = lookupService;
    }
    saveTemplateData(payload: TemplateDataRequest, files: File[]) {
        if (payload.id) return this.templateApi.updateTemplateData(payload, files)
        return this.templateApi.createTemplateData(payload, files)
    }

    getTemplateData(id: string): Promise<TemplateDataRequest> {
        return this.templateApi.getTemplateData(id).then((res: TemplateDataResponse) => this.ConvertTemplateDataResponseRequest(res));
    }

    getTemplate(id: string, name: string, version = 0): Promise<Template> {
        if (id) {
            return this.templateApi.getTemplate(id).then((res: TemplateResponse) => this.ConvertTemplateResponseToTemplate(res));
        }
        return this.getLatestTemplate(name, version).then((res: TemplateResponse) => this.ConvertTemplateResponseToTemplate(res))
    }

    saveAttachmentData(payLoad: FileMetadata[], caseId: string): Promise<boolean> {
        return this.templateApi.saveAttachmentData(payLoad, caseId);
    }
    getCaseAttachments(caseId: string): Promise<any> {
        return this.templateApi.getCaseAttachments(caseId);
    }

    private getLatestTemplate(name: string, version = 0): Promise<TemplateResponse> {
        const storeKey = name + String("_") + version;
        if (this.storeService.LatestTemplates[storeKey]) return new Promise((res) => res(this.storeService.LatestTemplates[storeKey]));
        return this.templateApi.getLatestTemplate(name, version).then((res: TemplateResponse) => {
            this.storeService.saveLatestTemplate(res, storeKey);
            return res;
        });
    }

    private ConvertTemplateResponseToTemplate(res: TemplateResponse): Template {
        return {
            id: res.id,
            name: res.name,
            incType: res.incType,
            incSubType: res.incSubType,
            version: res.version,
            jsonTemplate: JSON.parse(res.jsonTemplate),
        }
    }

    private ConvertTemplateDataResponseRequest(res: TemplateDataResponse): TemplateDataRequest {
        return {
            id: res.id,
            templateId: res.templateId,
            jsonData: JSON.parse(res.jsonData),
            metadata: res.metadata,
            createdBy: res.createdBy && res.createdBy.name,
            createdDate: res.createdDate,
            modifiedBy: res.modifiedBy && res.modifiedBy.name,
            modifiedDate: res.modifiedDate
        }
    }
}