import { bindAllegationManageService } from "@/IoC/AllegationsIoC";
import { bindFacilityListService } from "@/IoC/FacilityIoC";
import { IoCTypes } from "@/IoC/IoCTypes";
import { AllegationsResponse } from "@/models/AllegationsModel";
import { FacilityListResponse } from "@/models/FacilityModal";
import { IAllegationManageService } from "@/services/allegation/manage/IAllegationManageService";
import { IFacilityListService } from "@/services/facility/facility-list/IFacilityListService";
import { Container } from "inversify";
import {
  computed,
  ComputedRef
} from "vue";
import {
  Options,
  Vue
} from "vue-class-component";

type TProvider = {
  postAllegation: () => void,
  getAllegationById: (id: string) => Promise<AllegationsResponse>,
  details: ComputedRef<AllegationsResponse>,
  isSavingAllegation: ComputedRef<boolean>,
  facilityList: ComputedRef<FacilityListResponse>
  isFacilityLoading: ComputedRef<boolean>
}

@Options({
  template: `<slot :details="details"></slot>`,
  provide(): TProvider {
    return {
      postAllegation: this.postAllegation,
      getAllegationById: this.getAllegationById,
      details: this.details,
      isSavingAllegation: computed(() => this.isSavingAllegation),
      facilityList: computed(() => this.facilityList),
      isFacilityLoading: computed(() => this.isFacilityLoading)
    }
  }
})
export default class AllegationManageHandler extends Vue {
  container: Container = new Container();
  allegationManageService!: IAllegationManageService;
  details: AllegationsResponse = {
    allegationEventDescription: "",
    allegationEventLocation: "",
    allegationStatus: "",
    comments: "",
    victimContactAddress: "",
    victimContactEmail: "",
    victimContactPhone: "",
    victimName: "",
    victimInmateId: ""
  };
  isSavingAllegation = false;
  facilityListService!: IFacilityListService;
  facilityList: FacilityListResponse[] = [];
  isFacilityLoading = true;
  postAllegation(allegation: AllegationsResponse) {
    this.isSavingAllegation = true;
    return this.allegationManageService.postNewAllegation(allegation).then(() => {
      if (this.$route.path.includes("new-allegation")) this.$router.back();
      this.$toast.add({
        severity: "success",
        summary: "Success",
        detail: "Allegation saved successfully",
        life: 3000
      });
    }).catch((err: PromiseRejectedResult) => {
      console.error("err.reason", err)
      this.$toast.add({
        severity: "error",
        summary: "Failed",
        detail: "Something went wrong",
        life: 3000
      });
    }).finally(() => {
      this.isSavingAllegation = false;
    })
  }

  getAllegationById(id: string): Promise<AllegationsResponse> {
    return this.allegationManageService.getAllegationById(id);
  }

  getFacilityList() {
    this.facilityListService.getFacilityList().then((res: any) => {
      if (res && res.length > 0) {
        this.facilityList = res.map((x: any) => {
          return {
            value: x.value,
            text: x.text
          }
        })
      }
      this.isFacilityLoading = false;
    }).catch(() => this.isFacilityLoading = false)
      .finally(() => this.isFacilityLoading = false)
  }

  beforeCreate() {
    bindAllegationManageService(this.container);
    bindFacilityListService(this.container);
    this.allegationManageService = this.container.get<IAllegationManageService>(IoCTypes.AllegationManageService);
    this.facilityListService = this.container.get<IFacilityListService>(IoCTypes.FacilityListService);
  }

  created() {
    this.getFacilityList();
  }
}