import { ProfileResponse } from "@/models/Profile";
import { injectable } from "inversify";
import { IProfileService } from "./IProfileService";
import { ProfileApi } from "../../api/ProfileApi";
import { Claimapi } from "../../api/ClaimApi";
import { ClaimResponse } from "@/models/Claims";
import AppStore from "@/store";
import ProfileStore from "@/store/profile/ProfileStore";
import ClaimStore from "@/store/claim/ClaimStore";
import { getModule } from "vuex-module-decorators";
import { StoreUtil } from "@/lib/StoreUtil";
import { decryptedText } from "@/lib/Util";


@injectable()
export class ProfileService implements IProfileService {
  profileApi: ProfileApi = new ProfileApi();
  claimApi: Claimapi = new Claimapi();
  profileStore!: ProfileStore;
  claimStore!: ClaimStore;
  storeUtil: StoreUtil = new StoreUtil();
  constructor() {
    this.profileStore = getModule(ProfileStore, AppStore);
    this.claimStore = getModule(ClaimStore, AppStore);
  }

  getProfile(): Promise<ProfileResponse> {
    const res: ProfileResponse = JSON.parse(decryptedText(this.storeUtil.getItem("userProfile")));
    this.profileStore.SAVE_MY_PROFILE(res);
    if (res && res.id) this.storeUtil.setItem("userId", res.id)
    if (res && res.organisations && res.organisations.length > 0) this.storeUtil.setItem("orgId", res.organisations[0].id)
    return Promise.resolve(res);
  }

  getClaim(): Promise<ClaimResponse> {
    const claimres: ClaimResponse = JSON.parse(decryptedText(this.storeUtil.getItem("userClaims")));
    this.claimStore.SAVE_CLAIMS(claimres);
    return Promise.resolve(claimres);
  }

  profileDetails(): ProfileResponse {
    return this.profileStore.MyProfile;
  }
}