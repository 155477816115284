import { execution } from "@/models/FormBuilderModel";
import { ConditionTypes } from "@/shared/FormBuilderShared";
import { ICustomFunction } from "../ICustomFunction";

export class Condition {
    private exec: execution;
    private func: ICustomFunction;

    constructor(exec: execution, func: ICustomFunction) {
        this.exec = exec;
        this.func = func;
    }

    execute() {
        if (this.exec && this.exec.conditions) {
            for (const cond of this.exec.conditions) {
                if (
                    (cond.type === ConditionTypes.IF && this.func.compare(cond.comparisions))
                    || cond.type === ConditionTypes.ELSE
                ) {
                    if (cond.executions && cond.executions.length > 0) return this.func.run(cond.executions)
                    return this.func.getValue(cond.value);
                }
            }
        }
    }
}