import { IoCTypes } from "@/IoC/IoCTypes";
import { bindNotification } from "@/IoC/NotificationListIoc";
import { StoreUtil } from "@/lib/StoreUtil";
import { ListQuery } from "@/models/ListQuery";
import { NotificationResponse } from "@/models/NotificationModal";
import { INotificationListService } from "@/services/notifications/INotificationListService";
import ClaimStore from "@/store/claim/ClaimStore";
import { Container } from "inversify";
import {
    computed,
    ComputedRef
} from "vue";
import { Options } from "vue-class-component";
import ListHandler from "../common/list/ListHandler";

type TProvider = {
    getNotificationList: () => void,
    handleTabChange: () => void,
    isFetchingNotification: ComputedRef<boolean>
    handlePageChange: () => void;
    listQuery: ComputedRef<ListQuery>;
    listDetails: ComputedRef<any>,
    notificationListData: ComputedRef<any>,
    refreshList: () => void,
    notificationSearchForm: ComputedRef<any>,
    handleResetClick: () => void,
    handleDropDownChange: () => void,
}

@Options({

    template: `<slot></slot>`,
    provide(): TProvider {
        return {
            getNotificationList: this.getNotificationList,
            handleTabChange: this.handleTabChange,
            notificationListData: computed(() => this.notificationListData),
            listDetails: computed(() => this.listDetails),
            handlePageChange: this.handlePageChange,
            listQuery: computed(() => this.listQuery),
            refreshList: this.refreshList,
            notificationSearchForm: computed(() => this.notificationSearchForm),
            handleResetClick: this.handleResetClick,
            handleDropDownChange: this.handleDropDownChange,
            isFetchingNotification: computed(() => this.isFetchingNotification),
        }
    }
})
export default class NotificationModuleHandler extends ListHandler<any> {
    container: Container = new Container();
    notificationListService!: INotificationListService;
    isFetchingNotification = true;
    storeUtil: StoreUtil = new StoreUtil();
    claimStore!: ClaimStore;
    userRole: any = [];
    notificationSearchForm: any = {
        featureNo: "",
        status: "ALL",
        datefilter: "ALL",
        isActive: "ALL"
    }

    beforeCreate() {
        bindNotification(this.container);
        this.notificationListService = this.container.get<INotificationListService>(IoCTypes.NotificationListService);
    }
    created() {
        this.loadList()
    }

    getList(query: ListQuery) {
        const userId = this.storeUtil.getItem("userId");
        this.isFetchingNotification = true;
        const payload: any = {
            PgIndex: query.page,
            PgSize: query.size,
            userid: userId ? userId : "",
            featureType: this.notificationSearchForm.isActive !== "ALL" ? "TASK" : "ALL",
            notificationType: "ALL",
            ...this.notificationSearchForm
        }
        return this.notificationListService.getNotificationList(payload).then((res) => {
            this.isFetchingNotification = false;
            return res;
        })
            .catch(() => this.isFetchingNotification = false)
            .finally(() => this.isFetchingNotification = false)

    }
    get notificationListData(): NotificationResponse[] {
        return (this.pages[this.listQuery.page] && this.pages[this.listQuery.page]);
    }
    handleResetClick() {
        this.notificationSearchForm = {
            featureNo: "",
            status: "ALL",
            datefilter: "ALL",
            isActive: "ALL",
        };
        this.listDetails.count = 0;

        this.isFetchingNotification = true;
        this.refreshList();
    }
    handleDropDownChange() {
        this.isFetchingNotification = true;
        this.listDetails.count = 0;
        this.refreshList();
    }
}


