export enum Permissions {
    createUser = "manageuser:create",
    updateUser = "manageuser:update",
    readUser = "manageuser:read",
    deleteUser = "manageuser:delete",
    createGroup = "managegroup:create",
    updateGroup = "managegroup:update",
    readGroup = "managegroup:read",
    deleteGroup = "managegroup:delete",
    createGroupMember = "managegroupmember:create",
    deleteGroupMember = "managegroupmember:delete",
    readGroupMember = "managegroupmember:read",
    createRole = "managerole:create",
    updateRole = "managerole:update",
    deleteRole = "managerole:delete",
    readRole = "managerole:read",
    createAllegation = "allegation:create",
    updateAllegation = "allegation:update",
    convertAllegation = "allegation:convert",
    readAllegation = "allegation:read",
    createCase = "case:create",
    updateCase = "case:update",
    deleteCase = "case:delete",
    readCase = "case:read",
    caseSearch = "casesearch",
    readCaseSearch = "casesearch:read",
    exportCaseSearch = "casesearch:export",
    updateCaseDetail = "casedetail:update",
    readCaseDetail = "casedetail:read",
    printCaseDetail = "casedetail:print",
    createCaseNotes = "casenotes:create",
    updateCaseNotes = "casenotes:update",
    readCaseNotes = "casenotes:read",
    uploadCaseNotesAttachment = "casenotesattachment:upload",
    removeCaseNotesAttachment = "casenotesattachment:remove",
    downloadCaseNotesAttachment = "casenotesattachment:download",
    createCasePerson = "caseperson:create",
    updateCasePerson = "caseperson:update",
    readCasePerson = "caseperson:read",
    deleteCasePerson = "caseperson:delete",
    readCasePersonList = "casepersonlist:read",
    createCaseEvidence = "caseevidence:create",
    updateCaseEvidence = "caseevidence:update",
    readCaseEvidence = "caseevidence:read",
    deleteCaseEvidence = "caseevidence:delete",
    readCaseEvidenceList = "caseevidencelist:read",
    createCaseInvestigationNotes = "caseinvestigationnotes:create",
    updateCaseInvestigationNotes = "caseinvestigationnotes:update",
    readCaseInvestigationNotes = "caseinvestigationnotes:read",
    deleteCaseInvestigationNotes = "caseinvestigationnotes:delete",
    uploadCaseInvestigationAttachment = "caseinvestigationattachment:upload",
    removeCaseInvestigationAttachment = "caseinvestigationattachment:remove",
    downloadCaseInvestigationAttachment = "caseinvestigationattachment:download",
    selectCaseInvestigationOutcome = "caseinvestigationoutcome:select",
    selectCaseOutcome = "caseoutcome:select",
    readCaseOutcome = "caseoutcome:read",
    assignCaseInvestigator = "caseinvestigator:assign",
    changeCaseInvestigator = "caseinvestigator:change",
    readCaseInvestigator = "caseinvestigator:read",
    selectCaseExtension = "caseextension:select",
    readCaseExtension = "caseextension:read",
    readDashboard = "dashboard:read",
    createReport = "reports:create",
    runReport = "reports:run",
    queryBuilderReport = "reports:queryBuilder",
    createFacility = "facility:create",
    updateFacility = "facility:update",
    deleteFacility = "facility:delete",
    readFacility = "facility:read",
    readNotification = "notification:read",
    caseNotesDelete = "casenotes:delete",
    workspaceOnly = "workspacewithoutsearch:read",
    createCaseTask = "casetasks:create",
    searchInmate = "inmate:search",
    createInmate = "inmate:create",
    updateInmate = "inmate:update",
    updateInmateQuestionnaire = "inmatequestionnaire:update",
    readInmateQuestionnaire = "inmatequestionnaire:read",
    readBJSReports = "bjsreports:read",
    exportBJSReports = "bjsreports:export",
    updateBJSReports = "bjsreports:update",
    runReportBJSReports = "bjsreports:runreport",
    readManagement = "management:read",
    readReports = "reports:read",
    otherReportsRead = "otherreports:read",
    createStaff = "staff:create",
    updateStaff = "staff:update",
    readSair = "sair:read",
    updateSair = "sair:update",
    readOutcomeMemo = "outcomeMemo:read",
    updateOutcomeMemo = "outcomeMemo:update",
    readAdministrativeReport = "administrativeReport:read",
    updateAdministrativeReport = "administrativeReport:update",
    readRetaliationReport = "retaliationReport:read",
    updateRetaliationReport = "retaliationReport:update",
    readSart = "sart:read",
    updateSart = "sart:update",
    readSexualVictimizationReport = "sexualVictimization:read",
    updateSexualVictimizationReport = "sexualVictimization:update",
    readDidTheVictim = "didthevictim:read",
    updateDidTheVictim = "didthevictim:update",
    readVictimMonitoring = "victimmonitoring:read",
    updateVictimMonitoring = "victimmonitoring:update",
    deleteVictimMonitoring = "victimmonitoring:delete",
    adultCaseMenu = "menu:adultcase",
    juvenileCaseMenu = "menu:juvenilecase"

}