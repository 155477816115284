import { HttpUtil } from "@/lib/HttpUtil";
import { PaginateList } from "@/models/ListModel";
import {
    GroupResponse,
    GroupListResponse,
    CreateGroupRequest,
    UpdateGroupRequest,
    UsersListResponse
} from "@/models/GroupModel";

import { GroupActionResponse } from "@/shared/GroupShared";
export class GroupApi {
    httpUtil = new HttpUtil("IDP");
    getGroupList(): Promise<GroupListResponse[]> {
        return this.httpUtil.get<GroupListResponse[]>("api/v1/Group/Manage/list")
    }
    getGroup(id: any): Promise<GroupResponse> {
        return this.httpUtil.get<GroupResponse>("api/v1/Group/Manage/Id/", id)
    }
    addGroup(request: CreateGroupRequest): Promise<GroupActionResponse> {
        return this.httpUtil.postForm<string>("api/v1/Group", request).then(() => {
            return GroupActionResponse.GROUP_CREATED
        });
    }
    updateGroup(request: UpdateGroupRequest): Promise<GroupActionResponse> {
        return this.httpUtil.putForm<string>("api/v1/Group", request).then(() => {
            return GroupActionResponse.GROUP_UPDATED
        });
    }
    deleteGroup(id: any): Promise<GroupActionResponse> {
        return this.httpUtil.deleteForm<any>("api/v1/Group/", id).then(() => {
            return GroupActionResponse.GROUP_UPDATED
        });
    }
    getGroupuserlist(): Promise<PaginateList<UsersListResponse>> {
        return this.httpUtil.get<PaginateList<UsersListResponse>>("/api/v1/User/ManageGroup/List")
    }
}
