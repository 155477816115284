import { CommonApi } from "@/api/CommonApi";
import { injectable } from "inversify";
import { ICommonService } from "./ICommonService";

@injectable()
export class CommonService implements ICommonService {
    commonApi: CommonApi = new CommonApi();
    getFeatureCurrentNumber(feaureName: string): Promise<string> {
        return this.commonApi.getFeatureCurrentNumber(feaureName)
    }
}