import {
    Module,
    Mutation,
    VuexModule
} from "vuex-module-decorators";
import { CommonState } from "./CommonStoreTypes";

@Module({
    namespaced: true,
    name: "common"
})
export class CommonStore extends VuexModule implements CommonState {
    needInmateListRefresh = true;

    get NeedInmateListRefresh() {
        return this.needInmateListRefresh;
    }

    @Mutation
    SET_NEED_INMATE_LIST_REFRESH(needRefresh: boolean): void {
        this.needInmateListRefresh = needRefresh
    }
}