import { ILookupService } from "@/services/lookup/ILookpupService";
import { ILookpupStoreService } from "@/services/lookup/ILookupStoreService";
import { LookupService } from "@/services/lookup/LookupService";
import { LookupStoreService } from "@/services/lookup/LookupStoreService";
import { Container } from "inversify";
import { bindAppConfigStoreService } from "./AppConfigIOC";
import { IoCTypes } from "./IoCTypes";

export function bindLookupStoreService(container: Container) {
    container.bind<ILookpupStoreService>(IoCTypes.LookupStoreService).to(LookupStoreService)
}

export function bindLookupService(container: Container) {
    container.bind<ILookupService>(IoCTypes.LookupService).to(LookupService);
    bindLookupStoreService(container);
    bindAppConfigStoreService(container);
}