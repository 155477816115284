import { bindCaseListService } from "@/IoC/CaseListIoC";
import { IoCTypes } from "@/IoC/IoCTypes";
import { StoreUtil } from "@/lib/StoreUtil";
import { CaseActivityListResponse } from "@/models/CaseModal";
import { ListQuery } from "@/models/ListQuery";
import { ICaseListService } from "@/services/case/case-list/ICaseListService";
import { Container } from "inversify";
import {
    computed,
    ComputedRef
} from "vue";
import { Options } from "vue-class-component";
import ListHandler from "../common/list/ListHandler";

type TProvider = {
    caseActivityHandlePageChange: () => void;
    caseActivityListQuery: ListQuery;
    caseActivityListDetails: ComputedRef<any>,
    caseActivityList: ComputedRef<CaseActivityListResponse[]>
    isFetchingCaseActivityList: ComputedRef<boolean>,
    refreshList: () => void,
    activityLoadList: () => void,
    sortBy:() => void
}

@Options({

    template: `<slot></slot>`,
    provide(): TProvider {
        return {
            caseActivityListDetails: computed(() => this.listDetails),
            caseActivityHandlePageChange: this.handlePageChange,
            caseActivityListQuery: this.listQuery,
            caseActivityList: computed(() => this.caseActivityList),
            isFetchingCaseActivityList: computed(() => this.isFetchingCaseActivityList),
            refreshList: this.refreshList,
            activityLoadList: this.loadList,
            sortBy: this.sortBy
        }
    }
})
export default class CaseActivityHandler extends ListHandler<CaseActivityListResponse> {
    container: Container = new Container();
    caseListService!: ICaseListService;
    isFetchingCaseActivityList = true;
    storeUtil: StoreUtil = new StoreUtil();

    beforeCreate() {
        bindCaseListService(this.container);
        this.caseListService = this.container.get<ICaseListService>(IoCTypes.CaseListService);
    }

    getList(query: ListQuery) {
        //const userId = sessionStorage.getItem("userId");
        const userId = this.storeUtil.getItem("userId");
        const payload = {
            pUserid: userId ? userId : "",
            ppagelimit: query.size ? query.size : 7,
            ppagenumber: query.page,
            psortField: query.sortField,
            psortOrder : query.sortOrder
        }        
        return this.caseListService.getCaseActivity(payload).then((res) => {
            this.isFetchingCaseActivityList = false
            return res;
        })
            .finally(() => this.isFetchingCaseActivityList = false)
    }

    get caseActivityList(): any {
        return this.pages[this.listQuery.page];
    }
}


