import {
 AsyncCaseModuleHandler, AsyncCaseActivityHandler 
} from "@/components/AsyncComponents";

export const withCaseModuleWrapper = (WrappedComponent: any) => {
    return {
        template: `<Handler><CaseActivityHandler><WrappedComponent></WrappedComponent></CaseActivityHandler></Handler>`,
        components: {
            WrappedComponent,
            Handler: AsyncCaseModuleHandler,
            CaseActivityHandler: AsyncCaseActivityHandler
        }
    }
}