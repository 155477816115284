import { injectable } from "inversify";
import { getModule } from "vuex-module-decorators";
import { ICaseStoreService } from "./ICaseStoreService";
import CaseStore from "@/store/case/CaseStore";
import AppStore from "@/store";
import { CaseTypesResponse } from "@/models/CaseModal";


@injectable()
export class CaseStoreService implements ICaseStoreService {
    caseStore!: CaseStore

    get LatestCaseTypes(): CaseTypesResponse {
        return this.caseStore.LatestCaseTypes;
    }
    constructor() {
        this.caseStore = getModule(CaseStore, AppStore);
    }

    saveLatestCaseTypes(caseTypes: CaseTypesResponse): void {
        this.caseStore.SAVE_LATEST_CASE_TYPES(caseTypes);

    }

}