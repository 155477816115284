import { AppConfig } from "@/models/AppConfig";
import { IAppConfigService } from "./IAppConfigService";
import { AppConfigApi } from "@/api/AppConfigApi";
import {
    inject,
    injectable
} from "inversify";
import { IAppConfigStoreService } from "./IAppConfigStoreService";
import { IoCTypes } from "@/IoC/IoCTypes";

@injectable()
export class AppConfigService implements IAppConfigService {
    appConfigStoreService!: IAppConfigStoreService

    constructor(@inject(IoCTypes.AppConfigStoreService) appConfigStoreService: IAppConfigStoreService) {
        this.appConfigStoreService = appConfigStoreService;
    }
    appConfigApi: AppConfigApi = new AppConfigApi()
    getAppConfigDetails(): Promise<AppConfig[]> {
        return this.appConfigApi.getAllAppConfig().then((res: AppConfig[]) => {
            this.appConfigStoreService.saveLatestAppConfigTypes(res);
            return res;
        })
    }

}