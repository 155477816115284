import {
    computed, ComputedRef
} from "vue";
import {
    Options, Vue
} from "vue-class-component";
import { ICaseListService } from "@/services/case/case-list/ICaseListService";
import { Container } from "inversify";
import { bindCaseListService } from "@/IoC/CaseListIoC";
import { IoCTypes } from "@/IoC/IoCTypes";
import { CaseStatisticsListRequest, } from "@/models/CaseModal";

type TProvider = {
    caseStatisticsFilterFormData: ComputedRef<any>;
    isLoading: ComputedRef<any>;
    caseStatisticsChartList: ComputedRef<any>;
    isChartLoading: ComputedRef<boolean>;
    getStatisticsList: () => void;
    caseStatisticsList: ComputedRef<any>;
    exportPdfTable: ComputedRef<any>;
    excelData: ComputedRef<any>;
    caseTitle: ComputedRef<string>;
}
@Options({
    template: `<slot></slot>`,
    provide(): TProvider {
        return {
            caseStatisticsFilterFormData: computed(() => this.caseStatisticsFilterFormData),
            isLoading: this.isLoading,
            caseStatisticsChartList: computed(() => this.caseStatisticsChartList),
            isChartLoading: computed(() => this.isChartLoading),
            getStatisticsList: this.getStatisticsList,
            caseStatisticsList: computed(() => this.caseStatisticsList),
            exportPdfTable: computed(() => this.exportPdfTable),
            excelData: computed(() => this.excelData),
            caseTitle: computed(()=> this.caseTitle)
        }
    }
})
export default class DashboardModuleHandler extends Vue {
    caseTitle:any={}
    container: Container = new Container();
    caseListService!: ICaseListService;
    caseStatisticsFilterFormData: any = {
        PType: "CaseType",
        PFacilityid: ""
    };
    isloading = true;
    caseStatisticsChartList: [] = [];
    isChartLoading = true;
    caseStatisticsList: [] = [];
    exportPdfTable: [] = [];
    excelData: [] = [];

    beforeCreate() {
        bindCaseListService(this.container);
        this.caseListService = this.container.get<ICaseListService>(IoCTypes.CaseListService);
    }
    created() {
        this.getStatisticsList();
    }
    constructTableBody(data: any, columns: any) {
        const body: any = [];
        body.push(columns);
        data.map((item: any) => {
            const row: any = [];
            row.push(item.type, item.currentYear, item.lastYear, item.previousYear);
            body.push(row);
            return body
        })
        return body;
    }
    getStatisticsList() {
        this.isChartLoading = true;
        this.caseStatisticsList = [];
        const userId = sessionStorage.getItem("userId");
        const payload: CaseStatisticsListRequest = {
            PPagenumber: 0,
            PPagelimit: 20,
            PUserId: userId ? userId : "",
            ...this.caseStatisticsFilterFormData,
        }
        switch(this.caseStatisticsFilterFormData.PType){
            case "Outcomes":
                this.caseTitle.value="Outcomes";
                break;
            case "CaseTotal":
                this.caseTitle.value="Case Total";
                break;
            case "OperationalStatistics":
                this.caseTitle.value="Operational Statistics";
                break;
            default:
                this.caseTitle.value="Case Type";
                break;
        }
        return this.caseListService.getCaseStatisticsList(payload).then((res: any) => {
            if (res && res.items && res.items.length > 0) {
                const cY: any = new Date().getFullYear()
                this.caseStatisticsList = res.items;
                const currentYearKey = "Current Year" + ` (${cY})`;
                const lastYearKey = "Last Year" + ` (${cY - 1})`
                const previousYearKey = "Previous Year" + ` (${cY - 2})`
                const caseStatsExcel = res.items.map((x: any) => {
                    const a = {
                        Type: x.type,
                        [currentYearKey]: x.currentYear,
                        [lastYearKey]: x.lastYear,
                        [previousYearKey]: x.previousYear
                    }
                    return { ...a }
                });
                this.excelData = caseStatsExcel;
                const fillColor = "#3d3d3d";
                this.exportPdfTable = this.constructTableBody(res.items, [{
                    text: "Type",
                    fillColor: fillColor,
                    style: "colheader"
                },
                {
                    text: `Current Year` + `(${cY})`,
                    fillColor: fillColor,
                    style: "colheader"
                },
                {
                    text: `Last Year` + `(${cY - 1})`,
                    fillColor: fillColor,
                    style: "colheader"
                },
                {
                    text: `Previous Year` + `(${cY - 2})`,
                    fillColor: fillColor,
                    style: "colheader"
                },])
                const datasets: any = [];
                res.items.map((x: any, i: number) => {
                    const obj = {
                        type: "bar",
                        label: x.cType,
                        data: [x.previousYear, x.lastYear, x.currentYear],
                        backgroundColor:
                            i === 0
                                ? "#ffa726"
                                : i === 1
                                    ? "#7030a0"
                                    : i === 2
                                        ? "#66bb6a"
                                        : i === 3
                                            ? "#42a5f5"
                                            : i === 4
                                                ? "#ff7f27"
                                                : i === 5
                                                    ? "#b97a57"
                                                    : i === 6
                                                        ? "#ec407a"
                                                        : i === 7
                                                            ? "#ab47bc"
                                                            : i === 8
                                                                ? "#78909c"
                                                                : i === 9
                                                                    ? "#ffca28"
                                                                    : i === 10
                                                                        ? "#ca472f"
                                                                        : i === 11
                                                                            ? "#00FF80"
                                                                            : "grey",
                        barPercentage: 0.4,

                    };
                    datasets.push(obj);
                    this.caseStatisticsChartList = datasets;
                    return datasets;
                });
            }
            else {
                this.caseStatisticsList = [];
                this.caseStatisticsChartList = [];

            }
            this.isChartLoading = false;
            return "";
        })
            .finally(() => this.isChartLoading = false)
    }
}
