import { FileUploadComponentService } from "@/services/common/file/FileUploadComponentService";
import { FileViewService } from "@/services/common/file/FileViewService"
import { IFileUploadComponentService } from "@/services/common/file/IFileUploadComponentService";
import { Container } from "inversify";
import { IoCTypes } from "./IoCTypes";
import { IFileViewService } from "@/services/common/file/IFileViewService";

export const bindFileUploadComponentService = (container: Container) => {
  container.bind<IFileUploadComponentService>(IoCTypes.FileUploadComponentService)
    .to(FileUploadComponentService)
}

export const bindFileViewService = (container: Container) => {
  container.bind<IFileViewService>(IoCTypes.FileViewService)
    .to(FileViewService)
}