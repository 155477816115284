import { CommonService } from "@/services/common/CommonService";
import { CommonStoreService } from "@/services/common/CommonStoreService";
import { ICommonService } from "@/services/common/ICommonService";
import { ICommonStoreService } from "@/services/common/ICommonStoreService";
import { Container } from "inversify";
import { IoCTypes } from "./IoCTypes";

export function bindCommonStoreService(container: Container) {
    container.bind<ICommonStoreService>(IoCTypes.CommonStoreService).to(CommonStoreService)
}

export function bindCommonService(container: Container) {
    container.bind<ICommonService>(IoCTypes.CommonService).to(CommonService);
    bindCommonStoreService(container);
}