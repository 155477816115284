import { RouteRecordRaw } from "vue-router";
import { Permissions } from "@/shared/ClaimsShared";
import { FormBuilder } from "@/components/GlobalComponents";

const routes: Array<RouteRecordRaw> = [{
  path: "/reports",
  component: () => import("@/views/reports/ReportsModulePage.vue"),
  children: [
    {
      path: "bjs",
      component: () => import("@/views/reports/Reports.vue"),
      meta: { permission: Permissions.readBJSReports },
      strict: true,
      sensitive: true
    },
    {
      path: "other",
      component: () => import("@/views/reports/OtherReports.vue"),
      meta: { permission: Permissions.otherReportsRead },
      strict: true,
      sensitive: true
    },
    {
      path: "bjs/view",
      component: FormBuilder,
      meta: { permission: Permissions.readBJSReports },
      strict: true,
      sensitive: true
    },
    {
      path: "other/view",
      component: FormBuilder,
      meta: { permission: Permissions.otherReportsRead },
      strict: true,
      sensitive: true
    },
    {
      path: "builder",
      component: () => import("@/views/reports/AdhocReports.vue"),
      meta: { permission: Permissions.queryBuilderReport },
    }
  ],
}];

export default routes;
