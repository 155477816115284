import { AsyncFormExecutionHandler } from "@/components/AsyncComponents"
import { withNavigationBlocker } from "@/components/common/NavigationBlocker"

export const withFormExecutionWrapper = (WrappedComponent: any) => {
    return withNavigationBlocker({
        template: `<Handler><WrappedComponent></WrappedComponent></Handler>`,
        components: {
            WrappedComponent,
            Handler: AsyncFormExecutionHandler
        },
    })
}