import { CaseTypesResponse } from "@/models/CaseModal";
import {
    Module, Mutation, VuexModule
} from "vuex-module-decorators";
import { CaseState } from "./CaseStoreTypes";

@Module({
    namespaced: true,
    name: "case"
})

export default class CaseStore extends VuexModule implements CaseState {
    latestCaseTypes!: CaseTypesResponse;
    get LatestCaseTypes(): CaseTypesResponse { return this.latestCaseTypes }

    @Mutation
    SAVE_LATEST_CASE_TYPES(caseTypes: CaseTypesResponse) {
        this.latestCaseTypes = caseTypes;
    }

}