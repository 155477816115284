import { InmateApi } from "@/api/InmateApi";
import {
    InmateRequest, InmateResponse
} from "@/models/InmateModel";
import { IdName } from "@/models/ListModel";
import { injectable } from "inversify";
import { IManageInmateService } from "./IManageInmateService";

@injectable()
export class ManageInmateService implements IManageInmateService {

    getInmateId(featureName: any) {
        return this.api.getInmateId(featureName)
    }
    api = new InmateApi();
    addInmate(payload: InmateRequest): Promise<string> {
        if (typeof (payload.height) === "string" && payload.height === "") {
            payload.height = null
        }
        if (typeof (payload.weight) === "string" && payload.weight === "") {
            payload.weight = null
        }
        return this.api.addInmate(payload);
    }
    getInmate(id: string): Promise<InmateRequest> {
        return this.api.getInmate(id).then(res => this.convertResponseToRequest(res));
    }
    updateInmate(payload: InmateRequest) {
        if (typeof (payload.height) === "string" && payload.height === "") {
            payload.height = null
        }
        if (typeof (payload.weight) === "string" && payload.weight === "") {
            payload.weight = null
        }
        return this.api.updateInmate(payload);
    }


    private convertResponseToRequest(res: InmateResponse): InmateRequest {
        const orientationList = res.orientations?.map((x: IdName<string>) => {
            return x.id;
        });
        return {
            id: res.id,
            inmateNo: res.inmateNo,
            bookingId: res.bookingId,
            bookingDate: res.bookingDate,
            firstName: res.firstName,
            middleName: res.middleName,
            lastName: res.lastName,
            dob: res.dob,
            ethnicity: res.ethnicity?.id,
            sex: res.sex?.id,
            height: res.height,
            weight: res.weight,
            race: res.race && res.race.map(x => x.id),
            otherRace: res.otherRace,
            otherSex: res.otherSex,
            preaScore: res.preaScore,
            classification: res.classification,
            facilityId: res.facility?.id,
            housingLocation: res.housingLocation,
            caseResponsibility: res.caseResponsibility,
            custodialResponsibility: res.custodialResponsibility,
            securityThreatGroup: res.securityThreatGroup,
            livingAssignment: res.livingAssignment,
            dischargeDate: res.dischargeDate,
            orientations: orientationList
        }
    }
}