import {
  actions,
  container,
  execution,
  field,
  properties,
  style
} from "@/models/FormBuilderModel";
import {
  ActionTypes,
  ContainerTypes
} from "@/shared/FormBuilderShared";
import { prop } from "vue-class-component";

export class BaseContainerProps implements container {
  id: string = prop({ required: true });
  name?: string = prop({ required: false });
  icon?: string = prop({ required: false });
  title?: string = prop({ required: false });
  subTitle?: string | undefined = prop({ required: false });
  description?: string | undefined = prop({ required: false });
  containerType: ContainerTypes = prop({ required: true });
  displayOrder?: number = prop({ required: false });
  fields?: field[] | undefined = prop({ required: false });
  containers?: container[] | undefined = prop({ required: false });
  style?: style = prop({ required: false });
  properties?: properties | undefined = prop({ required: false });
  actions?: actions[] | undefined = prop({ required: false });
  actionsStyle?: style = prop({ required: false });
  actionType?: ActionTypes | undefined = prop({ required: false });
  commands?: actions[] | undefined = prop({ required: false });
  modelValue: any = prop({ required: true });
  onCreate?: Array<execution> = prop({ required: false });
  onEvent?: Array<execution> = prop({ required: false });
  onCreateComponent?: Array<execution> = prop({ required: false });
}
