import { AsyncManageInmateHandler } from "@/components/AsyncComponents"

export const withManageInmate = (WrappedComponent: any) => {
    return {
        template: `<Handler><WrappedComponent></WrappedComponent></Handler>`,
        components: {
            WrappedComponent,
            Handler: AsyncManageInmateHandler
        },
    }
}