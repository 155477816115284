import {
  FieldOption,
  IdName,
  PaginateList
} from "@/models/ListModel";
import {
  inject,
  injectable
} from "inversify";
import { IRoleListService } from "./IRoleListService";
import { RoleApi } from "@/api/RoleApi";
import { ConvertIdNameToFieldOption } from "@/lib/Util";
import { IoCTypes } from "@/IoC/IoCTypes";
import { ILookpupStoreService } from "../lookup/ILookupStoreService";
import { PermissionListResponse } from "@/models/RoleModal";
// import { RoleListResponse } from "@/models/RoleModal";

@injectable()
export class RoleListService implements IRoleListService {
  roleApi: RoleApi = new RoleApi();

  api = new RoleApi();
  lookupStoreService: ILookpupStoreService;
  listKey = "roleList"

  constructor(@inject(IoCTypes.LookupStoreService) lookupStoreService: ILookpupStoreService) {
    this.lookupStoreService = lookupStoreService;
  }

  getRoleList(): Promise<FieldOption[]> {
    if (this.lookupStoreService.lookupDetails[this.listKey]) {
      return Promise.resolve(this.lookupStoreService.lookupDetails[this.listKey])
    }
    this.lookupStoreService.setLookupDetailsLoading(this.listKey, true);
    return this.api.getRoleList().then((res: IdName<string>[]) => {
      const options = ConvertIdNameToFieldOption(res);
      this.lookupStoreService.saveLookupDetails(this.listKey, options);
      this.lookupStoreService.setLookupDetailsLoading(this.listKey, false)
      return options;
    });
  }

  getRoles(): Promise<PaginateList<any>> {
    return this.roleApi.getRoles();

  }
  deleteRole(roleId: any): any {
    return this.roleApi.deleteRole(roleId)
  }
  getPermissions(index: number): Promise<PermissionListResponse[]> {
    return this.roleApi.getPermissionList(index);
  }
}