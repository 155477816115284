import { IoCTypes } from "@/IoC/IoCTypes";
import { bindRoleManageService } from "@/IoC/RoleIoC";
import { PermissionDictionary } from "@/models/RoleModal";
// import { RoleRequest } from "@/models/RoleModal";
import { IRoleManageService } from "@/services/role/manage/IRoleManageService";
import { Container } from "inversify";
import {
    computed,
    ComputedRef,
    inject
} from "vue";
import {
    Options, Vue
} from "vue-class-component";

type TProvider = {
    rolePage: ComputedRef<any>,
    onSave: () => void,
    isSaved: ComputedRef<boolean>,
    savingRole: ComputedRef<boolean>
    baserolelistflag: ComputedRef<boolean>

}
const roleForm = {
    roleName: "",
    description: "",
    parentRoleIds: [],
    roleId: ""
};
@Options({
    template: `<c-validation-listener v-model="validate"><slot></slot></c-validation-listener>`,
    provide(): TProvider {
        return {
            rolePage: computed(() => this.rolePage),
            onSave: this.onSave,
            isSaved: computed(() => this.isSaved),
            savingRole: computed(() => this.savingRole),
            baserolelistflag: computed(() => this.baserolelistflag)
        }
    },
    watch: { "rolePage.roleForm.parentRoleIds"() { this.getPermissionsByRole() } },
})
export default class RoleManageHandler extends Vue {
    validate: () => Promise<{ valid: boolean; errors?: any }> = () => new Promise<{ valid: boolean }>((res) => res({ valid: true }));
    container: Container = new Container();
    roleManageService!: IRoleManageService;
    rolePage: any = { roleForm }
    savingRole = false;
    isSaved = false;
    baserolelistflag = false;
    rowData: any = inject("rowData") as any;
    beforeCreate() {
        bindRoleManageService(this.container);
        this.roleManageService = this.container.get<IRoleManageService>(IoCTypes.RoleManageService);
    }
    handleerrror() {
        if (this.rolePage.roleForm.parentRoleIds.length < 0) {
            this.baserolelistflag = true;
        }
        else {
            this.baserolelistflag = false;
        }
    }
    getPermissionsByRole() {
        if (this.rolePage.roleForm.parentRoleIds?.length > 0) {
            if (this.rolePage.roleForm.roleId) {
                const roleId: string[] = [this.rolePage.roleForm.roleId];
                roleId.push(
                    ...this.rolePage.roleForm.parentRoleIds.filter((x: any) => !this.rowData.parentRoleIds.includes(x))
                )
                this.roleManageService.getRolePermissions(roleId)
                    .then((res: PermissionDictionary[]) => {
                        this.rolePage.roleForm.permissions = res.map((x: PermissionDictionary) => {
                            return x.key
                        }
                        )
                    })
            }
            else {
                this.roleManageService.getRolePermissions(this.rolePage.roleForm.parentRoleIds)
                    .then((res: PermissionDictionary[]) => {
                        this.rolePage.roleForm.permissions = res.map((x: PermissionDictionary) => {
                            return x.key
                        }
                        )
                    })
            }
        }
        else {
            this.rolePage.roleForm.permissions = []
        }
    }
    async onSave() {
        const validationResult = await this.validate()
        if (!validationResult.valid) return;
        if (this.rolePage.roleForm.parentRoleIds.length !== 0) {
            this.baserolelistflag = false;
            this.savingRole = true;
            if (this.rolePage.roleForm.roleId) {
                this.roleManageService.updateRole(this.rolePage.roleForm).then((res: any) => {
                    if (res) {
                        this.$toast.add({
                            severity: "success",
                            summary: "Success",
                            detail: "Updated Successfully",
                            life: 4000
                        });
                        this.isSaved = true;
                        this.savingRole = false;
                        delete this.rolePage.roleForm.roleName;
                        delete this.rolePage.roleForm.description;
                        delete this.rolePage.roleForm.parentRoleIds;
                        delete this.rolePage.roleForm.roleId;
                    }
                    return res
                })
                    .catch((err: PromiseRejectedResult) => {
                        console.error("err.reason", err)
                        this.$toast.add({
                            severity: "error",
                            summary: "Failed",
                            detail: err,
                            life: 4000
                        });
                        this.isSaved = true
                    })
                    .finally(() => this.isSaved = true)
            }
            else {
                this.roleManageService.saveRole(this.rolePage.roleForm).then((res: any) => {
                    if (res) {
                        this.$toast.add({
                            severity: "success",
                            summary: "Success",
                            detail: "Added Successfully",
                            life: 4000
                        });
                        this.isSaved = true;
                        this.savingRole = false;
                        delete this.rolePage.roleForm.roleName;
                        delete this.rolePage.roleForm.description;
                        delete this.rolePage.roleForm.parentRoleIds;
                        delete this.rolePage.roleForm.roleId;
                    }
                    return res
                })
                    .catch((err: PromiseRejectedResult) => {
                        console.error("err.reason", err)
                        this.$toast.add({
                            severity: "error",
                            summary: "Failed",
                            detail: err,
                            life: 4000
                        });
                        this.isSaved = true
                    })
                    .finally(() => this.isSaved = true)
            }
        }
        else {
            this.baserolelistflag = true;
        }

    }
}


