import ChildFormListHandler from "./ChildFormListHandler"

export const withChildFormList = (WrappedComponent: any) => {
    return {
        props: WrappedComponent.props,
        template: `<ChildFormListHandler v-bind="$props"><WrappedComponent></WrappedComponent></ChildFormListHandler>`,
        components: {
            WrappedComponent,
            ChildFormListHandler
        }
    }
}