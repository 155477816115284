import { IoCTypes } from "@/IoC/IoCTypes";
import {
    authorizePermission,
    getRefreshToken,
    getToken,
    loginByRefreshToken
} from "@/lib/AuthUtil";
import { StoreUtil } from "@/lib/StoreUtil";
import { loadAppConfig } from "@/lib/Util";
import {
    inject,
    injectable
} from "inversify";
import { IProfileService } from "../Profile/IProfileService";
import { IAppConfigService } from "./IAppConfigService";
import { IAppService } from "./IAppService";

@injectable()
export class AppService implements IAppService {
    profileService!: IProfileService;
    appConfigService!: IAppConfigService;

    constructor(
        @inject(IoCTypes.ProfileService) profileService: IProfileService,
        @inject(IoCTypes.AppConfigService) appConfigService: IAppConfigService
  
    ){
        this.profileService = profileService;
        this.appConfigService = appConfigService;
    }
    

    async loadAuthorization(obj: any): Promise<boolean> {
        if (!new StoreUtil("local").getItem("CONFIG")) await loadAppConfig();
        const refreshToken = getRefreshToken();
        if (refreshToken && !getToken()) {
            await loginByRefreshToken(refreshToken);
        }
        if (getToken()) {
            await this.profileService.getProfile();
            await this.profileService.getClaim();
            await this.appConfigService.getAppConfigDetails();
            const permission = obj.$route.meta.permission
            if (permission && !authorizePermission(String(permission))) return false;
        }
        return true;
    }
}