import { HttpUtil } from "@/lib/HttpUtil";
import { PaginateList } from "@/models/ListModel";
import {
    TemplateDataRequest,
    TemplateDataListResponse,
    TemplateDataResponse,
    TemplateResponse
} from "@/models/TemplateModel";
import { FileMetadata } from "@/models/FilesModel";
import { DynamicApi } from "@/models/FormBuilderModel";
import { Helpers } from "@/lib/Util";

export class TemplateApi {
    httpUtil: HttpUtil = new HttpUtil();
    getLatestTemplate(name: string, version = 0): Promise<TemplateResponse> {
        return this.httpUtil.get<TemplateResponse>(`template/latest?name=${name}&version=${version}`);
    }
    getTemplate(templateId: string): Promise<TemplateResponse> {
        return this.httpUtil.get<TemplateResponse>("template", { templateId })
    }
    getTemplateDataList(templateId: string): Promise<PaginateList<TemplateDataListResponse>> {
        return this.httpUtil.get<PaginateList<TemplateDataListResponse>>("template/templateData/list", { templateId })
    }
    createTemplateData(payload: TemplateDataRequest, files: File[]): Promise<string> {
        return this.httpUtil.postWithFiles<string>("template/templateData", payload, files, { templateId: payload.templateId })
    }
    updateTemplateData(payload: TemplateDataRequest, files: File[]): Promise<boolean> {
        return this.httpUtil.putWithFiles<boolean>("template/templateData", payload, files, { id: payload.id })
    }
    getTemplateData(id: string): Promise<TemplateDataResponse> {
        return this.httpUtil.get<TemplateDataResponse>("template/templateData", { id })
    }

    saveAttachmentData(payLoad: FileMetadata[], caseId: string): Promise<boolean> {

        return this.httpUtil.post<boolean>("template/".concat(caseId, "/attachments"), payLoad);
    }
    getCaseAttachments(caseId: string): Promise<any> {
        return this.httpUtil.get<any>("template/".concat(caseId, "/attachments"));
    }

    getDynamicApi(api: DynamicApi): Promise<any> {
        return new HttpUtil(api.origin).get<any>(api.path, api.header);
    }

    postDynamicApi(api: DynamicApi): Promise<any> {
        return new HttpUtil(api.origin).post<any>(api.path, api.payload, api.header);
    }

    putDynamicApi(api: DynamicApi): Promise<any> {
        return new HttpUtil(api.origin).put<any>(api.path, api.payload, api.header);
    }

    async postAndGetBlob(api: DynamicApi): Promise<{ resp: Response, blob: Blob }> {
        const res = await new HttpUtil(api.origin).postAndGetBlob(api.path, api.payload, api.header);
        if (res.resp.headers.has("x-filename")) {
            this.downloadBlob(res.resp.headers.get("x-filename") as any, res.blob, true)
        }
        else {
            this.downloadBlob(Helpers.randomString(10), res.blob, true)
        }
        return res;
    }

    async getBlob(api: DynamicApi): Promise<{ resp: Response, blob: Blob }> {
        const res = await new HttpUtil(api.origin).getBlob(api.path);
        if (res.resp.headers.has("x-filename")) {
            this.downloadBlob(res.resp.headers.get("x-filename") as any, res.blob, true)
        }
        else {
            this.downloadBlob(Helpers.randomString(10), res.blob, true)
        }
        return res;
    }

    private downloadBlob(fileName: string, blobBytes: Blob, shouldOpen: boolean): void {
        const ele: HTMLAnchorElement = document.createElement("a") as HTMLAnchorElement;
        ele.href = URL.createObjectURL(blobBytes);
        if (shouldOpen) {
            ele.target = "_blank";
        }
        else {
            ele.download = fileName;
        }
        ele.click();
        //URL.revokeObjectURL(ele.href);
    }

    downloadAttachment(id: string): Promise<any> {
        return this.httpUtil.postAndGetBlob("attachments/download/".concat(id), {})
    }
}