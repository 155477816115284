import { bindCommonStoreService } from "@/IoC/CommonIoC";
import { bindFacilityListService } from "@/IoC/FacilityIoC";
import { bindManageInmateService } from "@/IoC/InmateIoC";
import { IoCTypes } from "@/IoC/IoCTypes";
import { FacilityListResponse } from "@/models/FacilityModal";
import { InmateRequest } from "@/models/InmateModel";
import { ICommonStoreService } from "@/services/common/ICommonStoreService";
import { IFacilityListService } from "@/services/facility/facility-list/IFacilityListService";
import { IManageInmateService } from "@/services/inmate/IManageInmateService";
import { Container } from "inversify";
import {
    computed, ComputedRef, inject
} from "vue";
import { Options } from "vue-class-component";
import BaseHandler from "../BaseHandler";

type TProvider = {
    inmate: ComputedRef<InmateRequest>,
    isLoading: ComputedRef<boolean>,
    facilityList: ComputedRef<FacilityListResponse>
    isFacilityLoading: ComputedRef<boolean>
    saveInmate: () => void
}

@Options({
    template: `<c-validation-listener v-model="validate"><slot></slot></c-validation-listener>`,
    provide(): TProvider {
        return {
            inmate: computed(() => this.inmate),
            isLoading: computed(() => this.isLoading),
            saveInmate: this.saveInmate,
            facilityList: computed(() => this.facilityList),
            isFacilityLoading: computed(() => this.isFacilityLoading)
        }
    }
})
export default class ManageInmateHandler extends BaseHandler {
    manageInmateService!: IManageInmateService;
    container: Container = new Container();
    facilityList: FacilityListResponse[] = [];
    facilityListService!: IFacilityListService;
    refreshList: () => void = inject("refreshList") as () => void;
    commonStoreService!: ICommonStoreService;
    isFacilityLoading = true;

    inmate: InmateRequest = {
        inmateNo: "",
        firstName: "",
        lastName: "",
        height: null
    };
    isLoading = false;


    beforeCreate() {
        bindManageInmateService(this.IoC);
        bindFacilityListService(this.container);
        bindCommonStoreService(this.container)
        this.manageInmateService = this.IoC.get<IManageInmateService>(IoCTypes.ManageInmateService);
        this.facilityListService = this.container.get<IFacilityListService>(IoCTypes.FacilityListService);
        this.commonStoreService = this.container.get<ICommonStoreService>(IoCTypes.CommonStoreService)
    }

    created() {
        if (this.$route.params.id) {
            this.inmate.id = this.$route.params.id.toString();
            this.getInmate();
        }
        this.getFacilityList();
    }
    getFacilityList() {
        this.facilityListService.getFacilityList().then((res: any) => {
            if (res && res.length > 0) {
                this.facilityList = res.map((x: any) => {
                    return {
                        text: x.text,
                        value: x.value
                    }
                })
            }
            this.isFacilityLoading = false;
        }).catch(() => this.isFacilityLoading = false)
            .finally(() => this.isFacilityLoading = false)
    }

    getInmate() {
        if (this.inmate.id) {
            this.isLoading = true;
            this.manageInmateService.getInmate(this.inmate.id).then((res: InmateRequest) => {
                this.inmate = res;
            }).finally(() => this.isLoading = false)
        }
    }

    async saveInmate() {
        this.isLoading = true;
        const validationResult = await this.validate()
        if (!validationResult.valid) { this.isLoading = false; return }
        if (this.inmate.id) {
            this.manageInmateService.updateInmate(this.inmate).then(() => {
                this.$confirm.require({
                    message: JSON.stringify({
                        text: "Inmate Updated Sucessfully",
                        status: "SUCCESS",
                        type: "INFORM"
                    }),
                    header: "Saved",
                    acceptLabel: "Ok",
                    icon: "pi pi-check"

                });
                this.commonStoreService.setNeedInmateListRefresh(true)
                this.$router.back();
            }).catch((err: any) => {
                console.warn(err);
                this.$toast.add({
                    severity: "error",
                    summary: "Failed",
                    detail: err,
                    life: 3000
                });
            }).finally(() => this.isLoading = false)
        }
        else {
            this.manageInmateService.addInmate(this.inmate).then(() => {
                this.$confirm.require({
                    message: JSON.stringify({
                        text: "Inmate Saved Sucessfully",
                        status: "SUCCESS",
                        type: "INFORM"
                    }),
                    header: "Saved",
                    acceptLabel: "Ok",
                    icon: "pi pi-check"

                });
                this.$router.back();
                this.refreshList();
            }).catch((err: any) => {
                console.warn(err);
                this.$toast.add({
                    severity: "error",
                    summary: "Failed",
                    detail: err,
                    life: 3000
                });
            }).finally(() => this.isLoading = false)
        }
    }
}