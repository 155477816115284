import { IInmateListService } from "@/services/inmate/IInmateListService";
import { IManageInmateService } from "@/services/inmate/IManageInmateService";
import { InmateListService } from "@/services/inmate/InmateListService";
import { ManageInmateService } from "@/services/inmate/ManageInmateService";
import { Container } from "inversify";
import { bindCommonStoreService } from "./CommonIoC";
import { IoCTypes } from "./IoCTypes";

export function bindManageInmateService(container: Container) {
    container.bind<IManageInmateService>(IoCTypes.ManageInmateService).to(ManageInmateService);
}

export function bindInmateListService(container: Container) {
    container.bind<IInmateListService>(IoCTypes.InmateListServices).to(InmateListService);
    bindCommonStoreService(container);
}