import { AsyncInmateModuleHandler } from "@/components/AsyncComponents"

export const withInmateModule = (WrappedComponent: any) => {
    return {
        template: `<Handler><WrappedComponent></WrappedComponent></Handler>`,
        components: {
            WrappedComponent,
            Handler: AsyncInmateModuleHandler
        },
    }
}