import { AsyncManageLoginHandler } from "@/components/AsyncComponents";

export const withLoginModuleWrapper = (WrappedComponent: any) => {
  return {
    template: `<LoginModuleHandler><WrappedComponent></WrappedComponent></LoginModuleHandler>`,
    components: {
      LoginModuleHandler: AsyncManageLoginHandler,
      WrappedComponent,
    },
  };
};
