import { execution } from "@/models/FormBuilderModel";
import { ICustomFunction } from "../ICustomFunction";

export class GetValue {
    private exec: execution;
    private func: ICustomFunction;

    constructor(exec: execution, func: ICustomFunction) {
        this.exec = exec;
        this.func = func;
    }

    execute() {        
        return this.func.getValue(this.exec.value);
    }
}