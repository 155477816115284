import { TemplateResponse } from "@/models/TemplateModel";
import AppStore from "@/store";
import FormBuilderStore from "@/store/form-builder/FormBuilderStore";
import { injectable } from "inversify";
import { getModule } from "vuex-module-decorators";
import { IFormBuilderStoreService } from "./IFormBuilderStoreService";
import ProfileStore from "@/store/profile/ProfileStore";
import { ProfileResponse } from "@/models/Profile";

@injectable()
export class FormBuilderStoreService implements IFormBuilderStoreService {
    formBuilderStore!: FormBuilderStore;
    profileStore!: ProfileStore;

    get LatestTemplates(): { [field: string]: TemplateResponse } {
        return this.formBuilderStore.LatestTemplates;
    }
    get Profile(): ProfileResponse {
        return this.profileStore.MyProfile;
    }

    get ApiResponses(): { [field: string]: any } {
        return this.formBuilderStore.ApiResponses;
    }

    constructor() {
        this.formBuilderStore = getModule(FormBuilderStore, AppStore);
        this.profileStore = getModule(ProfileStore, AppStore);
    }

    saveLatestTemplate(template: TemplateResponse, name: string): void {
        this.formBuilderStore.SAVE_LATEST_TEMPLATE({
            template,
            name
        });
    }

    saveApiResponse(field: string, apiResponse: any) {
        this.formBuilderStore.SAVE_API_RESPONSE({
            field,
            apiResponse
        })
    }

}