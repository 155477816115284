import {
    LoginRequest,
    LoginResponse
} from "@/models/Login";
import { injectable } from "inversify";
import { ILoginService } from "./ILoginService";
import { Authapi } from "../../api/AuthApi";
import { StoreUtil } from "@/lib/StoreUtil";
import { loginSuccess } from "@/lib/AuthUtil";
import { encryptedText } from "@/lib/Util";
import { UserApi } from "@/api/UserApi";

@injectable()
export class LoginService implements ILoginService {
    authApi: Authapi = new Authapi();
    userApi: UserApi = new UserApi();
    storeUtil: StoreUtil = new StoreUtil();

    validateLogin(data: LoginRequest): Promise<LoginResponse> {
        return this.authApi.validateLogin(data).then((res: LoginResponse) => {
            if(res.resetToken === null || res.resetToken === undefined){
                if (data.rememberMe) new StoreUtil("local").setItem("keep_me_signed_in", data.rememberMe);
                this.storeUtil.setItem("userProfile", encryptedText(res.userProfile));
                this.storeUtil.setItem("userClaims", encryptedText(res.userClaims));
                loginSuccess(res);
            }
            return res
        });
    }
}