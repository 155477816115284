import { App } from "vue";
import accordion from "primevue/accordion";
import accordiontab from "primevue/accordiontab";
import autocomplete from "primevue/autocomplete";
import avatar from "primevue/avatar";
import avatargroup from "primevue/avatargroup";
import badge from "primevue/badge";
import blockui from "primevue/blockui";
import breadcrumb from "primevue/breadcrumb";
import button from "primevue/button";
import calendar from "primevue/calendar";
import card from "primevue/card";
import carousel from "primevue/carousel";
import cascadeselect from "primevue/cascadeselect";
import checkbox from "primevue/checkbox";
import chip from "primevue/chip";
import chips from "primevue/chips";
import colorpicker from "primevue/colorpicker";
import confirmdialog from "primevue/confirmdialog";
import confirmpopup from "primevue/confirmpopup";
import contextmenu from "primevue/contextmenu";
import datatable from "primevue/datatable";
import column from "primevue/column"
import dataview from "primevue/dataview";
import dataviewlayoutoptions from "primevue/dataviewlayoutoptions";
import deferredcontent from "primevue/deferredcontent";
import dialog from "primevue/dialog";
import divider from "primevue/divider";
import dock from "primevue/dock";
import dropdown from "primevue/dropdown";
import fieldset from "primevue/fieldset";
import fileupload from "primevue/fileupload";
import galleria from "primevue/galleria";
import image from "primevue/image";
import inplace from "primevue/inplace";
import inputmask from "primevue/inputmask";
import inputnumber from "primevue/inputnumber";
import inputswitch from "primevue/inputswitch";
import inputtext from "primevue/inputtext";
import knob from "primevue/knob";
import listbox from "primevue/listbox";
import megamenu from "primevue/megamenu";
import menu from "primevue/menu";
import menubar from "primevue/menubar";
import message from "primevue/message";
import multiselect from "primevue/multiselect";
import orderlist from "primevue/orderlist";
import organizationchart from "primevue/organizationchart";
import overlaypanel from "primevue/overlaypanel";
import paginator from "primevue/paginator";
import panel from "primevue/panel";
import panelmenu from "primevue/panelmenu";
import password from "primevue/password";
import picklist from "primevue/picklist";
import progressbar from "primevue/progressbar";
import progressspinner from "primevue/progressspinner";
import radiobutton from "primevue/radiobutton";
import rating from "primevue/rating";
import scrollpanel from "primevue/scrollpanel";
import scrolltop from "primevue/scrolltop";
import selectbutton from "primevue/selectbutton";
import sidebar from "primevue/sidebar";
import skeleton from "primevue/skeleton";
import slider from "primevue/slider";
import speeddial from "primevue/speeddial";
import splitbutton from "primevue/splitbutton";
import splitter from "primevue/splitter";
import splitterpanel from "primevue/splitterpanel";
import steps from "primevue/steps";
import tabmenu from "primevue/tabmenu";
import tabview from "primevue/tabview";
import tabpanel from "primevue/tabpanel";
import tag from "primevue/tag";
import terminal from "primevue/terminal";
import textarea from "primevue/textarea";
import tieredmenu from "primevue/tieredmenu";
import timeline from "primevue/timeline";
import toast from "primevue/toast";
import togglebutton from "primevue/togglebutton";
import toolbar from "primevue/toolbar";
import tree from "primevue/tree";
import treeselect from "primevue/treeselect";
import treetable from "primevue/treetable";
import tristatecheckbox from "primevue/tristatecheckbox";
import virtualscroller from "primevue/virtualscroller";


const install = (app: App): void => {
  app.component("pv-accordion", accordion);
  app.component("pv-accordiontab", accordiontab);
  app.component("pv-autocomplete", autocomplete);
  app.component("pv-avatar", avatar);
  app.component("pv-avatargroup", avatargroup);
  app.component("pv-badge", badge);
  app.component("pv-blockui", blockui);
  app.component("pv-breadcrumb", breadcrumb);
  app.component("pv-button", button);
  app.component("pv-calendar", calendar);
  app.component("pv-card", card);
  app.component("pv-carousel", carousel);
  app.component("pv-cascadeselect", cascadeselect);
  app.component("pv-checkbox", checkbox);
  app.component("pv-chip", chip);
  app.component("pv-chips", chips);
  app.component("pv-colorpicker", colorpicker);
  app.component("pv-confirmdialog", confirmdialog);
  app.component("pv-confirmpopup", confirmpopup);
  app.component("pv-contextmenu", contextmenu);
  app.component("pv-datatable", datatable);
  app.component("pv-column", column);
  app.component("pv-dataview", dataview);
  app.component("pv-dataviewlayoutoptions", dataviewlayoutoptions);
  app.component("pv-deferredcontent", deferredcontent);
  app.component("pv-dialog", dialog);
  app.component("pv-divider", divider);
  app.component("pv-dock", dock);
  app.component("pv-dropdown", dropdown);
  app.component("pv-fieldset", fieldset);
  app.component("pv-fileupload", fileupload);
  app.component("pv-galleria", galleria);
  app.component("pv-image", image);
  app.component("pv-inplace", inplace);
  app.component("pv-inputmask", inputmask);
  app.component("pv-inputnumber", inputnumber);
  app.component("pv-inputswitch", inputswitch);
  app.component("pv-inputtext", inputtext);
  app.component("pv-knob", knob);
  app.component("pv-listbox", listbox);
  app.component("pv-megamenu", megamenu);
  app.component("pv-menu", menu);
  app.component("pv-menubar", menubar);
  app.component("pv-message", message);
  app.component("pv-multiselect", multiselect);
  app.component("pv-orderlist", orderlist);
  app.component("pv-organizationchart", organizationchart);
  app.component("pv-overlaypanel", overlaypanel);
  app.component("pv-paginator", paginator);
  app.component("pv-panel", panel);
  app.component("pv-panelmenu", panelmenu);
  app.component("pv-password", password);
  app.component("pv-picklist", picklist);
  app.component("pv-progressbar", progressbar);
  app.component("pv-progressspinner", progressspinner);
  app.component("pv-radiobutton", radiobutton);
  app.component("pv-rating", rating);
  app.component("pv-scrollpanel", scrollpanel);
  app.component("pv-scrolltop", scrolltop);
  app.component("pv-selectbutton", selectbutton);
  app.component("pv-sidebar", sidebar);
  app.component("pv-skeleton", skeleton);
  app.component("pv-slider", slider);
  app.component("pv-speeddial", speeddial);
  app.component("pv-splitbutton", splitbutton);
  app.component("pv-splitter", splitter);
  app.component("pv-splitterpanel", splitterpanel);
  app.component("pv-steps", steps);
  app.component("pv-tabmenu", tabmenu);
  app.component("pv-tabview", tabview);
  app.component("pv-tabpanel", tabpanel);
  app.component("pv-tag", tag);
  app.component("pv-terminal", terminal);
  app.component("pv-textarea", textarea);
  app.component("pv-tieredmenu", tieredmenu);
  app.component("pv-timeline", timeline);
  app.component("pv-toast", toast);
  app.component("pv-togglebutton", togglebutton);
  app.component("pv-toolbar", toolbar);
  app.component("pv-tree", tree);
  app.component("pv-treeselect", treeselect);
  app.component("pv-treetable", treetable);
  app.component("pv-tristatecheckbox", tristatecheckbox);
  app.component("pv-virtualscroller", virtualscroller);
}

export default { install };