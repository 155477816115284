import { InmateApi } from "@/api/InmateApi";
import { InmateListResponse } from "@/models/InmateModel";
import { PaginateList } from "@/models/ListModel";
import { ListQuery } from "@/models/ListQuery";
import { injectable } from "inversify";
import { IInmateListService } from "./IInmateListService";

@injectable()
export class InmateListService implements IInmateListService {
    InmateApi = new InmateApi();
    getInmateList(query: ListQuery): Promise<PaginateList<InmateListResponse>> {
        return this.InmateApi.getInmateList(query)
    }
    getInmateQuestionnaire(id: string) {
        return this.InmateApi.getInmateQuestionnaire(id)
    }
}