import axios, {
    AxiosInstance,
    CancelToken,
    CancelTokenSource
} from "axios";
import {
    handleBlobResponse,
    handleError,
    handleResponse
} from "./HttpResponseUtil";
import { GlobalConfigService } from "@/services/GlobalConfigService";
import qs from "qs";
import { StoreUtil } from "./StoreUtil";
import {
    getSessionRefreshToken,
    loginByRefreshToken
} from "./AuthUtil";

const cancelToken: { [field: string]: CancelTokenSource } = {};

function processFormData(payload: any, files: File[]): FormData {
    const data: FormData = new FormData();
    if (payload !== undefined) {

        for (const file of files) {
            data.append("files", file);
        }

    }
    data.append("request", JSON.stringify(payload));
    return data;
}

export function loadTokenCancel(cancelTokenKey: string): CancelToken {
    if (cancelToken[cancelTokenKey]) cancelToken[cancelTokenKey].cancel(String("previous ") + cancelTokenKey + String(" cancelled"));
    cancelToken[cancelTokenKey] = axios.CancelToken.source();
    return cancelToken[cancelTokenKey].token;
}

export class HttpUtil {
    axiosInstance!: AxiosInstance;
    baseUrl!: string;
    storeUtil: StoreUtil = new StoreUtil();

    constructor(baseDomain?: string) {
        // eslint-disable-next-line @typescript-eslint/no-this-alias
        const This = this;

        switch (baseDomain) {
            case "IDP": this.baseUrl = GlobalConfigService.Instance.getIDPBaseUrl(); break;
            case "LOCAL": this.baseUrl = "/"; break;
            default: this.baseUrl = GlobalConfigService.Instance.getApiBaseUrl(); break;
        }

        this.axiosInstance = axios.create({ baseURL: this.baseUrl });

        // Add a request interceptor
        this.axiosInstance.interceptors.request.use(function (config: any) {
            const storeUtil: StoreUtil = new StoreUtil();
            config.headers["Authorization"] = storeUtil.getItem("token_type") + String(" ") + storeUtil.getItem("access_token");
            if (storeUtil.getItem("orgId")) config.headers["org_id"] = storeUtil.getItem("orgId");
            config.cancelToken = loadTokenCancel(config.headers["CancelToken"] || config.url);
            return config;
        }, function (error: any) {
            // Do something with request error
            return Promise.reject(error);
        });

        // Add a response interceptor
        this.axiosInstance.interceptors.response.use(function (response: any) {
            return response;
        }, async function (error: any) {
            if (error.response && error.response.status === 401 && !error.config._retry) {
                error.config._retry = true;
                await loginByRefreshToken(getSessionRefreshToken());
                return This.axiosInstance(error.config);
            }
            return Promise.reject(error);
        });
    }

    get<T>(path: string, headersArg: any = {}) {
        const headers = { ...headersArg }
        return this.axiosInstance.get<T>(path, { headers }).then(handleResponse).catch(handleError)
    }

    post<T>(path: string, payload: any, headersArg: any = {}) {
        const headers = {
            ...headersArg,
            "Content-Type": "application/json"
        }
        return this.axiosInstance.post<T>(path, JSON.stringify(payload), { headers }).then(handleResponse).catch(handleError)
    }
    postForm<T>(path: string, payload: any, headersArg: any = {}) {
        const headers = {
            ...headersArg,
            "Content-Type": "application/x-www-form-urlencoded"
        }
        return this.axiosInstance.post<T>(path, qs.stringify(payload), { headers }).then(handleResponse).catch(handleError)
    }
    putForm<T>(path: string, payload: any, headersArg: any = {}) {
        const headers = {
            ...headersArg,
            "Content-Type": "application/x-www-form-urlencoded"
        }
        return this.axiosInstance.put<T>(path, qs.stringify(payload), { headers }).then(handleResponse).catch(handleError)
    }
    postWithFiles<T>(path: string, payload: any, files: File[], headersArg: any = {}) {
        const headers = {
            ...headersArg,
            "Content-Type": "application/json"
        }
        return this.axiosInstance.post<T>(path, processFormData(payload, files), { headers }).then(handleResponse).catch(handleError)
    }
    put<T>(path: string, payload: any, headersArg: any = {}) {
        const headers = {
            ...headersArg,
            "Content-Type": "application/json"
        }
        return this.axiosInstance.put<T>(path, JSON.stringify(payload), { headers }).then(handleResponse).catch(handleError)
    }
    putWithFiles<T>(path: string, payload: any, files: File[], headersArg: any = {}) {
        const headers = {
            ...headersArg,
            "Content-Type": "application/json"
        }
        return this.axiosInstance.put<T>(path, processFormData(payload, files), { headers }).then(handleResponse).catch(handleError)
    }
    delete<T>(path: string, payload: any, headersArg: any = {}) {
        const headers = {
            "Content-Type": "application/json",
            ...headersArg,
        }
        return this.axiosInstance.delete<T>(path, {
            headers,
            data: JSON.stringify(payload)
        }).then(handleResponse).catch(handleError)
    }
    deleteForm<T>(path: string, headersArg: any = {}) {
        const headers = {
            "Content-Type": "application/x-www-form-urlencoded",
            ...headersArg,
        }
        return this.axiosInstance.delete<T>(path, { headers }).then(handleResponse).catch(handleError)
    }

    postAndGetBlob = (path: string, payload: any, argHeaders?: HeadersInit): Promise<{ resp: Response, blob: Blob }> => {
        const headers = {
            "Content-Type": "application/json",
            "Accept": "application/pdf",
            ...argHeaders
        }
        return this.getFetchInstance(path,
            {
                method: "POST",
                body: JSON.stringify(payload),
                headers,
            }).then(handleBlobResponse);
    }

    getBlob = (path: string): Promise<{ resp: Response, blob: Blob }> => {
        const headers = {
            "Content-Type": "application/json",
            "Accept": "application/pdf",
        }
        return this.getFetchInstance(path,
            {
                method: "GET",
                headers,
            }).then(handleBlobResponse);
    }

    getFetchInstance(path: string, init?: RequestInit | undefined) {
        return this.getFetch(path, init).then((res: Response) => {
            if (res.status === 401) return loginByRefreshToken(getSessionRefreshToken()).then(() => this.getFetch(path, init));
            return res;
        });
    }

    getFetch(path: string, init?: RequestInit | undefined) {
        return fetch(`${this.baseUrl}${path}`, {
            ...init,
            headers: {
                ...init?.headers,
                Authorization: this.storeUtil.getItem("token_type") + String(" ") + this.storeUtil.getItem("access_token"),
                "org_id": this.storeUtil.getItem("orgId"),
            }
        })
    }
}