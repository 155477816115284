import { LoginResponse } from "@/models/Login";
import { GlobalConfigService } from "@/services/GlobalConfigService";
import AppStore from "@/store";
import ClaimStore from "@/store/claim/ClaimStore";
import axios, { AxiosResponse } from "axios";
import qs from "qs";
import { getModule } from "vuex-module-decorators";
import { StoreUtil } from "./StoreUtil";

const claimStore: ClaimStore = getModule(ClaimStore, AppStore)

export const authorizePermission = (permission: string) => {
    if (permission && claimStore.Permissions) return claimStore.Permissions.includes(permission);
    return true;
};

export const filterListByPermission = (list: any[]) => {
    return list.filter(x => authorizePermission(x.permission))
}

export const getToken = () => {
    return new StoreUtil().getItem("access_token");
};
export const getRefreshToken = () => {
    return new StoreUtil("local").getItem("refresh_token");
};
export const getSessionRefreshToken = () => {
    return new StoreUtil().getItem("refresh_token");
};

export const logout = () => {
    sessionStorage.clear();
    localStorage.clear();
    window.location.pathname = "/login";
}
export const loginSuccess = (res: LoginResponse) => {
    const storeUtil: StoreUtil = new StoreUtil();
    storeUtil.setItem("access_token", res.access_token);
    storeUtil.setItem("expires_in", res.expires_in);
    storeUtil.setItem("refresh_token", res.refresh_token);
    storeUtil.setItem("scope", res.scope);
    storeUtil.setItem("token_type", res.token_type);
    if (new StoreUtil("local").getItem("keep_me_signed_in")) new StoreUtil("local").setItem("refresh_token", res.refresh_token);
}


export function loginByRefreshToken(refreshToken: string) {
    const data = {
        "client_id": "prea_ui",
        "client_secret": "secret",
        "grant_Type": "refresh_token",
        "refresh_token": refreshToken
    };
    const headers = { "Content-Type": "application/x-www-form-urlencoded" }
    const url = GlobalConfigService.Instance.getIDPBaseUrl() + String("connect/token");
    return axios.post<LoginResponse>(url, qs.stringify(data), { headers })
        .then((res: AxiosResponse<LoginResponse>) => loginSuccess(res.data))
        .catch((error) => {
            logout();
            throw error;
        });
}