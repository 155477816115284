import { HttpUtil } from "@/lib/HttpUtil";
import {
    AdHocQueryModel,
    Column, DataSet
} from "@/models/QueryBuilderTokensModel";

export class QueryApi {

    private httpUtil: HttpUtil = new HttpUtil("");

    async getQueries(): Promise<Array<any>> {
        const resp = await this.httpUtil.get("/query/getall");
        return resp.items;
    }

    async getQueryById(id: string): Promise<AdHocQueryModel> {
        const resp = await this.httpUtil.get(`/query/getbyid/${id}`);
        return resp;
    }

    async getDatasets(): Promise<Array<DataSet>> {
        const resp = await this.httpUtil.get("/query/getDatasets");
        return resp;
    }

    async getDatasetColumns(datasetName: string): Promise<Array<Column>> {
        const resp = await this.httpUtil.get(`/query/getDatasetColumns?datasetName=${datasetName}`);
        return resp;
    }

    async saveReport(payload: any): Promise<void> {
        const resp = await this.httpUtil.post("query/savequery", payload);
        return resp;
    }

    async runReport(reportId: any, newParams: any): Promise<Array<any>> {
        const resp = await this.httpUtil.post(`query/runreport/${reportId}`, newParams);
        return resp;
    }

    async downloadReport(reportId: any, newParams: any): Promise<{ resp: Response, blob: Blob }> {
        const resp = await this.httpUtil.postAndGetBlob(`query/downloadreport/${reportId}`, newParams);
        return resp;
    }

    async getOptions(type: any, code: any): Promise<Array<{ label: string, value: string }>> {
        const resp = await this.httpUtil.get(`query/getoptions/${type}/${code}`);
        return resp;
    }

    deleteAdHocQuery(queryId: string): Promise<boolean> {
        return this.httpUtil.delete(`query?adHocId=${queryId}`, {});
    }
}