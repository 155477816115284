import AppStore from "@/store";
import { CommonStore } from "@/store/common/CommonStore";
import { injectable } from "inversify";
import { getModule } from "vuex-module-decorators";
import { ICommonStoreService } from "./ICommonStoreService";

@injectable()
export class CommonStoreService implements ICommonStoreService {
    commonStore: CommonStore;

    constructor() {
        this.commonStore = getModule(CommonStore, AppStore);
    }

    setNeedInmateListRefresh(needRefresh: boolean): void {
        this.commonStore.SET_NEED_INMATE_LIST_REFRESH(needRefresh);
    }

    get NeedInmateListRefresh() { return this.commonStore.NeedInmateListRefresh }

}