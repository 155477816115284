import { AsyncManageForgotHandler } from "@/components/AsyncComponents";

export const withForgotModuleWrapper = (WrappedComponent: any) => {
  return {
    template: `<ForgotModuleHandler><WrappedComponent></WrappedComponent></ForgotModuleHandler>`,
    components: {
      ForgotModuleHandler: AsyncManageForgotHandler,
      WrappedComponent,
    },
  };
};