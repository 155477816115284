import { NotificationApi } from "@/api/NotificationApi";
import { PaginateList } from "@/models/ListModel";
import {
  NotificationRequest, NotificationResponse
} from "@/models/NotificationModal";
import { injectable } from "inversify";
import { INotificationListService } from "./INotificationListService";

@injectable()
export class NotificationListService implements INotificationListService {
  notificationApi: NotificationApi = new NotificationApi();

  getNotificationList(query:NotificationRequest): Promise<PaginateList<NotificationResponse>> {
    return this.notificationApi.getNotificationList(query);
  }
  getAllNotificationList(query:NotificationRequest): Promise<PaginateList<NotificationResponse>> {
    return this.notificationApi.getAllNotificationList(query)
  }
  updateNotification(notificationId: string): Promise<any> {
    return this.notificationApi.updateNotification(notificationId)
  }
}