import { IManageUserService } from "@/services/user/IManageUserService";
import { IUserListService } from "@/services/user/IUserListService";
import { ManageUserService } from "@/services/user/ManageUserService";
import { UserListService } from "@/services/user/UserListService";
import { Container } from "inversify";
import { IoCTypes } from "./IoCTypes";

export function bindManageUserService(container: Container) {
    container.bind<IManageUserService>(IoCTypes.ManageUserService).to(ManageUserService);
}

export function bindUserListService(container: Container) {
    container.bind<IUserListService>(IoCTypes.UserListService).to(UserListService);
}