import { IoCTypes } from "@/IoC/IoCTypes";
import { bindManageGroupService } from "@/IoC/GroupIoc";
import { IGroupManageService } from "@/services/groups/manage/IGroupManageService";
import { GroupManageHandlerProps } from "./GroupManageHandlerProps"
import { Container } from "inversify";
import {
    computed, ComputedRef, inject
} from "vue";
import {
    Options, Vue
} from "vue-class-component";
import { UsersListResponse } from "@/models/GroupModel";

type TProvider = {
    fetchingGroups: ComputedRef<boolean>
    GroupForm: ComputedRef<any>,
    userList: ComputedRef<any>,
    onSave: () => void,
    isSaved: ComputedRef<boolean>,
    savingGroup: ComputedRef<boolean>
    userlistflag: ComputedRef<boolean>
}

@Options({
    template: `<c-validation-listener v-model="validate"><slot></slot></c-validation-listener>`,
    provide(): TProvider {
        return {
            GroupForm: computed(() => this.GroupForm),
            fetchingGroups: computed(() => this.fetchingGroups),
            onSave: this.onSave,
            isSaved: computed(() => this.isSaved),
            savingGroup: computed(() => this.savingGroup),
            userList: computed(() => this.userList),
            userlistflag: computed(() => this.userlistflag),
        }
    },
    watch: { "GroupForm.userlist"() { this.handleerrror(); } },
})

export default class GroupManageHandler extends Vue.with(GroupManageHandlerProps) {
    validate: () => Promise<{ valid: boolean; errors?: any }> = () => new Promise<{ valid: boolean }>((res) => res({ valid: true }));
    container: Container = new Container();
    GroupManageService!: IGroupManageService;
    userList: any = [];
    groupmembersdeatils: any = [{
        id: "",
        name: ""
    }];
    GroupForm: any = {
        groupname: "",
        userlist: []
    }
    isSaved = false;
    savingGroup = false;
    groupdetailspage = false;
    fetchingGroups = false;
    userlistflag = false;
    groupList: [] = inject("groupList") as [];

    beforeCreate() {
        bindManageGroupService(this.container);
        this.GroupManageService = this.container.get<IGroupManageService>(IoCTypes.GroupManageService);
    }
    created() {
        this.getusersList();
        this.getGroup();

    }
    getusersList() {
        this.userList = [];
        this.userList = this.GroupManageService.getGroupuserlist().then((res) => {
            res.items.forEach((x: UsersListResponse) => {
                x.displayName = x.firstName.concat(" ", x.lastName, " (", x.roleName, ")")
            })
            this.userList = res.items;
        });
    }

    getGroup() {
        if (this.GroupId) {
            const id = { id: this.GroupId }
            this.fetchingGroups = true;
            this.GroupManageService.getGroup(id).then((res) => {
                this.GroupForm.id = res.id;
                this.GroupForm.groupname = res.groupname;
                this.GroupForm.userlist = res.userlist;
            })
                .finally(() => this.fetchingGroups = false);

        }

    }
    handleerrror() {
        if (this.GroupForm.userlist.length < 0) {
            this.userlistflag = true;
        }
        else {
            this.userlistflag = false;
        }
    }

    async onSave() {
        const validationResult = await this.validate()
        if (!validationResult.valid) return;

        if (this.GroupForm.userlist.length !== 0) {
            this.userlistflag = false;
            if (this.GroupForm.id) {

                this.GroupManageService.updategroup(this.GroupForm).then((res: any) => {
                    if (res) {
                        this.$toast.add({
                            severity: "success",
                            summary: "Success",
                            detail: "Updated Successfully",
                            life: 4000
                        });
                        this.isSaved = true;
                        this.savingGroup = false;
                    }
                    return res
                }).catch((err: PromiseRejectedResult) => {
                    console.error("err.reason", err)
                    this.$toast.add({
                        severity: "error",
                        summary: "Failed",
                        detail: err,
                        life: 4000
                    });
                    this.isSaved = true
                })
                    .finally(() => this.isSaved = true)
            }
            else {
                if (this.groupList.find((x: any) => x.users[0].groupName.toLowerCase() === this.GroupForm.groupname.toLowerCase()) === undefined) {
                    this.GroupManageService.addgroup(this.GroupForm).then((res: any) => {
                        if (res) {
                            this.$toast.add({
                                severity: "success",
                                summary: "Success",
                                detail: "Added Successfully",
                                life: 4000
                            });
                            this.isSaved = true;
                            this.savingGroup = false;
                        }
                        return res
                    })
                        .catch((err: PromiseRejectedResult) => {
                            console.error("err.reason", err)
                            this.$toast.add({
                                severity: "error",
                                summary: "Failed",
                                detail: err,
                                life: 4000
                            });
                            this.isSaved = true
                        })
                        .finally(() => this.isSaved = true);
                }
                else {
                    this.$confirm.require({
                        acceptLabel: "Try Again",
                        acceptClass: "p-button p-button-danger",
                        message: JSON.stringify({
                            text: "Group name already exists",
                            type: "INFORM"
                        }),
                        icon: "pi pi-times",
                        accept: () => {
                            //yes
                        },
                    }
                    );
                }
            }
        }
        else {
            this.userlistflag = true;
        }
    }
}