import {
  Options, Vue
} from "vue-class-component";

@Options({
  template: `<slot></slot>`,
  provide() {
    return {
      setInitialNavValue: this.setInitialNavValue,
      setCurrentNavValue: this.setCurrentNavValue,
      isNavValueDirty: this.isNavValueDirty,
      setCanCheckDirtyValue: this.setCanCheckDirtyValue
    }
  }
})
export default class DirtyChecker extends Vue {
  initialNavValue: any;
  currentNavValue: any;
  canCheckDirtyValue = true;
  setCanCheckDirtyValue(canCheck: boolean) {
    this.canCheckDirtyValue = canCheck;
  }
  setInitialNavValue(value: any) {
    this.initialNavValue = JSON.parse(JSON.stringify(value));
  }
  setCurrentNavValue(value: any) {
    this.currentNavValue = value;
  }
  isNavValueDirty() {
    if (this.canCheckDirtyValue && JSON.stringify(this.initialNavValue) !== JSON.stringify(this.currentNavValue)) return true;
    return false
  }
  canNavigate(next: any): boolean {
    if (this.isNavValueDirty()) {
      this.$confirm.require({
        message: JSON.stringify({ text: "Are you sure you want to leave this page? Changes that you made may not be saved" }),
        header: "Leave Page?",
        icon: "pi pi-exclamation-triangle",
        acceptClass: "p-button p-button-danger",
        rejectClass: "p-button p-button-secondary",
        accept: () => {
          next();
          return true;
        },
        reject: () => {
          this.$emit("onReject", false);
          return false;
          // next(false)
        },
      });

      return false
    }

    return true

  }
}
