import { LookupApi } from "@/api/LookupApi";
import { IoCTypes } from "@/IoC/IoCTypes";
import { RemoteOptions } from "@/models/FormBuilderModel";
import { FieldOption } from "@/models/ListModel";
import {
    inject,
    injectable
} from "inversify";
import { IAppConfigStoreService } from "../app/IAppConfigStoreService";
import { ILookupService } from "./ILookpupService";
import { ILookpupStoreService } from "./ILookupStoreService";

@injectable()
export class LookupService implements ILookupService {
    lookupApi: LookupApi = new LookupApi();
    lookupStoreService: ILookpupStoreService;
    appConfigStoreService!:IAppConfigStoreService;

    constructor(@inject(IoCTypes.LookupStoreService) lookupStoreService: ILookpupStoreService,
    @inject(IoCTypes.AppConfigStoreService) AppConfigStoreService: IAppConfigStoreService) {
        this.lookupStoreService = lookupStoreService;
        this.appConfigStoreService=AppConfigStoreService;
    }

    getLookupDetails(options: RemoteOptions): Promise<FieldOption[]> {
        const alterStoreName=options.storeAlterName===undefined? options.url:options.storeAlterName;
        
        if (this.lookupStoreService.lookupDetails[alterStoreName]) {
            return Promise.resolve(this.lookupStoreService.lookupDetails[alterStoreName])
        }
        this.lookupStoreService.setLookupDetailsLoading(alterStoreName, true);
        return this.lookupApi.getLookupDetails(options).then((res: FieldOption[]) => {
            if(options.textReplaceAttr?.featureName && options.textReplaceAttr?.configName){

                const appConfigValue=this.appConfigStoreService.LatestAppConfigTypes.find((x)=> x.configName===options.textReplaceAttr?.configName 
                && x.featureName===options.textReplaceAttr?.featureName);
                if(appConfigValue){
                appConfigValue.configValue=JSON.parse(appConfigValue.configValue)
                
                
                if(appConfigValue.configValue!==null){
                    res.forEach(x => {
                        const value =appConfigValue.configValue.find((y:any) => y.value===x.hiddenValue);
                        if (value){
                        x.text= value.report_text;
                    }
                    });
                }
                }
            }
            this.lookupStoreService.saveLookupDetails(alterStoreName, res);
            this.lookupStoreService.setLookupDetailsLoading(alterStoreName, false)
            return res;
        });
    }
}