import { Container } from "inversify";
import { IoCTypes } from "./IoCTypes";
import { IAppService } from "@/services/app/IAppService";
import { AppService } from "@/services/app/AppService";
import { bindLoginService } from "./LoginIoC";
import { bindProfileService } from "./ProfileIoC";
import{ bindAppConfigService } from "./AppConfigIOC";

export function bindAppService(container: Container) {
    container.bind<IAppService>(IoCTypes.AppService).to(AppService);
    bindLoginService(container);
    bindProfileService(container);
    bindAppConfigService(container);
}