import { injectable } from "inversify";
import { getModule } from "vuex-module-decorators";
import { IAppConfigStoreService } from "./IAppConfigStoreService";
import AppConfigStore from "@/store/appConfig/AppConfigStore";
import AppStore from "@/store";
import { AppConfig } from "@/models/AppConfig";

@injectable()
export class AppConfigStoreService implements IAppConfigStoreService {
    appConfigStore!: AppConfigStore

    get LatestAppConfigTypes(): AppConfig[] {
        return this.appConfigStore.AppConfigTypes;
    }
    constructor() {
        this.appConfigStore = getModule(AppConfigStore, AppStore);
    }

    saveLatestAppConfigTypes(appConfigTypes: AppConfig[]):void {
        this.appConfigStore.SAVE_APP_CONFIG_TYPES(appConfigTypes);

    }

}
