import { IFileUploadComponentService } from "./IFileUploadComponentService";
import { injectable } from "inversify";
import { inject } from "vue";

@injectable()
export class FileUploadComponentService implements IFileUploadComponentService {
  attachmentData: any = inject("attachmentData");

  saveAttachments(files: FileList, category: string, subCategory: string) {
    Array.from(files).forEach((x: File) => {
      this.attachmentData.attachmentsMetadata.push({
        category: category,
        subCategory: subCategory,
        fileName: x.name,
        fileType: x.type,
        file: URL.createObjectURL(x),
        comments: "",
        isActive: true
      })
      this.attachmentData.attachments.push(x);
    })
  }
} 