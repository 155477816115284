import { AsyncFormBuilderHandler } from "@/components/AsyncComponents"
import { withFormExecutionWrapper } from "./FormExecutionWrapper"

export const withFormWrapper = (WrappedComponent: any) => {
    return withFormExecutionWrapper({
        template: `<Handler><WrappedComponent></WrappedComponent></Handler>`,
        components: {
            WrappedComponent,
            Handler: AsyncFormBuilderHandler
        }
    })
}