import { createStore } from "vuex"
import CaseStore from "./case/CaseStore";
import FormBuilderStore from "./form-builder/FormBuilderStore"
import ProfileStore from "./profile/ProfileStore"
import ClaimStore from "./claim/ClaimStore"
import { LookupStore } from "./lookup/LookupStore";
import { CommonStore } from "./common/CommonStore";
import AppConfigStore from "./appConfig/AppConfigStore";

const modules = {
    formBuilder: FormBuilderStore,
    profile: ProfileStore,
    case: CaseStore,
    claim: ClaimStore,
    lookup: LookupStore,
    common: CommonStore,
    appConfig : AppConfigStore
}

const AppStore = createStore({ modules });
export default AppStore;
