import { HttpUtil } from "@/lib/HttpUtil";
import { 
  ForgotRequest,
  ForgotResponse 
} from "@/models/Forgotpassword";

export class Forgotapi {
  
  httpUtil: HttpUtil = new HttpUtil("IDP");
  
  validateForgot(payload: ForgotRequest): Promise<ForgotResponse> {
    const data = { "username": payload.username };
    return this.httpUtil.postForm<ForgotResponse>("/api/v1/Login/ForgotPassword", data);
}
}