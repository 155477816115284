import { execution } from "@/models/FormBuilderModel";
import {
    ValueSource,
    ValueTypes
} from "@/shared/FormBuilderShared";
import { ICustomFunction } from "../ICustomFunction";

export class Find {
    private exec: execution;
    private func: ICustomFunction;

    constructor(exec: execution, func: ICustomFunction) {
        this.exec = exec;
        this.func = func;
    }

    execute() {
        const arr: any[] = this.func.getValue({
            type: ValueTypes.FIELD,
            value: this.exec.field,
            isFixed: true,
            source: this.exec.source
        })
        if (arr && arr.length) {
            return arr.find((x) => {
                this.func.setValue(this.exec.subField || "x", x, ValueSource.LOCAL_VARIABLE);
                if (this.exec.comparisions) return this.func.compare(this.exec.comparisions);
                return true;
            });
        }
    }
}