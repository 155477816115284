import { IoCTypes } from "@/IoC/IoCTypes";
import { bindRoleListService } from "@/IoC/RoleIoC";
import { FieldOption } from "@/models/ListModel";
import { ILookpupStoreService } from "@/services/lookup/ILookupStoreService";
import { IRoleListService } from "@/services/role/IRoleListService";
import {
    computed,
    ComputedRef
} from "vue";
import { Options } from "vue-class-component";
import BaseHandler from "../BaseHandler";

type TProvider = {
    roleList: ComputedRef<FieldOption[]>,
    roleListLoading: ComputedRef<boolean>
}

@Options({
    template: `<slot :roleList="roleList" :roleListLoading="roleListLoading"></slot>`,
    provide(): TProvider {
        return {
            roleList: computed(() => this.roleList),
            roleListLoading: computed(() => this.roleListLoading)
        }
    }
})
export default class RoleLookupHandler extends BaseHandler {
    roleListService!: IRoleListService;
    lookupStoreService!: ILookpupStoreService;

    beforeCreate() {
        bindRoleListService(this.IoC);
        this.roleListService = this.IoC.get<IRoleListService>(IoCTypes.RoleListService);
        this.lookupStoreService = this.IoC.get<ILookpupStoreService>(IoCTypes.LookupStoreService);
    }

    created() {
        this.roleListService.getRoleList();
    }

    get roleList(): FieldOption[] {
        return this.lookupStoreService.lookupDetails.roleList || []
    }

    get roleListLoading(): boolean {
        return this.lookupStoreService.lookupDetailsLoading.roleList || false;
    }
}