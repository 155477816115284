import { IoCTypes } from "@/IoC/IoCTypes";
import { bindNotification } from "@/IoC/NotificationListIoc";
import { StoreUtil } from "@/lib/StoreUtil";
import { INotificationListService } from "@/services/notifications/INotificationListService";
import AppStore from "@/store";
import ClaimStore from "@/store/claim/ClaimStore";
import { Container } from "inversify";
import {
    computed,
    ComputedRef
} from "vue";
import {
    Options, Vue
} from "vue-class-component";
import { getModule } from "vuex-module-decorators";

type TProvider = {
    notificationList: ComputedRef<any>,
    notificationActiveTab: ComputedRef<number>,
    notificationForm: ComputedRef<any>,
    getNotificationList: () => void,
    handleTabChange: () => void,
    alertList: ComputedRef<any>,
    isFetchingNotification: ComputedRef<boolean>
    updateNotification: () => void,
    isUpdatingNotification: ComputedRef<boolean>,
    isReceivedAllData: ComputedRef<boolean>,
    getNotificationAndAlertData: () => void,
    totalAlertsCount: ComputedRef<number>,
    totalNotificationCount: ComputedRef<number>
}

@Options({
    template: `<slot></slot>`,
    provide(): TProvider {
        return {
            notificationList: computed(() => this.notificationList),
            notificationActiveTab: computed(() => this.notificationActiveTab),
            getNotificationList: this.getNotificationList,
            handleTabChange: this.handleTabChange,
            notificationForm: this.notificationForm,
            alertList: computed(() => this.alertList),
            isFetchingNotification: computed(() => this.isFetchingNotification),
            updateNotification: this.updateNotification,
            isUpdatingNotification: computed(() => this.isUpdatingNotification),
            isReceivedAllData: computed(() => this.isReceivedAllData),
            getNotificationAndAlertData: this.getNotificationAndAlertData,
            totalAlertsCount: computed(() => this.totalAlertsCount),
            totalNotificationCount: computed(() => this.totalNotificationCount)
        }
    }
})
export default class NotificationHandler extends Vue {
    container: Container = new Container();
    notificationListService!: INotificationListService;
    notificationList: any = [];
    alertList: any = [];
    notificationActiveTab = 0;
    isFetchingNotification = false;
    isUpdatingNotification = false;
    notificationForm: any = {
        PMessageType: "",
        PFilterBy: "",
        PgIndex: 0,
        PgSize: 5,
        ftype: "ALL"
    }
    storeUtil: StoreUtil = new StoreUtil();
    claimStore!: ClaimStore;
    isReceivedAllData = false;
    isAlertCompleted = false;
    totalAlertsCount = 0;
    totalNotificationCount = 0;

    beforeCreate() {
        bindNotification(this.container);
        this.notificationListService = this.container.get<INotificationListService>(IoCTypes.NotificationListService);
    }
    created() {
        this.claimStore = getModule(ClaimStore, AppStore);
        this.getNotificationAndAlertData();
    }
    getNotificationList(type: string, pageIndex: number) {
        this.isFetchingNotification = true;

        const userId = this.storeUtil.getItem("userId");
        const payload = {
            PgSize: 15,
            PgIndex: this.notificationActiveTab === 0 ? 0 : pageIndex,
            userid: userId ? userId : "",
            featureType: this.notificationForm.ftype ? this.notificationForm.ftype : "ALL",
            datefilter: "ALL",
            isActive: type === "ALL" ? "ALL" : "ACTIVE",
            featureNo: "",
            notificationType: type
        }
        this.isReceivedAllData = false;
        return this.notificationListService.getNotificationList(payload).then((res) => {
            this.isFetchingNotification = false
            if (res.items && res.items.length > 0) {
                //this.setNotificationAndAlertData(res, type, pageIndex, payload);
                if (type === "ALL") {
                    this.notificationList = res.items;
                    this.totalNotificationCount = res.count;
                }
                if (type === "ALERT") {

                    if (pageIndex === 0) {
                        this.alertList = res.items.filter(((x: any) => x.notificationType === "ALERT" && x.isActive === true));
                        this.totalAlertsCount = res.count
                    }
                    else {
                        const alertRes = res.items.filter(((x: any) => x.notificationType === "ALERT" && x.isActive === true))
                        this.alertList = [...this.alertList, ...alertRes];
                        this.totalAlertsCount = res.count;
                    }
                }
                if (res.items.length < payload.PgSize && type === "ALERT") {
                    if (res.items.length < payload.PgSize) {
                        this.isReceivedAllData = true
                    }
                }
            }
            else {
                // this.alertList = [];
            }
            return "";
        })
            .finally(() => this.isFetchingNotification = false)
    }



    updateNotification(data: any) {
        this.isUpdatingNotification = true;
        this.notificationListService.updateNotification(data.notificationId).then((res: any) => {
            this.isUpdatingNotification = false;
            if (res) {
                if (data.notificationType === "ALERT") {
                    this.alertList = this.alertList.length > 0 && this.alertList.filter((x: any) => x.notificationId !== data.notificationId);
                    const seletedindex = this.notificationList.findIndex((x: any) => x.notificationId === data.notificationId);
                    if (seletedindex > -1) {
                        this.notificationList[seletedindex].isActive = false;
                    }
                    this.isAlertCompleted = true;
                    this.totalAlertsCount = this.totalAlertsCount - 1;
                }
                this.$toast.add({
                    severity: "success",
                    summary: "Success",
                    detail: "Completed",
                    life: 3000
                });
            }
        })
            .finally(() => this.isFetchingNotification = false)
    }

    handleTabChange(value: any) {
        this.notificationActiveTab = value.index;
        if (this.isAlertCompleted) {
            this.notificationList = [];
            this.getNotificationList("ALL", 0);
            this.isAlertCompleted = false;
        }
    }
    getNotificationAndAlertData() {
        this.notificationActiveTab = 0;
        this.notificationList = [];
        this.alertList = [];
        this.totalNotificationCount = 0;
        this.totalAlertsCount = 0;
        this.getNotificationList("ALL", 0);
        this.getNotificationList("ALERT", 0);
    }
}


