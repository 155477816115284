import { AsyncManageResetHandler } from "@/components/AsyncComponents";

export const withResetModuleWrapper = (WrappedComponent: any) => {
  return {
    template: `<ResetModuleHandler><WrappedComponent></WrappedComponent></ResetModuleHandler>`,
    components: {
      ResetModuleHandler: AsyncManageResetHandler,
      WrappedComponent,
    },
  };
};