import { execution } from "@/models/FormBuilderModel";
import {
    ValueSource,
    ValueTypes
} from "@/shared/FormBuilderShared";
import { ICustomFunction } from "../ICustomFunction";

export class ForEach {
    private exec: execution;
    private func: ICustomFunction;

    constructor(exec: execution, func: ICustomFunction) {
        this.exec = exec;
        this.func = func;
    }

    execute() {
        const arr: any[] = this.func.getValue({
            type: ValueTypes.FIELD,
            value: this.exec.field,
            isFixed: true,
            source: this.exec.source
        })
        if (arr && arr.length) {
            let index = 0
            for (const x of arr) {
                this.func.setValue(this.exec.subField || "x", x, ValueSource.LOCAL_VARIABLE);
                this.func.setValue("index", index, ValueSource.LOCAL_VARIABLE);
                if (this.exec.executions) this.func.run(this.exec.executions);
                index++;
            }
        }
    }
}