import {
    AxiosError,
    AxiosResponse
} from "axios";

export function handleResponse(response: AxiosResponse) {
    if (
        response.status === 200 ||
        response.status === 201 ||
        response.status === 202 ||
        response.status === 204
    ) {
        return response.data;
    }
    if (response.status === 400) {
        // So, a server-side validation error occurred.
        const error = response.statusText;
        throw new Error(error);
    }
    throw new Error("Network response was not ok.");
}

// In a real app, would likely call an error logging service.
export function handleError(error: AxiosError) {
    // eslint-disable-next-line no-console
    let errormsg;
    if (error && error.message) errormsg = error.message
    if (error && error.response && error.response.data) {
        const err = error.response.data;
        if (err.message) errormsg = err.message;
        else if (err.title) errormsg = err.title;
        else if (err.error_detail) errormsg = err.error_detail;
        else if (err.error) errormsg = err.error;
        else errormsg = err.error_description;
    }
    throw errormsg || "Something went wrong. Try again.";
}

export async function handleBlobResponse(v: Response) {
    const blob = await v.blob();
    return Promise.resolve({
        resp: v,
        blob
    });
}