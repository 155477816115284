import {
    Options,
    Vue
} from "vue-class-component";
import {
    InitForgotRequest,
    ForgotRequest
} from "@/models/Forgotpassword";
import { IForgotService } from "@/services/forgot/IForgotService";
import { Container } from "inversify";
import { bindForgotService } from "@/IoC/ForgotIoC";
import { IoCTypes } from "@/IoC/IoCTypes";
import {
    ComputedRef, computed
} from "vue";

type TProvider = {
    forgotpassword: typeof Function;
    forgot: ForgotRequest;
    forgotPage: any;
    failPopup: boolean;
    successPopup: boolean;
    isForgotPasswordLoading: ComputedRef<boolean>
};

@Options({
    template: `<slot></slot>`,
    provide(): TProvider {
        return {
            forgotpassword: this.forgotpassword,
            forgot: this.forgot,
            forgotPage: this.forgotPage,
            failPopup: this.failPopup,
            successPopup: this.successPopup,
            isForgotPasswordLoading: computed(() => this.isForgotPasswordLoading),
        };
    },
})

export default class ForgotHandler extends Vue {
    forgotservice!: IForgotService;
    forgot: ForgotRequest = { ...InitForgotRequest };
    forgotPage: any = {};
    container: Container = new Container();
    failPopup: any = { value: false };
    successPopup: any = { value: false };
    isForgotPasswordLoading = false;
    beforeCreate() {
        bindForgotService(this.container)
        this.forgotservice = this.container.get<IForgotService>(IoCTypes.ForgotService)
    }
    async forgotpassword() {
        if (!(await this.forgotPage.validateForm()).valid) return;
        this.isForgotPasswordLoading = true;

        this.forgotservice.validateForgot(this.forgot).then(() => {
            this.$confirm.require({
                message: JSON.stringify({
                    type : "INFORM",
                    status: "SUCCESS",
                    text: "Please check your email to reset your password"
                }),
                header: "Confirmation",
                acceptLabel: "Ok",
                acceptClass: "p-button p-button-success",
                icon: "pi pi-check",
                accept: () => {
                    window.location.pathname = "/login";
                }
            });
            this.isForgotPasswordLoading = false;

            // this.$router.push(this.$route + String("/"));
        }).catch((err) => {
            this.$confirm.require({
                message: JSON.stringify({ 
                    text: "There is no account registered to this e-mail address",
                    type : "INFORM",
                 }),
                header: "Warning!",
                acceptLabel: "Try Again",
                acceptClass: "p-button p-button-danger",
                icon: "pi pi-exclamation-triangle",
            });
            console.error(err);
            this.isForgotPasswordLoading = false;
        })
            .finally(() => this.isForgotPasswordLoading = false
            )
    }
}