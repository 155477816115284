import { formateDate } from "./FormatDate";
import { formateDateTime } from "./FormatDateTime";
import { formateMultidDate } from "./FormatMultiDate";
import { formatTime } from "./FormatTime"
import { formatTimezonedDateTime } from "./FormatTimezoneDateTime";

export default {
  formateDate,
  formatTime,
  formateDateTime,
  formatTimezonedDateTime,
  formateMultidDate
}