import { FormBuilder } from "@/components/GlobalComponents";
import { withCaseListWrapper } from "@/handlers/case/case-list/CaseListWrapper";
import { withCaseModuleWrapper } from "@/handlers/case/CaseModuleWrapper";
import { Permissions } from "@/shared/ClaimsShared";
import { defineAsyncComponent } from "vue";
import { RouteRecordRaw } from "vue-router";

const routes: Array<RouteRecordRaw> = [{
  path: "cases",
  component: withCaseModuleWrapper(
    defineAsyncComponent(() => import("@/views/case/CaseModulePage.vue"))
  ),
  redirect: "/cases/search",
  children: [{
    path: "search",
    component: withCaseListWrapper(
      defineAsyncComponent(
        () => import("@/views/case/case-list/CaseListPage.vue")
      )
    ),
    meta: { permission: Permissions.caseSearch },
  },
  {
    path: "add/:type",
    component: FormBuilder,
    meta: { permission: Permissions.createCase },
  },
  {
    path: "edit/:id",
    component: FormBuilder,
    meta: { permission: Permissions.readCase },
  },
  {
    path: "activity",
    component: withCaseListWrapper(
      defineAsyncComponent(
        () => import("@/views/case/case-list/case-activity/CaseActivityList.vue")
      )
    ),
    meta: { permission: Permissions.caseSearch },
  }]
}];

export default routes;
