import { AsyncGroupListHandler } from "@/components/AsyncComponents";

export const withGroupListWrapper = (WrappedComponent: any) => {
    return {
        template: `<Handler><WrappedComponent></WrappedComponent></Handler>`,
        components: {
            WrappedComponent,
            Handler: AsyncGroupListHandler,
        }
    }
}