import {
    Options,
    Vue
} from "vue-class-component";
import {
    computed,
    ComputedRef
} from "vue";
import {
    InitLoginRequest,
    LoginRequest
} from "@/models/Login";
import { ILoginService } from "@/services/login/ILoginService";
import { Container } from "inversify";
import { bindLoginService } from "@/IoC/LoginIoC";
import { IoCTypes } from "@/IoC/IoCTypes";
import { loadJsonAsset } from "@/lib/Util";
import {
    DomainConfig,
    DomainConfigModel
} from "@/models/DomainConfig";

type TProvider = {
    loginCheck: typeof Function;
    loginPage: any;
    login: ComputedRef<LoginRequest>;
    failPopup: boolean;
    isLoginLoading: ComputedRef<boolean>;
    domainData: ComputedRef<DomainConfigModel>
};

@Options({
    template: `<slot></slot>`,
    provide(): TProvider {
        return {
            loginCheck: this.loginCheck,
            login: computed(() => this.login),
            loginPage: this.loginPage,
            failPopup: this.failPopup,
            isLoginLoading: computed(() => this.isLoginLoading),
            domainData: computed(() => this.domainData)
        };
    },
})

export default class LoginHandler extends Vue {
    loginservice!: ILoginService;
    loginPage: any = { hasAgreed: false };
    login: LoginRequest = { ...InitLoginRequest };
    container: Container = new Container();
    failPopup: any = { value: false };
    isLoginLoading = false;
    domainData: DomainConfigModel = {} as DomainConfigModel;

    beforeCreate() {
        bindLoginService(this.container)
        this.loginservice = this.container.get<ILoginService>(IoCTypes.LoginService)
    }


    created() {
        loadJsonAsset("domain-config.json").then((resp) => {
            resp.json().then((r) => {
                const d: DomainConfig = r;
                const hasDomain = d.domains.filter(f => f.name.indexOf(window.location.host) > -1);
                if (hasDomain.length > 0 && hasDomain[0].use_default) {
                    this.domainData = d.domains.filter(f => f.name.indexOf("default") > -1)[0];
                }
                else if (hasDomain.length > 0) {
                    this.domainData = hasDomain[0];
                }
                else {
                    this.domainData = d.domains.filter(f => f.name.indexOf("default") > -1)[0];
                }
            });
        })
    }
    async loginCheck() {
        if (!(await this.loginPage.validateLogin()).valid) return;
        if (!this.loginPage.hasAgreed && (this.domainData?.disclaimer || "") !== "") return;
        this.isLoginLoading = true;

        this.loginservice.validateLogin(this.login).then((res) => {           
            const pathname = this.$route.query.returnUrl ? this.$route.query.returnUrl.toString() : "";
            if(res.resetToken !== null && res.resetToken !== undefined){
                
                window.location.href = res.resetToken
            }
            else{
            window.location.href = window.location.origin + pathname;
            }
            this.isLoginLoading = false;
        }).catch((err) => {
            this.$confirm.require(
                {
                    message: JSON.stringify({
                        text: "Your email or password is incorrect. Please try again",
                        type: "INFORM"
                    }),
                    header: "Failed",
                    acceptLabel: "Try Again",
                    rejectLabel: "Cancel",
                    icon: "pi pi-times",
                    acceptClass: "p-button p-button-danger",
                    rejectClass: "p-button p-button-secondary",
                });
            console.error(err);
            this.isLoginLoading = false;
        })
            .finally(() => this.isLoginLoading = false)
    }
}