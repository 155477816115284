import { inject } from "vue";
import {
  Options,
  Vue
} from "vue-class-component";
import { BaseContainerProps } from "./BaseContainerProps";

@Options({
  inheritAttrs: false,
  provide() { return { containerValue: this.containerValue } }
})
export default class BaseContainer extends Vue.with(BaseContainerProps) {
  IsReadOnly: boolean = inject("IsReadOnly") as boolean;
  containerState: any = inject("containerState") as any;

  get containerValue() {
    return this.modelValue;
  }
}