import { IAllegationModuleService } from "./IAllegationModuleService";
import { AllegationsApi } from "@/api/AllegationsApi";
import { injectable } from "inversify";
import { ListQuery } from "@/models/ListQuery";
import { PaginateList } from "@/models/ListModel";
import {
  AllegationSearchQuery,
  AllegationSearchResponse,
  AllegationsResponse,
  CaseNoSearch,
  CaseNoSearchResponse
} from "@/models/AllegationsModel";

@injectable()
export class AllegationModuleService implements IAllegationModuleService {
  allegationApi: AllegationsApi = new AllegationsApi();

  getAllAllegations(searchQuery: ListQuery): Promise<PaginateList<AllegationsResponse>> {
    return this.allegationApi.getAllAllegations(searchQuery);
  }

  postNewAllegation(allegation: AllegationsResponse): Promise<boolean> {
    allegation.isActive = true;
    allegation.allegationStatus = "New"
    return this.allegationApi.postNewAllegation(allegation);
  }

  updateAllegation(allegation: AllegationsResponse): Promise<boolean> {
    return this.allegationApi.updateAllegation(allegation)
  }

  getFilteredAllegation(query: AllegationSearchQuery): Promise<PaginateList<AllegationSearchResponse>> {
    return this.allegationApi.getSearchAllegation(query);
  }
  getCaseNoList(searchQuery: CaseNoSearch): Promise<PaginateList<CaseNoSearchResponse>> {
    return this.allegationApi.getCaseNoSearch(searchQuery);
  }

  linkCaseAllegation(caseId: string, allegationId: string): Promise<boolean> {
    return this.allegationApi.linkCaseAllegation(caseId, allegationId);
  }
}