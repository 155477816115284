import { AsyncDashboardCaseStatisticsListHandler } from "@/components/AsyncComponents";

export const withDashboardCaseStatisticsListWrapper = (WrappedComponent: any) => {
    return {
        template: `<DashboardCaseStatisticsListHandler><WrappedComponent></WrappedComponent></DashboardCaseStatisticsListHandler>`,
        components: {
            WrappedComponent,
            DashboardCaseStatisticsListHandler: AsyncDashboardCaseStatisticsListHandler
        }
    }
}