import "reflect-metadata"
import { createApp } from "vue";
import App from "./App.vue";
import { Helpers } from "./lib/Util";
import GlobalComponents from "./components/GlobalComponents";
import PrimeComponents from "./components/PrimeComponents";
import router from "./router";
import store from "./store";
import PrimeVue from "primevue/config";
import "primevue/resources/primevue.min.css";
import "primevue/resources/themes/md-light-indigo/theme.css";
import "primeicons/primeicons.css";
import "@material-icons/font/css/all.css"
import "primeflex/primeflex.min.css";
import "../public/assets/css/skins/theme-default.css"
import "@/components/validation/ValidationRules"
import { withAppHandler } from "@/handlers/AppHandlerWrapper";
import ConfirmationService from "primevue/confirmationservice";
import filters from "./components/filters";
import PermissionDirective from "./components/directives/PermissionDirective";
import ToastService from "primevue/toastservice";
import Tooltip from "primevue/tooltip";
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
//@ts-ignore
import excel from "vue-excel-export";
import BadgeDirective from "primevue/badgedirective";

Helpers.preloadConfigurations();

declare global { interface Window { MonacoEnvironment: any; } }
self.MonacoEnvironment = { getWorkerUrl: (_moduleId: any, _label: any) => { return "./editor.worker.bundle.js"; } };

const app = createApp(withAppHandler(App));

app.config.globalProperties.$filters = filters;
app.directive("if-permission", PermissionDirective);
app.directive("badge", BadgeDirective);
app.directive("tooltip", Tooltip);

app.use(store)
    .use(PrimeVue)
    .use(router)
    .use(ConfirmationService)
    .use(ToastService)
    .use(GlobalComponents.install)
    .use(PrimeComponents.install)
    .use(excel).mount("#app");