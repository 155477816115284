import { execution } from "@/models/FormBuilderModel";
import {
    ValueSource,
    ValueTypes
} from "@/shared/FormBuilderShared";
import { ICustomFunction } from "../ICustomFunction";

export class SpreadOperator {
    private exec: execution;
    private func: ICustomFunction;

    constructor(exec: execution, func: ICustomFunction) {
        this.exec = exec;
        this.func = func;
    }

    execute() {
        let obj = {};
        let arr: any[] = [];
        const isArray: boolean = this.func.getValue({
            type: ValueTypes.FIELD,
            value: "isArraySpread",
            isFixed: true,
            source: ValueSource.LOCAL_VARIABLE
        });
        if (this.exec.parameters) {
            for (const param of this.exec.parameters) {
                const val = this.func.getValue(param);
                if (val) {
                    if (isArray) arr = [...arr, ...val]
                    else obj = {
                        ...obj,
                        ...val
                    }
                }
            }
        }
        this.func.setValue("isArraySpread", false, ValueSource.LOCAL_VARIABLE)
        if (isArray) return arr;
        return obj;
    }
}