import { AsyncManageUserHandler } from "@/components/AsyncComponents"

export const withManageUser = (WrappedComponent: any) => {
    return {
        template: `<Handler><WrappedComponent></WrappedComponent></Handler>`,
        components: {
            WrappedComponent,
            Handler: AsyncManageUserHandler
        },
    }
}