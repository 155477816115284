import { withCaseListWrapper } from "@/handlers/case/case-list/CaseListWrapper";
import { withCaseModuleWrapper } from "@/handlers/case/CaseModuleWrapper";
import { defineAsyncComponent } from "vue";
import { RouteRecordRaw } from "vue-router";
import { Permissions } from "@/shared/ClaimsShared";

const routes: Array<RouteRecordRaw> = [{
    path: "/welcome",
    component: () => import("@/views/welcome/WelcomePage.vue")

},
{
    path: "workspace",
    component: withCaseModuleWrapper(
        defineAsyncComponent(() => import("@/views/case/case-list/case-activity/Workspace.vue"))
    ),
    children: [{
        path: "",
        component: withCaseListWrapper(
            defineAsyncComponent(
                () => import("@/views/case/case-list/case-activity/CaseActivityTable.vue")
            )
        ),
        meta: { permission: Permissions.workspaceOnly },
    }]
}];

export default routes;
