import { IoCTypes } from "@/IoC/IoCTypes";
import { bindUserListService } from "@/IoC/UserIoC";
import { PaginateList } from "@/models/ListModel";
import { ListQuery } from "@/models/ListQuery";
import {
    UserListResponse,
    UserSearchRequest
} from "@/models/UserModel";
import { IUserListService } from "@/services/user/IUserListService";
import {
    computed,
    ComputedRef
} from "vue";
import { Options } from "vue-class-component";
import ListHandler from "../common/list/ListHandler";


type TProvider = {
    setCanLoadList: (canLoad: boolean) => void;
    canLoadList: ComputedRef<boolean>;
    userList: ComputedRef<UserListResponse[]>;
    selectedItems: ComputedRef<string[]>;
    handleItemSelected: () => void;
    handlePageChange: () => void;
    listQuery: ComputedRef<ListQuery>;
    listDetails: ComputedRef<any>,
    loadList: () => void,
    refreshList: () => void,
    searchRequest: ComputedRef<UserSearchRequest>,
    clearSearch: () => void
}

@Options({
    template: `<slot></slot>`,
    provide(): TProvider {
        return {
            userList: computed(() => this.userList),
            selectedItems: computed(() => this.selectedItems),
            canLoadList: computed(() => this.canLoadList),
            listDetails: computed(() => this.listDetails),
            handleItemSelected: this.handleItemSelected,
            handlePageChange: this.handlePageChange,
            listQuery: computed(() => this.listQuery),
            loadList: this.loadList,
            refreshList: this.refreshList,
            setCanLoadList: this.setCanLoadList,
            searchRequest: computed(() => this.searchRequest),
            clearSearch: this.clearSearch
        }
    },
    watch: { userList() { this.selectedItems = []; } }
})
export default class UserModuleHandler extends ListHandler<UserListResponse> {

    canLoadList = true;
    selectedItems: string[] = [];
    userListService!: IUserListService
    searchRequest: UserSearchRequest = {
        facility: "",
        role: "",
        name: "",
        status: 1
    }

    beforeCreate() {
        bindUserListService(this.IoC);
        this.userListService = this.IoC.get<IUserListService>(IoCTypes.UserListService);
    }

    get userList(): UserListResponse[] {
        return this.pages[this.listQuery.page];
    }
    clearSearch() {
        this.searchRequest = {
            facility: "",
            role: "",
            name: "",
            status: 0
        }
    }
    getList(searchQuery: ListQuery): Promise<PaginateList<UserListResponse>> {
        const payload = {
            facility: this.searchRequest.facility,
            role: this.searchRequest.role,
            page: searchQuery.page,
            size: searchQuery.size,
            searchText: this.searchRequest.name,
            filterText: "",
            status: this.searchRequest.status
        }
        return this.userListService.getUsers(payload);
    }

    setCanLoadList(canLoad: boolean) {
        this.selectedItems = [];
        this.canLoadList = canLoad;
    }

    handleItemSelected(selected: boolean, id: string) {
        if (selected) {
            this.selectedItems.push(id)
        }
        else {
            this.selectedItems = this.selectedItems.filter(x => x !== id)
        }
    }

}   