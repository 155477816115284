import { HttpUtil } from "@/lib/HttpUtil";
import {
    InmateListResponse,
    inmateQuestionnaireResponse,
    InmateRequest, InmateResponse
} from "@/models/InmateModel";
import { PaginateList } from "@/models/ListModel";
import { ListQuery } from "@/models/ListQuery";
import qs from "qs";

export class InmateApi {
    httpUtil = new HttpUtil();

    getInmateList(query: ListQuery): Promise<PaginateList<InmateListResponse>> {
        return this.httpUtil.get(`inmate/All?${qs.stringify(query)}`)
    }

    getInmateQuestionnaire(inmateId: string) {
        return this.httpUtil.get<inmateQuestionnaireResponse>("inmate/QuestionnaireList", { inmateId })
    }

    getInmate(id: string) {
        return this.httpUtil.get<InmateResponse>("inmate", { id });
    }

    addInmate(payload: InmateRequest) {
        return this.httpUtil.post<string>("inmate", payload);
    }

    updateInmate(payload: InmateRequest) {
        return this.httpUtil.put("inmate", payload, { id: payload.id });
    }
    getInmateId(featureName: any) {
        return this.httpUtil.get("common/CurrentNumber", featureName)
    }

    getInmateDetails(searchText: string, searchType: string): Promise<InmateResponse[]> {
        return this.httpUtil.get<InmateResponse[]>(`inmate/list?searchText=${searchText}&type=${searchType}`, { CancelToken: "InmateApi.getInmateDetails" });
    }
}