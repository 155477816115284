import { withGroupListWrapper } from "@/handlers/group/GroupListWrapper";
import { defineAsyncComponent } from "vue";
import { RouteRecordRaw } from "vue-router";

const routes: Array<RouteRecordRaw> = [{
  path: "/groups",
  component: withGroupListWrapper(
    defineAsyncComponent(() => import("@/views/group/GroupModulePage.vue"))
  ),
}];
export default routes;
