import { GroupApi } from "@/api/GroupApi";
import { 
    CreateGroupRequest,
    UpdateGroupRequest,
    UsersListResponse,
    GroupRequest,
    GroupResponse
} from "@/models/GroupModel";
import { PaginateList } from "@/models/ListModel";
import { injectable } from "inversify";
import { IGroupManageService } from "./IGroupManageService";
import { GroupActionResponse } from "@/shared/GroupShared";

@injectable()
export class GroupManageService implements IGroupManageService {
    Groupapi = new GroupApi();

    addgroup(request:CreateGroupRequest):Promise<GroupActionResponse>{
        return this.Groupapi.addGroup(request)
    }
    updategroup(request:UpdateGroupRequest):Promise<GroupActionResponse>{
        return this.Groupapi.updateGroup(request)
    }
    getGroupuserlist():Promise<PaginateList<UsersListResponse>>{
        return this.Groupapi.getGroupuserlist()
    }
    getGroup(id:string): Promise<GroupRequest>{
        return this.Groupapi.getGroup(id).then((res:GroupResponse)=>{
            const result:GroupRequest={
                id:res.id,
                groupname:res.groupname,
                userlist:res.userlist && res.userlist.map(x=>x.id)
            }
            return result
        })
    }
}