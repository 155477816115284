import { FormExecutionService } from "@/services/form-builder/form-execution/FormExecutionsService";
import { IFormExecutionService } from "@/services/form-builder/form-execution/IFormExecutionService";
import { FormBuilderService } from "@/services/form-builder/FormBuilderService";
import { FormBuilderStoreService } from "@/services/form-builder/FormBuilderStoreService";
import { IFormBuilderService } from "@/services/form-builder/IFormBuilderService";
import { IFormBuilderStoreService } from "@/services/form-builder/IFormBuilderStoreService";
import { Container } from "inversify";
import { IoCTypes } from "./IoCTypes";
import { bindLookupService } from "./LookupIoC";

export function bindFormBuilderStoreService(container: Container) {
    container.bind<IFormBuilderStoreService>(IoCTypes.FormBuilderStoreService).to(FormBuilderStoreService)
}

export function bindFormBuilderService(container: Container) {
    container.bind<IFormBuilderService>(IoCTypes.FormBuilderService).to(FormBuilderService);
    bindFormBuilderStoreService(container);
    bindLookupService(container);
}

export function bindFormExecutionService(container: Container) {
    container.bind<IFormExecutionService>(IoCTypes.FormExecutionService).to(FormExecutionService);
    bindFormBuilderStoreService(container);
    bindLookupService(container);
}

