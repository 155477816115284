const prefix = "prea_";

export class StoreUtil {
    storage: Storage;
    constructor(storage?: string) {
        if (storage === "local") this.storage = localStorage;
        else this.storage = sessionStorage;
    }
    setItem(field: string, value: any) {
        const val = this.stringifyJson(value) || value;
        this.storage.setItem(this.getField(field), btoa(val));
    }
    getItem(field: string): any {
        const item = this.storage.getItem(this.getField(field));
        if (item) {
            const val = atob(item);
            return this.parseJson(val) || val;
        }
    }

    private getField(field: string) {
        return String(prefix + field).toUpperCase();
    }

    private parseJson(str: string) {
        try {
            return JSON.parse(str);
        }
        catch (e) {
            return false;
        }
    }

    private stringifyJson(obj: any) {
        try {
            return JSON.stringify(obj);
        }
        catch (e) {
            return false;
        }
    }
}