import { ListQuery } from "@/models/ListQuery";
import {
  ListDetails,
  ListPage
} from "@/models/ListModel";
import BaseHandler from "@/handlers/BaseHandler";
import { SortOrder } from "@/models/QueryBuilderTokensModel";

export default abstract class ListHandler<T> extends BaseHandler {

  listDetails: ListDetails = {
    perPageOptions: [5, 10, 25, 50],
    count: 0,
    isLoading: false,
  }

  pages: ListPage<T> = {}

  listQuery: ListQuery = {
    searchText: "",
    page: 0,
    size: 10,
    filterText: ""
  }

  abstract getList(searchQuery: ListQuery): any;

  handlePageChange(page: number, rows: number, hasArrowPagination: boolean) {
    if (this.listQuery.size !== rows && !hasArrowPagination) {
      this.refreshListInput();
      this.listQuery.size = rows;
      this.loadList();
      return;
    }
    this.listQuery.size = rows;
    this.listQuery.page = page;
    this.loadList();
  }

  refreshListInput() {
    this.pages = {};
    this.listQuery.sortField = ""
    this.listQuery.page = 0;
    this.listQuery.size = 10;
  }
	sortBy(value: any) {
		this.listQuery.sortField = value.sortField || "";
		this.listQuery.sortOrder = (value.sortOrder === 1 ? SortOrder.ASC : SortOrder.DESC ) || "";
		this.pages = {};
		this.listQuery.page = 0;
		this.loadList();
	}
  refreshList() {
    this.refreshListInput();
    this.loadList();
  }
  removeCachedPages(page: any) {
    for (const i in this.pages) {
      if (page <= i) {
        delete this.pages[i];
      }
    }
  }

  loadList() {    
    if (!this.pages[this.listQuery.page]) {
      this.listDetails.isLoading = true;
      this.getList({
        page: this.listQuery.page,
        size: this.listQuery.size,
        searchText: this.listQuery.searchText,
        filterText: this.listQuery.filterText,
        sortField: this.listQuery.sortField,
        sortOrder : this.listQuery.sortOrder
      })
        .then((res: any) => {
          this.listDetails.isLoading = false;
          this.pages[this.listQuery.page] = res.items;
          this.listDetails.count = res.items &&
            res.items.length > 0 ? res.items[0].totalCount === undefined ? res.count : res.items[0].totalCount : 0;
        });
    }
  }
}
