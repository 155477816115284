import { IGroupListService } from "@/services/groups/IGroupListService";
import { IGroupManageService } from "@/services/groups/manage/IGroupManageService";
import { GroupManageService } from "@/services/groups/manage/GroupManageService";
import { GroupListService } from "@/services/groups/GroupListService";
import { Container } from "inversify";
import { IoCTypes } from "./IoCTypes";

export function bindManageGroupService(container: Container) {
    container.bind<IGroupManageService>(IoCTypes.GroupManageService).to(GroupManageService);
}

export function bindGroupListService(container: Container) {
    container.bind<IGroupListService>(IoCTypes.GroupListService).to(GroupListService);
}