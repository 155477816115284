import {
    actions,
    execution,
    properties,
    style
} from "@/models/FormBuilderModel";
import {
    EventTypes,
    ActionSource,
    IconPosition
} from "@/shared/FormBuilderShared";
import { prop } from "vue-class-component";

export class BaseActionProps implements actions {
    executions?: execution[] = prop({ required: false });
    onEvent?: execution[] = prop({ required: false });
    id?: string | undefined = prop({ required: false });
    name?: string | undefined = prop({ required: false });
    event: EventTypes = prop({ required: true });
    type: ActionSource = prop({ required: true });
    text?: string | undefined = prop({ required: false });
    url?: string | undefined = prop({ required: false });
    icon?: string | undefined = prop({ required: false });
    iconPos?: IconPosition | undefined = prop({ required: false });
    displayOrder?: number | undefined = prop({ required: false });
    style?: style | undefined = prop({ required: false });
    properties?: properties | undefined = prop({ required: false });
    onCreate?: Array<execution> = prop({ required: false })
}
