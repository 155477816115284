import { FieldOption } from "@/models/ListModel";
import {
    Module,
    Mutation,
    VuexModule
} from "vuex-module-decorators";
import { LookupState } from "./LookupStoreTypes";


@Module({
    namespaced: true,
    name: "lookup"
})
export class LookupStore extends VuexModule implements LookupState {
    lookupDetailsLoading: { [field: string]: boolean; } = {};
    lookupDetails: { [field: string]: FieldOption[] } = {};

    get LookupDetails(): { [field: string]: FieldOption[] } { return this.lookupDetails }

    @Mutation
    SAVE_LOOKUP_DETAILS({
        field,
        details
    }: { field: string, details: FieldOption[] }): void {
        this.lookupDetails[field] = details
    }

    @Mutation
    SET_LOOKUP_DETAILS_LOADING({
        field,
        isLoading
    }: { field: string, isLoading: boolean }): void {
        this.lookupDetailsLoading[field] = isLoading
    }
}