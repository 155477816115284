import { ProfileResponse } from "@/models/Profile";
import {
    Module,
    Mutation,
    VuexModule
} from "vuex-module-decorators";
import { ProfileState } from "./ProfileStoreTypes";

@Module({
    namespaced: true,
    name: "profile"
})
export default class ProfileStore extends VuexModule implements ProfileState {
    myProfile!: ProfileResponse;
    get MyProfile(): ProfileResponse { return this.myProfile; }

    @Mutation
    SAVE_MY_PROFILE(profile: ProfileResponse): void {
        this.myProfile = profile;
    }
}