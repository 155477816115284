import { prop } from "vue-class-component";

export class BaseActionComponentProps {
    id?: string = prop({ required: false });
    label?: string | undefined = prop({ required: false });
    type?: string | undefined = prop({ required: false });
    cssClass?: string = prop({ required: false });
    icon?: string | undefined = prop({ required: false });
    iconPos?: string | undefined = prop({ required: false });
    loading?: boolean | undefined = prop({ required: false });
    badge?: number | undefined = prop({ required: false });
    badgeClass?: string | undefined = prop({ required: false });
    disabled?: boolean | undefined = prop({ required: false });
}