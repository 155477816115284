export interface ChangeRequest {
    Email : string,
    currentPassword : string,
    newPassword : string,
    confirmPassword : string
}

export interface ChangeResponse {
    message: string;
  }

export const InitChangeRequest: ChangeRequest = {
    Email : "",
    currentPassword: "",
    newPassword:"",
    confirmPassword:""
}