import { withDashboardCaseStatisticsListWrapper } from "@/handlers/dashboard/dashboard-case-statistics-list/DashboardCaseStatisticsListWrapper";
import { defineAsyncComponent } from "vue";
import { RouteRecordRaw } from "vue-router";
import { withDashboardModuleWrapper } from "@/handlers/dashboard/DashboardModuleWrapper";
import { Permissions } from "@/shared/ClaimsShared";

const routes: Array<RouteRecordRaw> = [{
  path: "/dashboard",
  component: withDashboardModuleWrapper(defineAsyncComponent(() => import("@/views/dashboard/DashboardModulePage.vue"))),
  children: [{
    path: "",
    component: withDashboardCaseStatisticsListWrapper(defineAsyncComponent(() => import("@/views/dashboard/case-statistics/CaseStatisticsPage.vue"))),
    meta: { permission: Permissions.readDashboard },

  },],
}];

export default routes;
