import { bindCaseListService } from "@/IoC/CaseListIoC";
import { IoCTypes } from "@/IoC/IoCTypes";
import {
    InitCaseListRequest, CaseTypesResponse, initCaseTypes
} from "@/models/CaseModal";
import { ICaseListService } from "@/services/case/case-list/ICaseListService";
import { Container } from "inversify";
import {
    computed,
    ComputedRef
} from "vue";
import {
    Options,
    Vue
} from "vue-class-component";
import { ICaseStoreService } from "@/services/case/ICaseStoreService";
import { bindCaseStoreService } from "@/IoC/CaseIoC";

type TProvider = {
    filterPage: ComputedRef<any>;
    caseTypesList: ComputedRef<CaseTypesResponse>;
    isFetchingCaseTypes: ComputedRef<boolean>
}

@Options({
    template: `<slot></slot>`,
    provide(): TProvider {
        return {
            filterPage: computed(() => this.filterPage),
            caseTypesList: computed(() => this.caseTypesList),
            isFetchingCaseTypes: computed(() => this.isFetchingCaseTypes)
        }
    }
})
export default class CaseModuleHandler extends Vue {
    container: Container = new Container();
    caseListService!: ICaseListService;
    filterPage: any = {
        activeIndex: 0,
        showSearchResults: false,
        caseFilterFormData: { ...InitCaseListRequest, },
        searching: false,
        customerror: false,
        customerrorone: false,
        isDeleting: false
    };
    showSearchResults = false;
    caseTypesList: CaseTypesResponse = { ...initCaseTypes };
    isFetchingCaseTypes = true;
    caseStoreService!: ICaseStoreService;


    beforeCreate() {
        bindCaseListService(this.container);
        bindCaseStoreService(this.container);
        this.caseListService = this.container.get<ICaseListService>(IoCTypes.CaseListService);
        this.caseStoreService = this.container.get<ICaseStoreService>(IoCTypes.CaseService);
    }
    created() {
        if (this.caseStoreService.LatestCaseTypes) {
            this.caseTypesList = this.caseStoreService.LatestCaseTypes;
            this.isFetchingCaseTypes = false;
        }
        else {
            this.getCaseTypes();
        }
    }
    getCaseTypes() {
        const orgId = sessionStorage.getItem("orgId");
        this.caseListService.getCaseTypes(orgId).then((res: any) => {
            if (res && res.items && res.items.length > 0) {
                this.caseTypesList = res.items.filter((x: any) => x.isActive === "True");
                this.caseStoreService.saveLatestCaseTypes(res.items.filter((x: any) => x.isActive === "True"));
            }
        })
            .finally(() => this.isFetchingCaseTypes = false)
    }
}
