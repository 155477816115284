interface InmateCaseRequest {
    PInmateId?: string,
    PBookingId?: string,
    PInmateFirstName?: string,
    PInmateLastName?: string,

}
interface StaffSearchRequest {
    PStaffId?: string,
    PStaffFirstName?: string,
    PStaffLastName?: string
}
interface CaseSearchRequest {
    PCaseId?: string,
    PExternalCaseId?: string,
    PReportedDateForm?: string,
    PReportedDateTo?: string,
    PFacility?: string,
    PIncidentDateFrom?: string,
    PIncidentDateTo?: string,
    POutcome?: string,
    PReportingSource?: string,
    PComPlaintType?: string,
    PCreatedDateFrom?: string,
    PCreatedDateTo?: string,
}
export interface CaseListRequest extends InmateCaseRequest, StaffSearchRequest, CaseSearchRequest {

}


export interface CaseListResponse {
    id: string,
    caseId: string,
    externalCaseId: number,
    facility: string,
    reportedDate: string,
    incidentDate: string,
    outcome: string,
    complaintType: string,
    reportingSource: string,
    location: string
    createdDate: string,
    createdBy: string,
    caseClassification: string,
    daysOpen: number,
    daysApproved: number,
    investigator: string,
    persons: [],
    totalcount: number,
    /*
     * personName: string,
     * dob: string,
     * personType: string,   
     */
}

export const InitCaseListRequest: CaseListRequest = {
    PInmateFirstName: "",
    PInmateLastName: "",
    PStaffFirstName: "",
    PStaffLastName: "",
    PCaseId: "",
    PExternalCaseId: "",
    PFacility: "",
    POutcome: "",
    PReportingSource: "",
    PComPlaintType: "",

};

export interface Pagination {
    Ptype: string
    Ppagelimit: number,
    Ppagenumber: number
}

export interface NewCaseListRequest extends InmateCaseRequest, StaffSearchRequest, CaseSearchRequest, Pagination {

}
export interface CaseTypesResponse {
    id: string,
    organizationId: string,
    personType: string,
    caseType: string,
    complaintType: string,
    definition: string
    federalDefinition: string,
    examples: string
    createdBy: string,
    createdDate: string,
    modifiedBy: string,
    modifiedDate: string,
    isActive: string
}

export const initCaseTypes = {
    id: "",
    organizationId: "",
    personType: "",
    caseType: "",
    complaintType: "",
    definition: "",
    federalDefinition: "",
    examples: "",
    createdBy: "",
    createdDate: "",
    modifiedBy: "",
    modifiedDate: "",
    isActive: ""

}

export interface CaseActivityListResponse {

    caseNo: string,
    caseId: string,
    caseCreatedDate: string,
    status: string,
    complaintType: string,
    facility: string,
    outcome: string,
    reportingSource: string,
    totalOpenCasesCount: number,
    totalOverdueCasesCount: number,
    totalCount: number,
}

export const InitRecentCaseListResponse = {

    caseNo: "",
    caseId: "",
    caseCreatedDate: "",
    status: "",
    totalOpenCasesCount: 0,
    totalOverdueCasesCount: 0,
    totalCount: 0,
}
export interface CaseActivityListRequest {
    pUserid: string,
    ppagelimit: number,
    ppagenumber: number
}

export interface CaseStatisticsListRequest {
    //userId: string,
    PUserId: string,
    PFacilityid: string,
    PType: string,
    PPageNumber: number,
    PPageLimit: number,
    // type: number,
}


export interface CaseStatisticsListResponse {
    type: string,
    currentYear: number,
    lastYear: number,
    previousYear: number
}

export interface StaffResponse {
    id: string,
    inmateNo?: string,
    bookingId?: string,
    firstName: string,
    middleName?: string,
    lastName?: string,
    name: string,
    fullName: string,
}
