import { convertDate } from "@/components/filters/ConvertDate";
import {
	formatMultidDate,
	formattedDate,
	formattedDateTime,
	formattedDateTimeReports,
	formattedTime, Helpers, increaseDate
} from "@/lib/Util";
import {
	execution,
	properties
} from "@/models/FormBuilderModel";
import { ProfileResponse } from "@/models/Profile";
import {
	computed,
	ComputedRef,
	inject
} from "vue";
import {
	Options,
	Vue
} from "vue-class-component";
import { ChildFormHandlerProps } from "./ChildFormHandlerProps";

type TProvider = {
	formData: ComputedRef<any>;
	handleSubmit: (executions?: execution[], onSubmit?: execution[]) => void;
};

@Options({
	template: `<c-validation-listener v-model="validate"><slot></slot></c-validation-listener>`,
	watch: { selectedIndex() { this.loadFormData(); } },
	provide(): TProvider {
		return {
			formData: computed(() => this.formData),
			handleSubmit: this.handleSubmit,
		};
	},
})
export default class ChildFormHandler extends Vue.with(ChildFormHandlerProps) {
	formData: any = {};
	selectedIndex: number = inject("selectedIndex") as number;
	formDataList: any[] = inject("formDataList") as any[];
	handleCancel: () => void = inject("handleCancel") as () => void;
	onCreateSubTemplates: execution[] = inject("onCreateSubTemplates") as execution[];
	beforeCreateSubTemplates: execution[] = inject("beforeCreateSubTemplates") as execution[];
	validate: () => Promise<{ valid: boolean; errors?: any }> = () => new Promise<{ valid: boolean }>((res) => res({ valid: true }));
	executeFunction: (executions: execution[], currentObject: any) => any = inject("executeFunction") as (executions: execution[], currentObject: any) => any;
	profile: ProfileResponse = inject("profile") as ProfileResponse;
	newGuid = Helpers.NewGuid();
	func = {
		getFormatMultidDate:formatMultidDate,
		getFormattedDate: formattedDate,
		getFormattedTime: formattedTime,
		getFormattedDateTime: formattedDateTime,
		increaseDateByNumber: increaseDate,
		getUTCNowDate: Helpers.getUTCNow,
		getConvertedDate: convertDate,
		getDateDifferenceInYears: Helpers.getDateDifferenceInYears,
		getFormattedDateTimeReports: formattedDateTimeReports
	};
	state: any = {};
	defaultValue: any = {};
	isSaved = false;
	onDestroySubTemplates: execution[] = inject("onDestroySubTemplates") as execution[];
	properties: properties = inject("properties") as properties;
	setInitialNavValue: (value: any) => void = inject("setInitialNavValue") as (
		value: any
	) => void;
	setCurrentNavValue: (value: any) => void = inject("setCurrentNavValue") as (
		value: any
	) => void;

	beforeCreate() {
		if (this.beforeCreateSubTemplates) this.executeFunction(this.beforeCreateSubTemplates, this);
	}

	created() {
		this.loadFormData();
	}

	mounted() {
		this.resetDirtyChecker();
	}

	loadFormData() {
		if (this.selectedIndex > -1 && !this.addOnly) this.formData = JSON.parse(JSON.stringify(this.formDataList[this.selectedIndex]));
		else this.formData = { ...this.defaultValue };
		if (this.onCreateSubTemplates) this.executeFunction(this.onCreateSubTemplates, this);
		this.resetDirtyChecker();
	}

	async handleSubmit(executions?: execution[], onSubmit?: execution[]) {
		const validationResult = await this.validate();
		if (!validationResult.valid) {
			return;
		}
		if (onSubmit && !this.executeFunction(onSubmit, this)) return;
		this.isSaved = true;
		if (this.selectedIndex > -1) {
			this.formDataList[this.selectedIndex] = JSON.parse(JSON.stringify(this.formData));
		}
		else {
			this.formData.createdAt = new Date();
			this.formData.createdBy = this.profile.firstName.concat(" ", this.profile.lastName);
			this.formDataList.push(JSON.parse(JSON.stringify(this.formData)));
		}
		this.resetDirtyChecker();
		this.handleFormCancel();
		if (executions && executions.length > 0) this.executeFunction(executions, this);
	}

	handleFormCancel() {
		this.formData = {};
		this.handleCancel();
	}

	beforeUnmount() {
		if (!this.isSaved) if (this.onDestroySubTemplates) this.executeFunction(this.onDestroySubTemplates, this);
	}

	private resetDirtyChecker() {
		if (this.properties?.dirtyChecker) {
			this.setInitialNavValue(this.formData);
			this.setCurrentNavValue(this.formData);
		}
	}
}