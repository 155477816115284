import { execution } from "@/models/FormBuilderModel";
import { ICustomFunction } from "../ICustomFunction";

export class SetFunction {
    private exec: execution;
    private func: ICustomFunction;

    constructor(exec: execution, func: ICustomFunction) {
        this.exec = exec;
        this.func = func;
    }

    execute() {
        this.func.setValue(this.exec.field, () => {
            if(this.exec.executions) this.func.run(this.exec.executions)
        }, this.exec.source);
    }
}