export class GlobalConfigService {
    private static instance: any;
    private constructor() {
        // Singleton pattern implemented
        console.warn("GlobalConfig Singleton initialized");
    }

    public static get Instance(): GlobalConfigService {
        if (!GlobalConfigService.instance) {
            GlobalConfigService.instance = new GlobalConfigService();
        }
        return GlobalConfigService.instance;
    }

    private fetchKey(key: string): string {
        if (localStorage.getItem("PREA_CONFIG")) {
            return JSON.parse(atob(localStorage.getItem("PREA_CONFIG") as string))[key]
        }
        return "";
    }

    public getApiBaseUrl = () => { return this.fetchKey("apiBaseUrl"); }

    public getIDPBaseUrl = () => { return this.fetchKey("idpBaseUrl"); }

    public getDateFormat = () => { return this.fetchKey("globalDateFormat"); }
    public getEnvBanner = () => {
        return this.fetchKey("envBanner");
    }
    public getCurrentTemplate = () => {
        return this.fetchKey("currentTemplate");
    }
    public getTimeOutMins = () => {
        return this.fetchKey("timeOutInMins");
    }
}