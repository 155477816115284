import { defineRule } from "vee-validate";
import {
  email, between
} from "@vee-validate/rules";

// Add the required rule
defineRule("required", (value: string, [target]: any[]) => {
  if (!value) {
    return target || "This field is required";
  }
  return true;
});

// Add the autogen rule--used in place where required is needed but not the asterisk symbol
defineRule("autogen", (value: string, [target]: any[]) => {
  if (!value) {
    return target || "This field is required";
  }
  return true;
});

// Add the alpha_num rule
defineRule("alpha_num", (value: string, [target]: any[]) => {
  if (value) {
    if (!(/^[A-Za-z0-9]+$/.test(value))) {
      return target || "Please enter alphanumeric characters only";
    }
  }
  return true;
});


// Add the alpha_num rule
defineRule("alpha_num_spaces", (value: string, [target]: any[]) => {
  if (value) {
    if (!(/^[A-Za-z0-9 ]+$/.test(value))) {
      return target || "This field allows Alpha Numeric Spaces characters";
    }
  }
  return true;
});

defineRule("phone", (value: string, [target]: any[]) => {
  if (value) {
    if (!(/^[A-Za-z0-9 )(-]+$/.test(value))) {
      return target || "Invalid Phone Number";
    }
  }
  return true;
});

defineRule("alpha_num_spaces_hyphen", (value: string, [target]: any[]) => {
  if (value) {
    if (!(/^[A-Za-z0-9 '"-]+$/.test(value))) {
      return target || "This field allows Alpha Numeric Spaces and characters";
    }
  }
  return true;
});

// Add the Alpha rule
defineRule("alpha", (value: string, [target]: any[]) => {
  if (value) {
    if (!(/^[a-zA-Z]+$/.test(value))) {
      return target || "This field allows Numeric characters";
    }
  }
  return true;
});

// Add the Numeric rule
defineRule("numeric", (value: string, [target]: any[]) => {
  if (value) {
    if (!(/^[0-9]+$/.test(value))) {
      return target || "This field allows Numeric characters";
    }
  }
  return true;
});



// Add the Numeric rule
defineRule("height", (value: string, [target]: any[]) => {
  if (value) {
    if ((!(/^[0-7]+?0[0-9]|1[0-1]$/.test(value)) || !(parseInt(value, 10) <= 711)) || !((/^\d{3}$/.test(value)))) {
      return target || "Please enter valid height(e.g. 506,511,604)";
    }
  }
  return true;
});


// Add the Decimal rule
defineRule("decimal", (value: string, [target]: any[]) => {
  if (value) {
    if (!(/^[0-9]{1,11}(?:\.[0-9]{1,7})?$/.test(value))) {
      return target || "This field allows Numeric or decimal characters";
    }
  }
  return true;
});

defineRule("regex", (value: string, [rule, target]: any[]) => {
  if (value) {
    if (!(new RegExp(rule).test(value))) {
      return target || "Field Value is Invalid";
    }
  }
  return true;
});


// Add the email rule
defineRule("email", email);

// Add the Confirmed rule
defineRule("confirmed", (value: any, [target]: any[]) => {
  if (value === target || (!value && !target)) {
    return true;
  }
  return "Confirm password doesn't match";
});


// Add the email rule
defineRule("between", between);

// Add the Password rule
defineRule("password", (value: string, [target]: any[]) => {
  if (value) {

    if (!(/^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[^A-Za-z\d]).{8,}$/.test(value))) {
      return target || "Weak Password";
    }
  }
  return true;
});

//Add the futuredate Rule
defineRule("futuredate", (value: Date, [target]: any[]) => {
  if (value) {
    const a = new Date(value);
    const b = new Date();
    if (a > b) {
      return target || "Casecreated date cannot be a future date";
    }
  }
  return true;
});


//Add the AdultcaseDob Rule
defineRule("adultcasedob", (value: Date, [target, givenDate]: any[]) => {
  if (value) {
    const date18YrsAgo = givenDate ? new Date(givenDate) : new Date;
    date18YrsAgo.setFullYear(date18YrsAgo.getFullYear() - 18);
    if (value >= date18YrsAgo) {
      return target || "Date of Birth of Person cannot be lesser than 18";
    }
  }
  return true;
});


//Add the JuvenilecaseDob Rule
defineRule("juvenilecasedob", (value: Date, [target, givenDate]: any[]) => {
  if (value) {
    const date18YrsAgo = givenDate ? new Date(givenDate) : new Date;
    date18YrsAgo.setFullYear(date18YrsAgo.getFullYear() - 18);
    if (value <= date18YrsAgo) {
      return target || "Date of Birth of Person cannot be higher than 18";
    }
  }
  return true;
});


//Add the Numbers and hyphens Rule
defineRule("numbers_hyphens", (value: string, [target]: any[]) => {
  if (value) {
    if (!(/^\d.*-?\d.*$/.test(value))) {
      return target || "This field allows Numeric and Hyphens";
    }
  }
  return true;
});

